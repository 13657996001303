import { Box, IconButton, Dialog, DialogContent, Typography, DialogTitle, DialogActions, Button } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import Iconify from '../../app/iconify';

interface CloudinaryUploadWidgetProps {
  onImageUpload: (urls: string[]) => void;
  currentImageUrls?: string[];
  multiple?: boolean;
  uploadPreset?: string;
  folder: string;
  displayMode?: 'standard' | 'compact';
  size?: number;
}

const CloudinaryUploadWidget: React.FC<CloudinaryUploadWidgetProps> = ({
  onImageUpload,
  currentImageUrls = [],
  multiple = false,
  uploadPreset = 'unsigned_images',
  folder,
  displayMode = 'standard',
  size = 60,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean;
    indexToDelete: number | null;
  }>({
    open: false,
    indexToDelete: null
  });

  const initializeWidget = useCallback(() => {
    if ((window as any).cloudinary) {
      const widget = (window as any).cloudinary.createUploadWidget(
        {
          cloudName: 'db15ezfng',
          uploadPreset,
          folder,
          maxFileSize: 5000000,
          allowedFormats: ['jpg', 'png', 'gif', 'webp'],
          sources: ['local', 'url', 'camera', 'unsplash', 'google'],
          multiple,
          cropping: true,
          croppingAspectRatio: 1,
          styles: {
            palette: {
              window: '#FFFFFF',
              windowBorder: '#90A0B3',
              tabIcon: '#0078FF',
              menuIcons: '#5A616A',
              textDark: '#000000',
              textLight: '#FFFFFF',
              link: '#0078FF',
              action: '#FF620C',
              inactiveTabIcon: '#0E2F5A',
              error: '#F44235',
              inProgress: '#0078FF',
              complete: '#20B832',
              sourceBg: '#E4EBF1',
            },
          },
        },
        (error: any, result: any) => {
          if (!error && result && result.event === 'success') {
            const imageUrl = result.info.secure_url;
            if (multiple) {
              const newUrls = [...currentImageUrls, imageUrl];
              onImageUpload(newUrls);
            } else {
              onImageUpload([imageUrl]);
            }
          }
        }
      );

      return widget;
    }
  }, [onImageUpload, multiple, uploadPreset, folder, currentImageUrls]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openWidget = useCallback(() => {
    const widget = initializeWidget();
    if (widget) {
      widget.open();
    }
  }, [initializeWidget]);

  const handleDeleteImage = (indexToDelete: number) => {
    setDeleteDialog({
      open: true,
      indexToDelete
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.indexToDelete !== null) {
      const updatedUrls = currentImageUrls.filter((_, index) => index !== deleteDialog.indexToDelete);
      onImageUpload(updatedUrls);
    }
    setDeleteDialog({
      open: false,
      indexToDelete: null
    });
  };

  const handleCancelDelete = () => {
    setDeleteDialog({
      open: false,
      indexToDelete: null
    });
  };

  const handlePreviewImage = (url: string) => {
    setPreviewUrl(url);
  };

  const closePreview = () => {
    setPreviewUrl(null);
  };

  if (displayMode === 'compact') {
    return (
      <Box
        sx={{
          position: 'relative',
          width: size,
          height: size,
          cursor: 'pointer',
        }}
        onClick={openWidget}
      >
        {currentImageUrls[0] ? (
          <Box
            component="img"
            src={currentImageUrls[0]}
            alt="Uploaded Image"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              border: '2px dashed',
              borderColor: 'grey.300',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Iconify icon="typcn:camera" color="gray" />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" gap={2} flexWrap="wrap">
        {currentImageUrls.map((url, index) => (
          <Box
            key={`image-${index}-${url}`}
            sx={{
              position: 'relative',
              width: 60,
              height: 60,
            }}
          >
            <Box
              component="img"
              src={url}
              alt={`Uploaded Image ${index + 1}`}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => handlePreviewImage(url)}
            />
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                top: -8,
                right: -8,
                backgroundColor: 'white',
                boxShadow: 1,
                '&:hover': {
                  backgroundColor: 'error.light',
                  color: 'white',
                },
                zIndex: 1,
              }}
              onClick={() => handleDeleteImage(index)}
            >
              <Iconify icon="eva:close-fill" width={16} />
            </IconButton>
          </Box>
        ))}
      </Box>

      <IconButton
        sx={{
          width: 55,
          height: 55,
          borderRadius: '8px',
          mt: 2,
          border: '2px dashed',
          borderColor: 'grey.300',
        }}
        onClick={openWidget}
      >
        <Iconify icon="typcn:camera" color="gray" />
      </IconButton>

      <Dialog open={!!previewUrl} onClose={closePreview} maxWidth="md" fullWidth>
        <DialogContent sx={{ p: 1 }}>
          {previewUrl && (
            <Box
              component="img"
              src={previewUrl}
              alt="Preview"
              sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '80vh',
                objectFit: 'contain',
              }}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteDialog.open}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this image? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CloudinaryUploadWidget;

import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Col, Divider, Form, Input, InputNumber, Radio, Row, Select, InputRef, Switch, FormInstance, Checkbox, Tooltip } from 'antd';
import TextSubtitle from '../../../../app/system-components/typography/text/TextSubtitle';
import {
  INV_BUNDLE_ITEM,
  INV_ITEM_EMBEDDED_SHOP_RATE_ID,
  INV_ITEM_EMBEDDED_SHOP_RATE_MONTH_ID,
  INV_ITEM_EMBEDDED_SHOP_RATE_WEEK_ID,
  INV_ITEM_SHOW_IN_SHOP,
} from '../../../../app/utils/models/modelConstants/modelConstants';
import ImageUploader from '../ImageUploader';
import { useFirestore } from 'react-redux-firebase';
import { inventoryQueries } from '../../../../app/services/firestore/queries/inventoryQueries';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import notificationError from '../../../../app/system-components/toasters/notificationError';
import onlyUnique from '../../../../app/utils/filters/filterOnlyUnique';
import { InventoryBundleList } from './InventoryBundleList';
import { InventoryItemRates } from './InventoryItemRates';
import { InventoryCategory } from '../../../../app/interfaces/inventoryCategory';
import { InventoryItem } from '../../../../app/interfaces/inventoryItem';
import ReactQuill from 'react-quill';
import { check } from 'prettier';
import { checkSubscriptionLevel } from '../../../../app/interfaces/features-set-permissions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CloudinaryUploadWidget from '../../CloudinaryUploadWidget';
import Iconify from '../../../../app/iconify';
import { Box, IconButton } from '@mui/material';
import { Vendor } from '../../../../app/interfaces/vendor';


const { Option } = Select;

interface NewItemDetailsProps {
  form: FormInstance;
  categories: InventoryCategory[];
  vendors: Vendor[];
  onImageUrl: (downloadURL: string, fileName: string) => void;
  removeImg: () => void;
  defaultImg: { downloadURL: string; fileName: string } | null;
  storedSelections: InventoryItem[];
  setStoredSelections: (selections: InventoryItem[]) => void;
  duplicated: boolean;
  saving: boolean;
  additionalImages?: string[];
  onAdditionalImageUpload?: (urls: string[]) => void;
  onRemoveAdditionalImage?: (index: number) => void;
}

const NewItemDetails: FC<NewItemDetailsProps> = ({
  categories,
  vendors,
  form,
  duplicated,
  onImageUrl,
  removeImg,
  defaultImg,
  storedSelections,
  setStoredSelections,
  saving,
  additionalImages,
  onAdditionalImageUpload,
  onRemoveAdditionalImage,
}) => {
  
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const nameInput = useRef<InputRef | null>(null);
  const [type, setType] = useState("rental");
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const firestore = useFirestore();

  const actualEmbeddedCheckout = orgData?.embeddedCheckoutEnabled;
  const [showInShop, setShowInShop] = useState(false);
  const [includeInPicklist, setIncludeInPicklist] = useState(true);
  const [autoCalculateStock, setAutoCalculateStock] = useState(false);

  const showInShopFromForm = form.getFieldValue(INV_ITEM_SHOW_IN_SHOP);
  const includeInPicklistFromForm = form.getFieldValue("includeInPicklist");
  const autoCalculateStockFromForm = form.getFieldValue("autoCalculateStock");

  useEffect(() => {
    setShowInShop(!!form.getFieldValue(INV_ITEM_SHOW_IN_SHOP));
  }, [showInShopFromForm]);

  useEffect(() => {
    setIncludeInPicklist(!!form.getFieldValue("includeInPicklist"));
  }, [includeInPicklistFromForm]);

  useEffect(() => {
    setAutoCalculateStock(!!form.getFieldValue("autoCalculateStock"));
  }, [autoCalculateStockFromForm]);

  useEffect(() => {
    const invType = form && form.getFieldValue("type");
    setType(invType);
  }, [storedSelections]);

  useEffect(() => {
    fsOrgPrefix &&
      inventoryQueries({ firestore }, fsOrgPrefix)
        ?.fetchInventory()
        .then((res) => {
          setInventoryItems(res?.filter((i) => i?.type !== "bundle"));
        });
  }, [saving]);

  useEffect(() => {
    if (duplicated) {
      nameInput.current?.focus();
    }
  }, [duplicated]);

  const storeSelections = (id: string) => {
    // map stored items
    const selected = inventoryItems.find((i) => i.id === id);
    if (!selected) notificationError("Something went wrong", "");
    else setStoredSelections([selected, ...storedSelections].filter(onlyUnique));
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <ImageUploader onImageUrl={onImageUrl} removeImg={removeImg} defaultImg={defaultImg} />
        </Col>
        <Col style={{ textAlign: "right" }}>
          <Form.Item name="type" initialValue="rental">
            <Radio.Group
              options={[
                { label: "Rental Item", value: "rental" },
                { label: "Rental Bundle", value: "bundle", disabled: !checkSubscriptionLevel("inventory.bundles", orgData) },
                { label: "Sale Item", value: "sale" },
              ]}
              onChange={(e) => {
                const fields = form.getFieldsValue();
                form.setFieldsValue({ rates: [] });
                setType(e.target.value);
              }}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Form.Item
        name="name"
        label="Product Name"
        rules={[
          {
            required: true,
            message: "Name required",
          },
          {
            pattern: /^[^:]*$/, // Regex to disallow ':' character
            message: "Name cannot contain ':'",
          },
        ]}
      >
        <Input placeholder="Name" ref={nameInput} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        tooltip="This is the description of the item displayed on the invoice."
        rules={[
          {
            required: true,
            message: "Description required",
          },
        ]}
      >
        <Input.TextArea placeholder="Description" rows={1} />
      </Form.Item>

      {showInShop && (
        <Form.Item
          label={"Formatted Website Description"}
          name={"rtfDescription"}
          tooltip="This is the description of the item displayed on your shop website. You can use markdown formatting to make the description more readable. If this is left blank, the description from the 'Description' field will be used instead."
        >
          <ReactQuill
            modules={{
              toolbar: [["bold", "italic", "underline"], ["link"]],
            }}
            placeholder="Shop website product description..."
            theme="snow"
            style={{
              height: "100px",
              marginBottom: "24px",
            }}
          />
        </Form.Item>
      )}

      <br />

      <Form.Item label={"Categories"} name="categoriesQueryHook">
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select categories"
          filterOption={(input, option) => {
            if (!option || !option.children) return true;
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          filterSort={(optionA, optionB) => {
            if (!optionA || !optionB || !optionA.children || !optionB.children) return 1;
            return optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase());
          }}
        >
          {categories &&
            categories.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item tooltip="This is a list of vendors that can be used to subrent items when this item is unavailable. You can send a request to a vendor to subrent an item by clicking the 'Request Subrental' button in the Order Details view." label={"Vendors"} name="vendorsQueryHook">
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select vendors"
          filterOption={(input, option) => {
            if (!option || !option.children) return true;
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          filterSort={(optionA, optionB) => {
            if (!optionA || !optionB || !optionA.children || !optionB.children) return 1;
            return optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase());
          }}
        >
          {vendors &&
            vendors.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.companyName}
              </Option>
            ))}
        </Select>
      </Form.Item>

      {actualEmbeddedCheckout && (
        <Form.Item
          name="showInShop"
          initialValue={false}
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                const showInShopSet = !!value;
                const hasSelectedRate = !!form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID);

                if (type === "sale") return Promise.resolve();
                
                return showInShopSet && !hasSelectedRate 
                  ? Promise.reject(new Error("Shop Rate Not Selected"))
                  : Promise.resolve();
              }
            }
          ]}
        >
          <Switch
            size="small"
            checked={showInShop}
            onChange={(e) => {
              setShowInShop(e);
              form.setFieldsValue({ showInShop: e });
              if (!e) {
                form.setFieldsValue({
                  [INV_ITEM_EMBEDDED_SHOP_RATE_ID]: null,
                  [INV_ITEM_EMBEDDED_SHOP_RATE_MONTH_ID]: null,
                  [INV_ITEM_EMBEDDED_SHOP_RATE_WEEK_ID]: null,
                });
              }
            }}
          />{" "}
          Show In Shop
        </Form.Item>
      )}

      <InventoryItemRates type={type} form={form} />

      <Divider style={{ margin: "16px 0px" }} />

      <Form.Item name="includeInPicklist" initialValue={true} valuePropName="checked">
        <Switch
          size="small"
          checked={includeInPicklist}
          onChange={(e) => {
            setIncludeInPicklist(e);
            form.setFieldsValue({ includeInPicklist: e });
          }}
        />{" "}
        Include in Picklist / Load Inventory
      </Form.Item>

      <Row gutter={24}>
        <Col>
          <Form.Item name="stock" label={<TextSubtitle text={"STOCK"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="turnaround" label={<TextSubtitle text={"TURN-AROUND (DAYS)"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>
        {type === "bundle" && (
          <Col>
            <Form.Item name="autoCalculateStock" initialValue={false} valuePropName="checked">
              <Switch
                size="small"
                checked={autoCalculateStock}
                onChange={(e) => {
                  console.log(e);
                  setAutoCalculateStock(e);
                  form.setFieldsValue({ autoCalculateStock: e });
                }}
              />{" "}
              Autocalculate Stock
            </Form.Item>
          </Col>
        )}
      </Row>

      <Form.Item label="Picklist Info"
        name="picklistInfo"
        tooltip="This is the information that will be displayed on the picklist for this item. This is useful for adding things like where the item is located, or any other relevant information."
      >
        <Input.TextArea placeholder="Picklist Info" rows={1} />
      </Form.Item>

      {type === INV_BUNDLE_ITEM && <InventoryBundleList storeSelections={storeSelections} inventoryItems={inventoryItems} />}

      <Form.Item label="Additional Images">
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 1 }}>
          <CloudinaryUploadWidget
            onImageUpload={(urls: string[]) => onAdditionalImageUpload?.(urls)}
            currentImageUrls={additionalImages || []}
            multiple={true}
            folder="inventory"
          />
        </Box>
      </Form.Item>
    </div>
  );
};

export default NewItemDetails;

import React, { FC, useMemo, MouseEvent, useContext } from "react";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  COLOR_PURPLE_0,
} from "./../../../app/ui/colorConstants";
import { Customer } from "../../../app/interfaces/customer";
import { ConvertNumberToCurrencyStr } from "../../../app/utils/numbers/convertNumberToCurrencyStr";
import CustomerContextMenu from "./contextMenu/CustomerContextMenu";
import { gridClasses } from "@mui/x-data-grid";
import { CustomerTableContext } from "../AdminCustomers";
import { useCustomerContextMenu } from "./contextMenu/useCustomerContextMenu";
import { usePersistentDataGrid } from "../../../app/services/hooks/usePersistentDataGrid";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { Tag } from "antd";

const CustomerDataGrid: FC<{
  orgData: OrganizationData;
  data: Customer[];
  loading: boolean;
  handleEdit: (c: Customer) => void;
  onDeleteComplete: () => void;
}> = ({ orgData, data, loading, handleEdit, onDeleteComplete }) => {
  const { actions } = useContext(CustomerTableContext);

  const persistentDataGrid = usePersistentDataGrid(
    "ADMIN_CUSTOMER_TABLE_STATE"
  );

  const customerContextMenuControl = useCustomerContextMenu({
    handleEdit,
    onDeleteComplete,
  });

  const columnsMUI = useMemo(getColumns, [data]);

  const toggleContextMenu = (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>,
    customer: Customer
  ) => {
    if (!customerContextMenuControl.contextConfig.selectedCustomer)
      customerContextMenuControl.setContextMenuConfig({
        x: event.clientX - 2,
        y: event.clientY - 4,
        visible: true,
        selectedCustomer: customer,
      });
    else customerContextMenuControl.resetContext();
  };

  const handleDoubleClick = (
    event: MouseEvent<HTMLElement>,
    record: Customer
  ) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    toggleContextMenu(event, record);
  };

  const handleRightClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const rowId = event.currentTarget.getAttribute("data-id");
    const customer = data.find((c) => c.id === rowId);
    if (customer) toggleContextMenu(event, customer);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
      </GridToolbarContainer>
    );
  };

  const handleOnPaginationChange = (props: GridPaginationModel) => {
    const lastIndexOnPage = (props.page + 1) * props.pageSize;
    const lastIndexOnNextPage = (props.page + 2) * props.pageSize;
    const shouldLoadMore =
      data.length >= lastIndexOnPage && data.length <= lastIndexOnNextPage;
    if (shouldLoadMore) actions.loadNext();
  };

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "white",
      }}
    >
      <DataGridPro<Customer>
        style={{ paddingLeft: "8px" }}
        autoHeight={true}
        apiRef={persistentDataGrid.apiRef}
        loading={loading}
        columns={columnsMUI}
        rows={data}
        pagination={true}
        onPaginationModelChange={handleOnPaginationChange}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: "customerDisplayName", sort: "asc" }],
          },
        }}
        // pageSizeOptions={[25, 50, 100]}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        onRowDoubleClick={(params, event) =>
          handleDoubleClick(event, params.row)
        }
        slotProps={{
          row: {
            onContextMenu: handleRightClick,
            style: { cursor: "context-menu", minHeight: "56px" },
          },
        }}
        onColumnOrderChange={persistentDataGrid.storeGridState}
        onColumnWidthChange={persistentDataGrid.storeGridState}
        onColumnVisibilityModelChange={persistentDataGrid.storeGridState}
        onPinnedColumnsChange={persistentDataGrid.storeGridState}
      />
      <CustomerContextMenu control={customerContextMenuControl} />
    </div>
  );

  function getColumns() {
    const cols: GridColDef[] = [
      {
        headerName: "Customer",
        field: "customerDisplayName",
        width: 200,
        renderCell: (params) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              const customer = data.find((c) => c.id === params.id);
              if (customer) handleEdit(customer);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        headerName: "Company",
        field: "customerCompanyName",
        width: 200
      },
      {
        headerName: "Address",
        field: "customerShippingAddressStr",
        width: 250,
      },
      { headerName: "Email", field: "email", width: 250 },
      { headerName: "Phone", field: "phone", width: 150 },
      { headerName: "Mobile", field: "mobile", width: 150 },
      {
        headerName: "Balance",
        field: "balance",
        width: 100,
        valueFormatter: (params) =>
          ConvertNumberToCurrencyStr(params.value, "$"),
      },
      {
        headerName: "Credit",
        field: "credit",
        width: 100,
        valueFormatter: (params) =>
          ConvertNumberToCurrencyStr(params.value, "$"),
      },
    ];

    if (orgData && orgData.qboRealmId && orgData.qboRealmId !== "") {
      cols.push({
        headerName: "QuickBooks",
        field: "qbId",
        width: 100,
        renderCell: (params) =>
          params.row.qbId ? (
            <div>
              <Tag color={"success"}>Synced</Tag>
            </div>
          ) : (
            <div>
              <Tag color={"error"}>Error</Tag>
            </div>
          ),
      });
    }
    return cols;
  }
};

export default CustomerDataGrid;

import React from "react";
import StopCellUnassigned from "./StopCellUnassigned";
import { COLOR_ORANGE_HIGHLIGHT } from "../../../../../app/ui/colorConstants";

export const unassignedColumns = (selectedStop, orgData) => {
  return [
    {
      title: "Customer",
      dataIndex: "displayName",
      render: (item, record) => {
        const isSelected =
          record?.invoiceId === selectedStop?.invoiceId &&
          record?.type === selectedStop?.stop?.type;
        return {
          props: {
            style: { background: isSelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF" },
          },
          children: <StopCellUnassigned stop={record} orgData={orgData} />,
        };
      },
    },
  ];
};

import { createSlice } from "@reduxjs/toolkit";
import {
  ORG_COLLECTION,
  USERS_COLLECTION,
} from "../../app/utils/models/collections/collectionConstants";
import checkUser from "../../app/utils/models/checkers/checkUser";
import checkOrg from "../../app/utils/models/checkers/checkOrg";
import checkEmailSettings from "../../app/utils/models/checkers/checkEmailSettings";
import { ROUTE_LOGIN } from "../../app/routes";
import notificationError from "../../app/system-components/toasters/notificationError";
import { checkUndefinedOrNull } from "../../app/utils/models/checkers/checkUndefined";
import { minimumDefaultRoutePermissions } from "../admin-permissions/usePermissions";

export const initialState = {
  userData: null,
  orgData: null,
  permissionsData: minimumDefaultRoutePermissions,
  invoiceEmailSettings: null,
  estimateEmailSettings: null,
  authenticated: false,
  verifyingAuthInProgress: false,
  fromLogout: false,
  fsOrgPrefix: null,
  currentOrgId: null,
  availableOrgs: [], // List of all orgs for super admin
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthOrgData(state, { payload }) {
      state.userData = payload.userData;
      state.orgData = payload.orgData;
      state.permissionsData = payload.permissionsData;
      state.adminSettingsGeneral = payload.adminSettingsGeneral;
      state.estimateEmailSettings = payload.estimateEmailSettings;
      state.invoiceEmailSettings = payload.invoiceEmailSettings;
      state.authenticated = true;
      state.fsOrgPrefix = `${ORG_COLLECTION}/${payload.orgData.id}/`;
      state.currentOrgId = payload.orgData.id;
      state.availableOrgs = payload.availableOrgs || [];
    },
    setRefreshedOrgData(state, { payload }) {
      state.orgData = payload;
    },
    verifyingAuth(state, { payload }) {
      state.verifyingAuthInProgress = payload;
    },
    setLogout(state, { payload }) {
      state.userData = payload.userData;
      state.orgData = payload.orgData;
      state.authenticated = false;
      state.availableOrgs = [];
    },
    setFromLogout(state, { payload }) {
      state.fromLogout = payload;
    },
    switchOrg(state, { payload }) {
      state.orgData = payload.orgData;
      state.fsOrgPrefix = `${ORG_COLLECTION}/${payload.orgData.id}/`;
      state.currentOrgId = payload.orgData.id;
      state.estimateEmailSettings = payload.estimateEmailSettings;
      state.invoiceEmailSettings = payload.invoiceEmailSettings;
      state.permissionsData = payload.permissionsData;
    },
    setAvailableOrgs(state, { payload }) {
      state.availableOrgs = payload;
    },
  },
});

export const {
  setAuthOrgData,
  verifyingAuth,
  setLogout,
  setFromLogout,
  setRefreshedOrgData,
  switchOrg,
  setAvailableOrgs,
} = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;

// Thunk actions
export const login =
  ({ firebase, firestore }, payload, history) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password);

      const userId = firebase.auth().currentUser.uid;
      let userData = null;
      // find user
      const userGroupCollection = firestore
        .collectionGroup(USERS_COLLECTION)
        .where("id", "==", userId);
      
      const querySnapshot = await userGroupCollection.get();
      querySnapshot.forEach((s) => {
        userData = checkUser(s);
      });

      if (!userData) {
        throw new Error("User not found");
      }

      // load org
      const orgSnap = await firestore
        .collection(ORG_COLLECTION)
        .doc(userData.orgId)
        .get();
      const orgData = checkOrg(orgSnap);

      const snapshot = await firestore
        .collection(`orgs/${orgData.id}/tags`)
        .get();
      
      if (snapshot.docs.length > 0) {
        const fetchedTags = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        Object.assign(orgData, { tags: checkUndefinedOrNull(fetchedTags, []) });
      }

      const invoiceEmailSettingsSnap = await orgSnap.ref
        .collection("settings")
        .doc("invoiceEmailSettings")
        .get();
      const invoiceEmailSettings = checkEmailSettings(invoiceEmailSettingsSnap);

      const estimateEmailSettingsSnap = await orgSnap.ref
        .collection("settings")
        .doc("estimateEmailSettings")
        .get();
      const estimateEmailSettings = checkEmailSettings(estimateEmailSettingsSnap);

      const permissionsSettingsSnap = await orgSnap.ref
        .collection("settings")
        .doc("permissions")
        .get();
      const permissionsData = permissionsSettingsSnap.exists
        ? permissionsSettingsSnap.data()
        : minimumDefaultRoutePermissions;

      // Load all orgs if super admin
      let availableOrgs = [];

      if (userData.multiLocation && userData.multiLocation.length > 1) {
        console.log("multiLocation", userData.multiLocation);
        const multiLocationOrgIds = userData.multiLocation;
        const multiLocationOrgsSnapshot = await firestore.collection(ORG_COLLECTION).where("id", "in", multiLocationOrgIds).get();
        availableOrgs = multiLocationOrgsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...checkOrg(doc),
        }));
        console.log("availableOrgs", availableOrgs);
      }  
      if (userData.isSuper === true) {
        const orgsSnapshot = await firestore.collection(ORG_COLLECTION).get();
        availableOrgs = orgsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...checkOrg(doc),
        }));
      }

      dispatch(
        setAuthOrgData({
          userData,
          orgData,
          permissionsData,
          estimateEmailSettings,
          invoiceEmailSettings,
          availableOrgs,
        })
      );
      dispatch(verifyingAuth(false));
    } catch (err) {
      console.error(err);
      notificationError(
        "Unable to login",
        "Password is incorrect or user does not exist"
      );
      dispatch(verifyingAuth(false));
      history.push(ROUTE_LOGIN);
    }
  };

export const verifyAuth =
  ({ firestore, firebase }, history) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          let userData = null;
          const userId = user.uid;
          // find user
          const userGroupCollection = firestore
            .collectionGroup(USERS_COLLECTION)
            .where("id", "==", userId);
          
          const querySnapshot = await userGroupCollection.get();
          querySnapshot.forEach((s) => {
            userData = checkUser(s);
          });

          if (!userData) {
            dispatch(verifyingAuth(false));
            dispatch(
              setLogout({
                userData: null,
                orgData: null,
                estimateEmailSettings: null,
                permissionsData: null,
              })
            );
            history.push(ROUTE_LOGIN);
            return;
          }

          // load org
          const orgSnap = await firestore
            .collection(ORG_COLLECTION)
            .doc(userData.orgId)
            .get();
          const orgData = checkOrg(orgSnap);

          const snapshot = await firestore
            .collection(`orgs/${orgData.id}/tags`)
            .get();
          const fetchedTags = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          Object.assign(orgData, { tags: fetchedTags });

          const invoiceEmailSettingsSnap = await orgSnap.ref
            .collection("settings")
            .doc("invoiceEmailSettings")
            .get();
          const invoiceEmailSettings = checkEmailSettings(invoiceEmailSettingsSnap);

          const estimateEmailSettingsSnap = await orgSnap.ref
            .collection("settings")
            .doc("estimateEmailSettings")
            .get();
          const estimateEmailSettings = checkEmailSettings(estimateEmailSettingsSnap);

          const permissionsSettingsSnap = await orgSnap.ref
            .collection("settings")
            .doc("permissions")
            .get();
          const permissionsData = permissionsSettingsSnap.exists
            ? permissionsSettingsSnap.data()
            : minimumDefaultRoutePermissions;

          // Load all orgs if super admin
          let availableOrgs = [];
          if (userData.multiLocation && userData.multiLocation.length > 1) {
            console.log("multiLocation", userData.multiLocation);
            const multiLocationOrgIds = userData.multiLocation;
            const multiLocationOrgsSnapshot = await firestore.collection(ORG_COLLECTION).where("id", "in", multiLocationOrgIds).get();
            availableOrgs = multiLocationOrgsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...checkOrg(doc),
            }));
            console.log("availableOrgs", availableOrgs);
          } 
          
          if (userData.isSuper === true) {
            const orgsSnapshot = await firestore.collection(ORG_COLLECTION).get();
            availableOrgs = orgsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...checkOrg(doc),
            }));
          }

          dispatch(
            setAuthOrgData({
              userData,
              orgData,
              permissionsData,
              estimateEmailSettings,
              invoiceEmailSettings,
              availableOrgs,
            })
          );
          dispatch(verifyingAuth(false));
        } else {
          dispatch(verifyingAuth(false));
          dispatch(
            setLogout({
              userData: null,
              orgData: null,
              permissionsData: null,
              estimateEmailSettings: null,
            })
          );
          history.push(ROUTE_LOGIN);
        }
      });
    } catch (err) {
      console.error(err);
      dispatch(verifyingAuth(false));
      history.push(ROUTE_LOGIN);
    }
  };

export const logout =
  ({ firebase }) =>
  async (dispatch) => {
    dispatch(verifyingAuth(true));
    await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(
          setLogout({
            userData: null,
            orgData: null,
            estimateEmailSettings: null,
            permissionsData: null,
          })
        );
        dispatch(verifyingAuth(false));
      });
  };

export const setLogoutState = () => async (dispatch) => {
  dispatch(setFromLogout(true));
};

export const refreshOrgState =
  ({ firestore }, orgData) =>
  async (dispatch) => {
    try {
      const orgRef = firestore.collection(ORG_COLLECTION).doc(orgData.id);

      const snapshot = await firestore
        .collection(`orgs/${orgData.id}/tags`)
        .get();
      const fetchedTags = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      await orgRef.get().then((orgSnap) => {
        const orgData = checkOrg(orgSnap);
        Object.assign(orgData, { tags: fetchedTags });
        dispatch(setRefreshedOrgData({ ...orgData }));
      });
    } catch (err) {
      console.error(err);
      notificationError("Something went wrong", "Please try again later");
    }
  };

export const switchOrgThunk = ({ firebase, firestore }, orgId) => async (dispatch) => {
  try {
    // Find the current user's document
    const userId = firebase.auth().currentUser.uid
    const userGroupCollection = firestore
      .collectionGroup(USERS_COLLECTION)
      .where("id", "==", userId)
    
    const querySnapshot = await userGroupCollection.get()
    let userDocRef = null
    querySnapshot.forEach((doc) => {
      userDocRef = doc.ref
    })

    if (!userDocRef) {
      throw new Error("User document not found")
    }

    // Update the user's orgId
    await userDocRef.update({
      orgId: orgId
    })

    // Reload the page to reinitialize with the new org
    window.location.reload()
  } catch (err) {
    console.error(err)
    notificationError(
      "Unable to switch organization",
      "Please try again or contact support"
    )
  }
}

import React, { useEffect, useRef, useState, MouseEvent, Suspense, lazy, useCallback, useContext, createContext, useMemo } from "react";
import { capitalize } from "lodash";
import { functions, functionsBaseUrl } from "../../app/config/firebase";
import { saveAs } from "file-saver";

import { OrganizationData } from "../../app/interfaces/organizationData";
import { AdditionalContact, Invoice } from "../../app/interfaces/invoice";
import RentalRow from "./rows/RentalRow";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Calendar from "@mui/icons-material/Event";
import { Check, FileCopyRounded, Key } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // MUI's equivalent of AntD's CheckCircleFilled


import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SubtotalRow from "./rows/SubtotalRow";
import { CheckSelectedItem } from "../../app/utils/models/checkers/checkSelectedItem";
import BundleRow from "./rows/BundleRow";
import { ROW_TYPE_SUBTOTAL } from "../../app/utils/models/modelConstants/modelConstants";
import InventoryPickerDialog from "./components/InventoryPickerDialog";
import { useBoolean } from "../../app/hooks/use-boolean";
import Iconify from "../../app/iconify";
import { useHistory } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { useInvoiceNumberListener } from "../../app/services/hooks/useInvoiceNumberListener";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { InventoryItem } from "../../app/interfaces/inventoryItem";
import DamageWaiverRow from "./rows/DamageWaiverRow";
import FlatFeeRow from "./rows/FlatFeeRow";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../app/hooks/use-responsive-font";
import getSymbolFromCurrency from "currency-symbol-map";
import { Customer } from "../../app/interfaces/customer";
import Nifty from "../../app/utils/Nifty";
import { configureNewSingleItemMUI } from "../../app/utils/models/configure/invoiceTable/configureNewSingleItem";
import SectionDiscountRow, { PercentNumberSwitch } from "./rows/SectionDiscountRow";
import { makeStyles } from "@mui/styles";
import { SelectedItem } from "../../app/interfaces/SelectedItem";
import DescriptionOnlyRow from "./rows/DescriptionOnlyRow";
import { DB } from "../../app/config/firebase";
import { dollarToCents } from "../../app/utils/models/configure/configureCurrencyToCents";
import { configureSelectedItems } from "../../app/utils/models/configure/invoice/configureSelectedItems";
import CustomerPickerDialog from "./components/CustomerPickerDialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCopyToClipboard } from "../../app/hooks/use-copy-to-clipboard";
import { useResponsive } from "../admin-inventory/components/calendar/hooks/use-responsive";

import { DateRange } from "@mui/x-date-pickers-pro";

import InvoicePaymentHistory from "../invoices/components/InvoicePaymentHistory";
import InvoiceSendRecords from "../invoices/components/InvoiceSendRecords";
import { ConfirmDialog } from "../file-manager/custom-dialog";
import { INVOICES_COLLECTION, ORG_COLLECTION, SEND_RECORDS, TABLE_INVOICES_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import { ROUTE_EDIT_INVOICE, ROUTE_INVOICES, ROUTE_RECEIVE_PAYMENT_ROOT } from "../../app/routes";
import InternalNotesDrawer from "../invoices/components/InternalNotesDrawer";
import { enqueueSnackbar } from "notistack";
import MakePickListDialog from "../dashboard/make-picklist";
import { configureDeliveryDetailsMUI } from "./logic/ConfigureReceiveDeliverDetailsMUI";
import { formatDateOnWrite } from "../../app/utils/time/formatTimezoneOffset";
import { configureReceiveReturnTimes } from "./logic/ConfigureReceiveDeliveryTimeDetails";
import PercentBasedFee from "./rows/PercentBasedFee";
import { useFirestoreContext } from "../../app/global-context/FirestoreContext";
import { StickyHeader } from "./components/sticky-header";
import { defaultReceiveDetailsProps, defaultReceiveReturnDetailsProps, defaultReturnDetailsProps, FullReceiveReturnDetailsProps, ReceiveDetailsProps, ReturnDetailsProps } from "./types/ReceiveReturnDetails";
import { ReceiveDetailsMUI } from "./components/receive-details";
import { ReturnDetailsMUI } from "./components/return-details";

import LineItemsHeader from "./components/LineItemsHeader";
import ShippingCalculator from "./components/ShippingCalculator";

// import { pdf } from "@react-pdf/renderer";

import { convertImageToBase64, convertPngToJpeg, convertWebPToJpegAndBase64 } from "../../app/utils/image-converter";

import { en } from "../../i18n/en";
import { es } from "../../i18n/es";
import { useOneOffContext } from "../../app/global-context/OneOffContext";
import SyncQuickBooksModal from "../invoices/components/SyncQuickBooksModal";
import { checkInvoices } from "../../app/utils/models/checkers/checkInvoices";
import InvoiceHistoryTab from "./tabs/InvoiceHistory";


import { CheckPayment } from "../../app/utils/models/checkers/checkPayment";
import { ConfigureDuplicateInvoice } from "../../app/utils/models/configure/configureDuplicateInvoice";
import { sleep } from "../../app/utils/Nifty/nifty";
import { configureQuickReportProps } from "../../app/utils/models/configure/configureQuickReportProps";
import moment from "moment";
import { getRowTotal } from "../invoices/helpers/getRowTotal";
import { COLOR_RED_0, COLOR_YELLOW_0 } from "../../app/ui/colorConstants";
import AddCustomerModal from "../admin-customers/components/modals/AddCustomerModal";
import EditCustomerModal from "../admin-customers/components/modals/EditCustomerModal";
import { checkCustomer } from "../../app/utils/models/checkers/checkCustomer";
import { useAvailableInventoryPaginated } from "../../app/services/hooks/useAvailableInventoryPaginated";
import { endOfDay, set, startOfDay } from "date-fns";
import InvoicePDF from "./components/invoice-pdf";
import { firelikeId } from "../../app/utils/models/checkers/firelikeid";
import { checkUndefinedCurrency } from "../../app/utils/models/checkers/checkUndefined";
import SendInvoiceModal from "./components/SendInvoiceModal";
import SendEmailTemplateView from "../invoices/send-email-template-view";
import { MadeByKitSection } from "./components/MadeByKitSection";
import { useDailyInventoryStatus } from "../../app/services/hooks/useDailyInventoryStatus";
import { finalizeWithTaxes } from "./logic/utils-invoice";
import { initializeReceiveReturnDetails } from "./logic/InitializeReceiveReturnDetails";
import { Vendor } from "../../app/interfaces/vendor";
import { vendorQueries } from "../../app/services/firestore/queries/vendorQueries";
import SubrentRequest from "./components/SubrentRequest";

const HistoryDrawer = lazy(() => import("./components/HistoryDrawer"));


// MARK: Main Component
export function MuiInvoice({ orgData, existingInvoice, cartItems }: Props) {
  // const [demoMode, setDemoMode] = useState(orgData.useLegacyInvoiceView);
  const [demoMode, setDemoMode] = useState(orgData.useLegacyInvoiceView);
  const [madeByKitAccordianExpanded, setMadeByKitAccordianExpanded] = useState(false);

  const [invoice, setInvoice] = useState<Invoice | undefined>(existingInvoice || undefined);
  const { users, tags, categories, venues, inventory, taxes, files, invoiceTextData, emailInvoiceSettings, emailEstimateSettings } = useFirestoreContext();
  const { getVenueSpecificSettings } = useOneOffContext();
  const [vendors, setVendors] = useState<Vendor[]>([]);

   // MARK: Fetch Vendors
   useEffect(() => {
    loadVendors();
  }, [orgData]);

  const loadVendors = () => {
    if (fsOrgPrefix) {
      const fsOrgPrefix = `${ORG_COLLECTION}/${orgData.id}/`;
      const queries = vendorQueries({ firestore }, fsOrgPrefix);
      if (queries) {
        queries.fetchVendors()
          .then((res: Vendor[]) => {
            setVendors(res);
          });
      }
    }
  };

  const [hasPaymentProcessor, setHasPaymentProcessor] = useState(() => {
    return !!(orgData.stripeAccount || orgData.justifiSubAccount);
  });

  const [tab, setTab] = React.useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const theme = useTheme();
  const accordianSummaryColor = theme.palette.grey[500];
  const accordianSummarySx = {
    backgroundColor: alpha(accordianSummaryColor, 0.6),
    border: "1px",
  };
  const accordianSx = {
    boxShadow: "none",
  };

  // MARK: Date Setters
  const [rentalRange, setRentalRange] = useState<DateRange<Date>>(() => [
    startOfDay(invoice?.rentalDateStart?.toDate() ?? new Date()) || startOfDay(new Date()),
    endOfDay(invoice?.rentalDateEnd?.toDate() ?? new Date()) || endOfDay(new Date()),
  ]);
  // Make a use ref for rentalRange
  const rentalRangeRef = useRef(rentalRange);
  useEffect(() => {
    rentalRangeRef.current = rentalRange;
  }, [rentalRange]);

  const [eventDate, setEventDate] = useState<Date | null>(invoice?.eventDate?.toDate() || null);
  const [paymentDueDate, setPaymentDueDate] = useState<Date | null>(invoice?.invoicePaymentDueDate?.toDate() || null);
  const [userSetEventDate, setUserSetEventDate] = useState(false);
  const [userSetPaymentDueDate, setUserSetPaymentDueDate] = useState(false);

  useEffect(() => {
    if (!userSetEventDate && !invoice?.eventDate && rentalRange[0]) {
      setEventDate(rentalRange[0]);
    }

    if (!userSetPaymentDueDate && !invoice?.invoicePaymentDueDate && rentalRange[0]) {
      setPaymentDueDate(rentalRange[1]);
    }
  }, [rentalRange]);

  // MARK: Use Refs
  const discountRef = useRef<HTMLInputElement>(null);
  const rateRef = useRef<HTMLInputElement>(null);
  const deliveryFeeRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  const { copy } = useCopyToClipboard();

  // MARK: UseBoolean
  const pickInventory = useBoolean();
  const showCustomerPicker = useBoolean();
  const showHistoryDialog = useBoolean();
  const confirmDelete = useBoolean();
  const confirmCancel = useBoolean();
  const showAddCustomerModal = useBoolean();
  const showEditCustomerModal = useBoolean();

  const handleSetShowAddCustomerModal = () => {
    showAddCustomerModal.onTrue();
  };

  const { isMDAndUp, isMDAndDown, isLGAndUp, isSMAndDown } = useCurrentBreakpoint();
  const mdUp = useResponsive("up", "md");

  const responsiveFontSize = useResponsiveFontSize();
  const classes = useStyles();

  const showDivider = useMediaQuery(theme.breakpoints.down("lg"));
  const showShortcuts = isMDAndUp;

  const [selectedItemsAccordianExpanded, setSelectedItemsAccordianExpanded] = useState(true);
  const [receiveDetailsAccordianExpanded, setReceiveDetailsItemsAccordianExpanded] = useState(true);
  const [customerDetailsAccordianExpanded, setCustomerDetailsAccordianExpanded] = useState(true);
  const [additionalNotesAccordianExpanded, setAdditionalNotesAccordianExpanded] = useState(true);
  const [showMakePickList, setShowMakePickList] = useState(false);
  const [showSubrentRequest, setShowSubrentRequest] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const memoizedSelectedItems = React.useMemo(() => selectedItems, [selectedItems]);

  const [deliveryFeeTaxable, setDeliveryFeeTaxable] = useState(invoice?.deliveryFeeTaxable ?? true);
  const [contractVersion, setContractVersion] = useState(invoice?.contractVersion ?? 1);

  // HOOKS

  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { deleteDocument } = useFirestoreWrite();

  const { userData, fsOrgPrefix } = useSelector(authSelector);
  const { nextInvoiceNumber } = useInvoiceNumberListener();
  const { newDocument, updateOrgDocument, updateDocument } = useFirestoreWrite();

  // USE STATE

  const [invoiceTotalData, setInvoiceTotalData] = useState(() => {
    return {
      taxAmt: invoice?.taxAmt ?? 0,
      taxRate: invoice?.taxRate ?? 0,
      subtotal: invoice?.subtotal ?? 0,
      discountedSubtotal: 0,
      total: invoice?.total ?? 0,
      hasTotalDiscount: invoice?.hasTotalDiscount ?? false,
      totalDiscountType: invoice?.totalDiscountType ?? "number",
      totalDiscountPercent: invoice?.totalDiscountPercent ?? 0,
      totalDiscountNumber: invoice?.totalDiscountNumber ?? 0,
      totalTaxableAmt: 0,
      totalNonTaxableAmt: 0,
    };
  });

  const [inventoryItemToReplace, setInventoryItemToReplace] = useState<SelectedItem | null>(null);
  const [indexToInsert, setInsertAtIndex] = useState<number | undefined>(undefined);
  const [itemsToFilter, setItemsToFilter] = useState<InventoryItem[]>([]);

  const [invoiceNotes, setInvoiceNotes] = useState(invoice?.notes || "");
  const [invoiceInternalNotes, setInvoiceInternalNotes] = useState(invoice?.internalNotes || "");
  const [contactPhoneNumber, setContactPhoneNumber] = useState(invoice?.contactPhoneNumber || "");
  // const [selectedAdditionalContact, setSelectedAdditionalContact] = useState<AdditionalContact | null>(null);

  const [receiveReturnDetails, setReceiveReturnDetails] = useState<FullReceiveReturnDetailsProps>(defaultReceiveReturnDetailsProps);
  const [receiveDetails, setReceiveDetails] = useState<ReceiveDetailsProps | null>(null);
  const [returnDetails, setReturnDetails] = useState<ReturnDetailsProps | null>(null);
  const receiveReturnDetailsRef = useRef<FullReceiveReturnDetailsProps>(receiveReturnDetails);

  const [customer, setCustomer] = useState<Customer | null>(invoice?.customer || null);
  const [initialCustomer, setInitialCustomer] = useState<string | null>(null);

  // MARK: Tax Rates
  const [showTaxRateSelector, setShowTaxRateSelector] = useState(false);

  const [selectedTaxRate, setSelectedTaxRate] = useState<any>(() => {
    if (invoice) {
      if (invoice.taxMetaData) {
        // console.log("Selected Tax Rate from Invoice: ", invoice.taxMetaData);
        return invoice.taxMetaData;
      } else {
        // console.log("Fallback Selected Tax Rate from Org Data: ", orgData.taxRate);
        return orgData.taxRate;
      }
    }

    if (orgData?.taxRate) {
      // console.log("Selected Tax Rate from Org Data: ", orgData.taxRate);
      return orgData.taxRate;
    }
  });
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const openEmailTemplateDialog = () => {
    setOpenTemplateDialog(true);
  };

  useEffect(() => {
    const customerQueryHook = invoice?.customerQueryHook;

    if (invoice) {
      setInitialCustomer(invoice.customerQueryHook ? invoice.customerQueryHook : null);
    }

    if (customer && customerQueryHook !== customer.id) {
      const customerTaxDetails = customer && customer.customerTaxRateDetails ? customer.customerTaxRateDetails : null;
      const orgTaxRate = orgData.taxRate ? orgData.taxRate : null;
      const taxRate = customerTaxDetails ? customerTaxDetails : orgTaxRate ? orgTaxRate : null;

      if (customer.defaultDiscountPercent && !invoice?.defaultCustomerDiscountOverridden) {
        setInvoiceTotalData({
          ...invoiceTotalData,
          totalDiscountPercent: customer.defaultDiscountPercent / 100,
          totalDiscountType: "percent",
          hasTotalDiscount: true,
        });
      }

      setSelectedTaxRate(taxRate);
    }

    setCurrencySymbol(getSymbolFromCurrency(orgData.currencyCode) ?? "$");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, customer]);

  // MARK: Fetch Payments
  const [payments, setPayments] = useState<any[]>([]);
  const listener = useRef<any>(null);

  useEffect(() => {
    if (!invoice) return;

    const fetchData = async () => {
      listener.current = firebase
        .firestore()
        .collection("orgs")
        .doc(orgData.id)
        .collection("payments")
        .where("invoicesQueryHook", "array-contains", invoice.id)
        .orderBy("paymentDate", "desc")
        .onSnapshot((snapshot) => {
          let data: any[] = [];
          snapshot.docs.forEach((s) => {
            data.push(CheckPayment(s, orgData.orgTimezone));
          });

          data = data.filter((d) => d.status === "complete" || d.status === "pending");
          setPayments(data);
        });
    };

    fetchData();

    return () => {
      const ref = listener.current;
      ref();
    };
  }, []); // dont remove dependency array.

  // Update the ref whenever the state changes
  useEffect(() => {
    receiveReturnDetailsRef.current = receiveReturnDetails;
  }, [receiveReturnDetails]);

  const [newInvoiceNumber, setNewInvoiceNumber] = useState<number | null>(null);
  const [changingInvoice, setChangingInvoice] = useState(false);
  const [invoiceChangeResult, setInvoiceChangeResult]: any = useState(null);

  const [i18n, seti18n] = useState(en);

  // *************************************
  // MARK: Initialize ReceiveReturnDetails
  // *************************************

  useEffect(() => {
    if (invoice) {
      const { receiveDetails, returnDetails, receiveReturnDetails } = initializeReceiveReturnDetails(invoice);

      setReceiveDetails(receiveDetails);
      setReturnDetails(returnDetails);
      setReceiveReturnDetails(receiveReturnDetails);

      if (invoice.selectedContact) {
        setSelectedContact(invoice.selectedContact);
      }
    } else {
      // Use default values
      setReceiveDetails(defaultReceiveDetailsProps);
      setReturnDetails(defaultReturnDetailsProps);
      setReceiveReturnDetails(defaultReceiveReturnDetailsProps);
    }

    const languageCode = orgData.languageCode ?? "en";
    let languageTable: any = en;
    if (languageCode === "en") {
      languageTable = en;
    } else if (languageCode === "es") {
      languageTable = es;
    }

    seti18n(languageTable);
  }, [invoice]);

  const [isSalesInvoice, setIsSalesInvoice] = useState(invoice?.isSalesInvoice ?? false);

  const [customerAddressChecked, setCustomerAddressChecked] = useState({
    receive: true,
    return: true,
  });

  const [toggleNewInvModal, setToggleNewInvModal] = useState({
    visible: false,
    name: "",
    key: null,
  });

  const [currencySymbol, setCurrencySymbol] = useState("");

  const [paymentLinkUrl, setPaymentLinkUrl] = useState<string | null>(null);

  const [deliveryFee, setDeliveryFee] = useState(invoice?.deliveryFee || 0);
  const [showDeliveryFeeCalculator, setShowDeliveryFeeCalculator] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);

  useEffect(() => {
    if (typeof cartItems !== "undefined" && cartItems.selectedItems.items.length !== 0) {
      const start = moment(cartItems.rentalDates.rentalDateStart);
      const end = moment(cartItems.rentalDates.rentalDateEnd);
      const items = cartItems.selectedItems.items;

      let range = end.diff(start, "days");
      let timeRangeInDays = range === 0 ? 1 : range;
      if (orgData.availabilityToOrderDurationMatch === false) {
        timeRangeInDays = 1;
      }

      setRentalRange([start.toDate(), end.toDate()]);

      const updated = items.map((i: any) => {
        const { selectedDuration, ...rest } = i;
        const rangeType = i.selectedRateMeta ? i.selectedRateMeta.rateRange : null;
        if (rangeType) {
          const newItem = {
            selectedDuration: timeRangeInDays,
            ...rest,
          };
          return {
            ...newItem,
            rowTotal: getRowTotal(newItem).newRowTotal,
          };
        } else {
          return i;
        }
      });

      setSelectedItems(updated);
    }
  }, [cartItems]);

  // MARK: Discount Logic
  const [discountLineItemsAmount, setDiscountLineItemsAmount] = useState(0);

  const handleDiscountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const condition = event.target.checked;

    setInvoiceTotalData({
      ...invoiceTotalData,
      totalDiscountPercent: 0,
      totalDiscountNumber: 0,
      totalDiscountType: condition ? "percent" : "number",
      hasTotalDiscount: false,
    });
  };

  const calculateDiscountAmount = () => {
    // console.log("Total Discount Type: ", totalDiscountNumber, totalDiscountPercent, totalDiscountType);
    if (invoiceTotalData.totalDiscountType === "percent") {
      return invoiceTotalData.subtotal * invoiceTotalData.totalDiscountPercent;
    } else {
      return invoiceTotalData.totalDiscountNumber;
    }
  };

  const [totalWeight, setTotalWeight] = useState(0);
  const [showSyncModal, setShowSyncModal] = useState(false);

  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [makingPaymentLink, setMakingPaymentLink] = useState(false);
  const [selectedSalesRep, setSelectedSalesRep] = useState<string | null>(null);
  const [selectedContact, setSelectedContact] = useState<AdditionalContact | null>(null);

  React.useEffect(() => {
    if (invoice) {
      const items = invoice?.selectedItems?.items.map((item) => CheckSelectedItem(item)) || [];
      setSelectedItems(items);
    }
  }, [invoice]);

  // MARK: Check Availability
  const [hasOverbooked, setHasOverbooked] = useState(false);
  // Make a use ref for rentalRange
  const overbookedRef = useRef(hasOverbooked);
  useEffect(() => {
    overbookedRef.current = hasOverbooked;
  }, [hasOverbooked]);

  const memoizedAvailabilityParams = useMemo(
    () => ({
      startEndOpts: {
        useTurnaround: true,
        rentalDateStart: rentalRange[0],
        rentalDateEnd: rentalRange[1],
      },
      filterInventoryIds: inventory?.map((i) => i.id),
      invoiceItems: [],
      ignoreInvoicesForAvailability: [invoice?.id],
    }),
    [rentalRange, inventory, invoice?.id]
  );

  const { availableInventory, fetchingInvoices, refreshInvoices, fullInventory } = useAvailableInventoryPaginated(memoizedAvailabilityParams);






  // *************************
  // MARK: Configure Breakdown
  // *************************

  useEffect(() => {
    /**
     * Recalculate total on item change or tax change
     */
    if (orgData) { // Only calculate if total is not set
      const totalsData = finalizeWithTaxes(
        customer,
        invoice,
        invoiceTotalData,
        selectedTaxRate,
        deliveryFee,
        deliveryFeeTaxable,
        selectedItems,
        orgData
      );
      if (totalsData) {
        setInvoiceTotalData(totalsData);
      }
    }
    setHasOverbooked(false);

    // Remove invoiceTotalData from dependencies
  }, 
  [invoiceTotalData, selectedItems, selectedTaxRate, deliveryFee, deliveryFeeTaxable]);
  // [selectedItems, selectedTaxRate, deliveryFee, deliveryFeeTaxable, customer, invoice, orgData]);




  // MARK: Discount % Update
  const handlePercentDiscountUpdate = (e: any) => {
    const discount = (e.target.value ? e.target.value : 0) / 100;
    const percentValue = parseFloat(discount.toFixed(4));

    setInvoiceTotalData({
      ...invoiceTotalData,
      totalDiscountPercent: percentValue,
      totalDiscountNumber: 0,
      totalDiscountType: "percent",
      hasTotalDiscount: true,
    });
  };

  // MARK: Discount Number Update
  const handleNumberDiscountUpdate = (e: any) => {
    const totalNumber = Number(e.target.value);

    setInvoiceTotalData({
      ...invoiceTotalData,
      totalDiscountPercent: 0,
      totalDiscountNumber: totalNumber,
      totalDiscountType: "number",
      hasTotalDiscount: true,
    });
  };

  const handleSubstituteItemClicked = useCallback((item: SelectedItem, substitutions: InventoryItem[]) => {
    console.log("item", item);
    console.log("substitutions", substitutions);
    setItemsToFilter(substitutions);
    setInventoryItemToReplace(item);
    pickInventory.onToggle();

  }, []);

  const handleUpdateSelectedItem = useCallback((index: number, item: any) => {
    setSelectedItems((prevSelectedItems) => {
      const items = [...prevSelectedItems];
      items[index] = item;
      return items;
    });
  }, []);

  const removeItem = useCallback((index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = prevSelectedItems.filter((item, i) => i !== index);
      return updatedItems.map((item, index) => ({
        ...item,
        listIndex: index,
      }));
    });
  }, []);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    if (startIndex === endIndex) {
      return list;
    }

    const result = [...list];
    const [movedItem] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, movedItem);

    // Update only the moved item's listIndex
    movedItem.listIndex = endIndex;

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(selectedItems, result.source.index, result.destination.index);

    // Update listIndex and key for all items
    const updatedItems = items.map((item, index) => ({
      ...item,
      listIndex: index,
      key: index,
    }));

    setSelectedItems(updatedItems);
  };

  const [systemOS, setSystemOS] = useState(""); // mac, win

  useEffect(() => {
    function detectOS() {
      const platform = navigator.platform.toLowerCase();
      if (platform.includes("mac")) {
        setSystemOS("mac");
      } else if (platform.includes("win")) {
        setSystemOS("win");
      }
    }
    detectOS();
  }, []);

  // *********************************
  // MARK: Image Processing
  // *********************************

  const [logoBase64, setLogoBase64] = useState("");
  const [itemImages, setItemImages] = useState<[{ id: string; data: string }]>([{ id: "", data: "" }]);
  const [signatureData, setSignatureData] = useState<any>(null);

  useEffect(() => {
    const convertLogo = async () => {
      const logoUrl = orgData.logoUrl;
      let logoString = "";
      try {
        if (logoUrl.includes(".webp") || logoUrl.includes(".WEBP")) {
          logoString = await convertWebPToJpegAndBase64(logoUrl);
        } else if (logoUrl.includes(".png") || logoUrl.includes(".PNG")) {
          logoString = await convertPngToJpeg(logoUrl);
        } else {
          logoString = await convertImageToBase64(logoUrl);
        }
        setLogoBase64(logoString);
      } catch (error) {
        console.log("Error converting logo: ", error);
      }
    };

    const getSignature = async () => {
      if (!invoice) return;

      const invoiceId = invoice.id;

      let signatureUrl = null;
      let date = null;

      if (invoice.paymentSignatures && invoice.paymentSignatures.length > 0) {
        const latest = invoice.paymentSignatures[invoice.paymentSignatures.length - 1];
        date = latest.timestamp || null;

        const bucket = firebase.storage().ref();
        const file = bucket.child(`paymentsignatures/${invoiceId}/paymentsignature-${latest.paymentId}`);
        await file
          .getDownloadURL()
          .then((signedUrls) => {
            signatureUrl = signedUrls;
          })
          .catch((error) => {
            console.error("Unable to get signed urls: ", error);
          });
      }

      // TODO: Might need to make it possible for estimate signature to be an array as well.
      let estimateSignatureUrl = null;
      if (invoice.estimateSignatures && invoice.estimateSignatures.length > 0) {
        date = invoice.estimateSignatures[0].timestamp || null;

        const bucket = firebase.storage().ref();
        const file = bucket.child(`estimatesignatures/${invoiceId}/estimatesignature.jpg`);
        await file
          .getDownloadURL()
          .then((signedUrls) => {
            estimateSignatureUrl = signedUrls;
          })
          .catch((error) => {
            console.error("Unable to get signed urls: ", error);
          });
      }

      if (invoice.type === "estimate" || (invoice.type === "invoice" && !signatureUrl)) {
        signatureUrl = estimateSignatureUrl;
      }

      const signatureData = { data: signatureUrl, date: date };

      setSignatureData(signatureData);
    };

    if (signatureData === null) {
      getSignature();
    }

    if (logoBase64 === "") {
      convertLogo();
    }
  }, [orgData]);

  useEffect(() => {
    const convertImages = async () => {
      if (!orgData.showInvoiceItemImg) {
        return;
      }

      // Create a copy of the existing itemImages array
      const newItemImages: [{ id: string; data: string }] = [...itemImages];

      const promises = selectedItems.map((item) => {
        // Return a new promise for each item
        return new Promise(async (resolve, reject) => {
          // Check if the item's image has already been converted
          const existingImage = newItemImages.find((img) => img.id === item.id);
          if (existingImage) {
            resolve(null); // Skip this item if it's already been processed
            return;
          }

          if (item?.image?.downloadURL) {
            const downloadUrl = item.image.downloadURL;

            let imageStr = "";
            try {
              if (downloadUrl.includes("cdn.shopify")) {
                imageStr = downloadUrl;
              } else if (downloadUrl.includes(".webp") || downloadUrl.includes(".WEBP")) {
                imageStr = await convertWebPToJpegAndBase64(downloadUrl);
              } else if (downloadUrl.includes(".png") || downloadUrl.includes(".PNG")) {
                imageStr = await convertPngToJpeg(downloadUrl);
              } else {
                imageStr = await convertImageToBase64(downloadUrl);
              }
            } catch (error) {
              reject(error);
            }

            // Handle the case where imageStr might still be empty
            if (!imageStr) {
              reject(new Error("Failed to process image"));
            }

            // Resolve the promise with the item id and imageStr
            resolve({ id: item.id, data: imageStr });
          } else {
            // If there is no downloadURL, resolve the promise with null or appropriate value
            resolve(null);
          }
        });
      });

      Promise.all(promises)
        .then((results) => {
          results.forEach((result: any) => {
            if (result) {
              const existingItemIndex = newItemImages.findIndex((imgItem) => imgItem.id === result.id);
              if (existingItemIndex !== -1) {
                newItemImages[existingItemIndex] = result;
              } else {
                newItemImages.push(result);
              }
            }
          });
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });

      setItemImages(newItemImages);
    };

    convertImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  // ****************************
  // MARK: Add Line Items
  // ****************************

  // Show Inventory Picker Dialog
  const handleAdd = () => {
    pickInventory.onToggle();
  };

  const handleReplaceAnItem = useCallback(
    (item: InventoryItem, oldItem: SelectedItem, keepQuantity: boolean) => {
      const newSelectedItems = [...selectedItems];

      const formattedNewItem = configureNewSingleItemMUI(newSelectedItems, customer, item);
      formattedNewItem.listIndex = oldItem.listIndex;
      formattedNewItem.key = oldItem.uuid;

      if (keepQuantity) {
        formattedNewItem.selectedQty = oldItem.selectedQty;
      }

      newSelectedItems[oldItem.listIndex] = formattedNewItem;
      setInventoryItemToReplace(null);
      setItemsToFilter([]);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, customer]
  );

  const handleInsertItemAtIndex = useCallback(
    (insertAtIndex?: number) => {
      setInsertAtIndex(insertAtIndex);
      pickInventory.onToggle();
    },
    [selectedItems, customer]
  );

  const handleAddItem = useCallback(
    (itemType: string, index: number | null, itemData: any, qty: number) => {
      if (pickInventory.value) {
        pickInventory.onToggle();
      }

      setSelectedItems((prevSelectedItems) => {
        const insertionIndex = index !== null && index !== undefined && index >= 0 && index < prevSelectedItems.length ? index + 1 : prevSelectedItems.length;

        const newItem = {
          uuid: firelikeId(), // Generate a unique id for each new item
          key: insertionIndex,
          listIndex: insertionIndex,
          rowType: itemType || "default",
          selectedQty: qty || 1,
          rowTotal: 0,
          name: "",
          ...itemData,
        };

        let newSelectedItems = [...prevSelectedItems];
        newSelectedItems.splice(insertionIndex, 0, newItem);

        // Recalculate listIndex and key for all items
        return newSelectedItems.map((item, i) => ({
          ...item,
          listIndex: i,
          key: i,
        }));
      });
    },
    [pickInventory]
  );

  const memoizedHandleInsertSpecial = useCallback((itemType: string, index: number) => handleAddItem(itemType, index, null, 1), [handleAddItem]);


  const handleNewItemSelected = useCallback(
    (item: any, qty: number, insertAtIndex?: number) => {
      const formattedNewItem = configureNewSingleItemMUI(selectedItems, customer, item);
      formattedNewItem.selectedQty = qty;

      if (insertAtIndex) {
        formattedNewItem.listIndex = insertAtIndex;
        formattedNewItem.key = insertAtIndex;
      }

      // setSelectedItems((prevSelectedItems) => [...prevSelectedItems, formattedNewItem]);
      setSelectedItems((prevSelectedItems) => {
        let newSelectedItems;

        // Insert the new item *below* the specified index
        const insertionIndex =
          insertAtIndex !== undefined && insertAtIndex >= 0 && insertAtIndex < prevSelectedItems.length ? insertAtIndex + 1 : prevSelectedItems.length;

        newSelectedItems = [...prevSelectedItems];
        newSelectedItems.splice(insertionIndex, 0, formattedNewItem);

        // Update the listIndex of all items
        newSelectedItems = newSelectedItems.map((item, i) => ({
          ...item,
          listIndex: i, // Recalculate listIndex for each item
          key: i,
        }));

        return newSelectedItems;
      });
    },
    [selectedItems, customer]
  );

  const handleAddSubtotal = useCallback(
    (index?: number) => {
      if (pickInventory.value) {
        pickInventory.onToggle();
      }

      const newItem = {
        key: selectedItems.length,
        listIndex: selectedItems.length, // The listIndex will be updated after insertion
        rowType: ROW_TYPE_SUBTOTAL,
        selectedQty: 1,
        rowTotal: 0,
        name: "",
        uuid: firelikeId(),
      };

      setSelectedItems((prevSelectedItems) => {
        let newSelectedItems;

        // Insert the new item *below* the specified index
        const insertionIndex = index !== undefined && index >= 0 && index < prevSelectedItems.length ? index + 1 : prevSelectedItems.length;

        newItem.key = insertionIndex;
        newItem.listIndex = insertionIndex;

        newSelectedItems = [...prevSelectedItems];
        newSelectedItems.splice(insertionIndex, 0, newItem);

        // Update the listIndex of all items
        newSelectedItems = newSelectedItems.map((item, i) => ({
          ...item,
          listIndex: i, // Recalculate listIndex for each item
          key: i,
        }));

        return newSelectedItems;
      });
    },
    [pickInventory, selectedItems]
  );

  const handleAddDescriptionOnly = useCallback(
    (index?: number) => {
      if (pickInventory.value) {
        pickInventory.onToggle();
      }

      const newItem = {
        key: selectedItems.length,
        listIndex: index !== undefined && index >= 0 && index < selectedItems.length ? index : selectedItems.length,
        rowType: "descriptionOnly",
        rowTotal: 0,
        selectedQty: 1,
        name: "",
      };

      setSelectedItems((prevSelectedItems) => {
        let newSelectedItems;

        // If an index is provided and valid, insert the new item at that index and update listIndex for subsequent items
        if (index !== undefined && index >= 0 && index < prevSelectedItems.length) {
          newSelectedItems = [...prevSelectedItems];
          newSelectedItems.splice(index, 0, newItem);

          // Update the listIndex of all items following the inserted one
          newSelectedItems = newSelectedItems.map((item, i) => ({
            ...item,
            listIndex: i, // Recalculate listIndex for each item
          }));
        } else {
          // Append the new item to the end if no index is provided or it's invalid
          newSelectedItems = [...prevSelectedItems, newItem];
        }

        return newSelectedItems;
      });
    },
    [pickInventory, selectedItems]
  );

  const handleAddDamageWaiver = useCallback(() => {
    if (pickInventory.value) {
      pickInventory.onToggle();
    }

    const newItem = {
      key: selectedItems.length,
      listIndex: selectedItems.length,
      rowType: "damageWaiver",
      rowTotal: 0,
      selectedQty: 1,
      name: "",
    };

    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, newItem]);
  }, [pickInventory, selectedItems]);

  const handleAddPercentageFee = useCallback(() => {
    if (pickInventory.value) {
      pickInventory.onToggle();
    }

    const newItem = {
      key: selectedItems.length,
      listIndex: selectedItems.length,
      rowType: "percentFee",
      rowTotal: 0,
      selectedDuration: 1,
      selectedQty: 1,
      name: "",
    };

    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, newItem]);
  }, [pickInventory, selectedItems]);

  const handleAddFlatFee = useCallback(() => {
    if (pickInventory.value) {
      pickInventory.onToggle();
    }

    const newItem = {
      key: selectedItems.length,
      listIndex: selectedItems.length,
      rowType: "flatFee",
      rowTotal: 0,
      selectedDuration: 1,
      selectedQty: 1,
      name: "",
    };

    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, newItem]);
  }, [pickInventory, selectedItems]);

  const handleAddDiscount = useCallback(() => {
    if (pickInventory.value) {
      pickInventory.onToggle();
    }

    const newItem = {
      key: selectedItems.length,
      listIndex: selectedItems.length,
      rowType: "discount",
      selectedQty: 1,
      rowTotal: 0,
      name: "",
    };

    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, newItem]);
  }, [pickInventory, selectedItems]);

  // ****************************
  // MARK: Keyboard Shortcuts
  // ****************************

  const handleCommandA = () => {
    handleAdd();
  };

  const handleCommandSlash = () => {
    memoizedHandleInsertSpecial("subtotal", selectedItems.length);
  };

  const handleCommandS = () => {
    handleSaveInvoice({
      type: invoice?.type || "draft",
    });
  };
  const handleCommandShiftS = () => {
    handleSaveInvoice({
      type: "invoice",
    });
  };

  const handleCommandShiftD = () => {
    if (!invoice) {
      enqueueSnackbar("Please save invoice first", { variant: "warning" });
      return;
    }
    handleDuplicate();
  };

  useEffect(() => {
    const handleCommandSlashDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "/") {
        event.preventDefault();
        handleCommandSlash();
      }
    };
    window.addEventListener("keydown", handleCommandSlashDown);
    return () => {
      window.removeEventListener("keydown", handleCommandSlashDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  // Keyboard Shortcuts
  useEffect(() => {
    const handleCommandADown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "a") {
        event.preventDefault(); // Prevent the default action (select all text)
        handleCommandA();
      }
    };

    window.addEventListener("keydown", handleCommandADown);

    return () => {
      window.removeEventListener("keydown", handleCommandADown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  

  // Developer Only
  const handleCommandShiftP = () => {
    enqueueSnackbar("Copied Invoice JSON", { variant: "info", anchorOrigin: { vertical: "bottom", horizontal: "center" } });
    navigator.clipboard.writeText(JSON.stringify(invoice)).catch((err) => console.log(err));
  };

  useEffect(
    () => {
      const handleDevData = (event: KeyboardEvent) => {
        if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === "p") {
          event.preventDefault();
          handleCommandShiftP();
        }
      };

      window.addEventListener("keydown", handleDevData);

      return () => {
        window.removeEventListener("keydown", handleDevData);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoice]
  );

  useEffect(() => {
    if (!invoice) {
      setDeliveryFeeTaxable(orgData.deliveryFeeTaxable);
    } else {
      setDeliveryFeeTaxable(invoice.deliveryFeeTaxable);
    }

    if (!orgData || !users || !invoice || !userData) return;

    const findSalesRep = () => {
      // First try to find by salesRepId
      const salesRepById = users.find((user) => user.id === invoice.salesRepId);
      if (salesRepById) return salesRepById.id;

      // Then try to find by salesRepName
      const salesRepByName = users.find((user) => user.name === invoice.salesRepName);
      if (salesRepByName) return salesRepByName.id;

      // Lastly, match with userData
      const currentUser = users.find((user) => user.id === userData.id);
      if (currentUser) return currentUser.id;

      return null;
    };

    const selectedSalesRepId = findSalesRep();
    if (selectedSalesRepId) {
      setSelectedSalesRep(selectedSalesRepId);
    }
  }, [invoice, orgData, userData, users]);

  // *********************************
  // MARK: Customer
  // *********************************

  const handleCreateNewCustomer = () => {
    showCustomerPicker.onFalse();
    showAddCustomerModal.onTrue();
  };

  const handleAddCustomer = (customer: Customer) => {
    const newCustomerAddressChecked = { ...customerAddressChecked };
    const newReceiveReturnDetails = { ...receiveReturnDetails };
    const newReceiveDetails = { ...(receiveDetails ?? defaultReceiveDetailsProps) };
    const newReturnDetails = { ...(returnDetails ?? defaultReturnDetailsProps) };

    if (receiveDetails && receiveDetails.manualReceiveAddress === "" && receiveReturnDetails.manualReceiveAddress === "") {
      newCustomerAddressChecked.receive = true;
      newReceiveReturnDetails.useCustomerAddressReceive = true;
      newReceiveDetails.useCustomerAddressReceive = true;
    }

    if (returnDetails && returnDetails.manualReturnAddress === "" && receiveReturnDetails.manualReturnAddress === "") {
      newCustomerAddressChecked.return = true;
      newReceiveReturnDetails.useCustomerAddressReturn = true;
      newReturnDetails.useCustomerAddressReturn = true;
    }

    setCustomerAddressChecked(newCustomerAddressChecked);
    setReceiveReturnDetails(newReceiveReturnDetails);
    setReceiveDetails(newReceiveDetails);
    setReturnDetails(newReturnDetails);

    if (customer.defaultDiscountPercent && !invoice?.defaultCustomerDiscountOverridden) {
      setInvoiceTotalData({
        ...invoiceTotalData,
        totalDiscountPercent: customer.defaultDiscountPercent / 100,
        totalDiscountType: "percent",
        hasTotalDiscount: true,
      });
    }

    console.log("New Customer: ", customer);

    setCustomer(customer);
  };

  const handleReplaceCustomer = (newCustomer: Customer, oldCustomer: Customer) => {
    if (newCustomer.defaultDiscountPercent && !invoice?.defaultCustomerDiscountOverridden) {
      setInvoiceTotalData({
        ...invoiceTotalData,
        totalDiscountPercent: newCustomer.defaultDiscountPercent / 100,
        totalDiscountType: "percent",
        hasTotalDiscount: true,
      });
    }

    setCustomer(newCustomer);
  };

  // MARK: Print Pull Sheet

  const printPullSheet = async () => {
    if (!invoice) {
      return;
    }

    const result = await handleSaveInvoice({
      showToast: false,
      type: invoice.type,
    });

    const refreshed = result?.invoice;

    return refreshed;
  };

  // *********************************
  // MARK: Download Invoice
  // *********************************

  const downloadInvoicePDF = async () => {
    if (!invoice) {
      return;
    }

    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    setDownloadingPDF(true);

    const result = await handleSaveInvoice({
      type: invoice?.type,
      showToast: false,
    });

    const refreshed = result?.invoice;

    if (refreshed) {
      setDownloadingPDF(true);
      let pdfEmailSettings = invoice.type === "invoice" ? emailInvoiceSettings : emailEstimateSettings;
      let pdfOrgData: any = { ...orgData };

      // PSR SPECIFIC
      const psr = process.env.REACT_APP_PSR_ID;
      if (
        orgData.id === psr &&
        ((invoice.receiveVenueQueryHook && invoice.receiveVenueQueryHook !== "") || (invoice.returnVenueQueryHook && invoice.returnVenueQueryHook !== ""))
      ) {
        const psrSettings = await getVenueSpecificSettings(refreshed.receiveVenueQueryHook);
        if (psrSettings) {
          pdfEmailSettings = refreshed.type === "invoice" ? psrSettings.invoiceSettings : psrSettings.estimateSettings;
          pdfOrgData.logoUrl = psrSettings.logo;
        }
      }
      // >>>>>>>>>>>>>>>>

      try {
        // Dynamically import the pdf function and InvoicePDF component
        const { pdf } = await import("@react-pdf/renderer");

        const blob = await pdf(
          <InvoicePDF
            invoice={refreshed}
            orgData={pdfOrgData}
            venues={venues}
            emailSettings={pdfEmailSettings}
            customText={invoiceTextData}
            i18n={i18n}
            logoData={logoBase64}
            itemImages={itemImages}
            signatureData={signatureData}
          />
        ).toBlob();

        saveAs(blob, `${refreshed.invoiceNumber}.pdf`);
        setDownloadingPDF(false);
        enqueueSnackbar("Invoice Downloaded", { variant: "success" });
      } catch (error) {
        console.log(error);
        setDownloadingPDF(false);
        enqueueSnackbar("Error Downloading Invoice", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please Save Order First", { variant: "error" });
    }
  };

  // *********************************
  // MARK: Receive Payment
  // *********************************

  const navigateToPayment = async () => {
    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    // Save it first
    await handleSaveInvoice({ showToast: false });

    history.push({
      pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${invoice?.customerQueryHook}`,
      state: { invoiceId: invoice?.id },
    });
  };

  const handleDateRangeChange = (newDates: DateRange<Date>) => {
    if (newDates[0] && newDates[1]) {
      const start = startOfDay(newDates[0]);
      const end = endOfDay(newDates[1]);

      setRentalRange([start, end]);
      refreshInvoices();
    }
  };

  // Updates when selected contact changes
  useEffect(() => {
    if (selectedContact) {
      if (invoice?.contactPhoneNumber && selectedContact.phone !== invoice.contactPhoneNumber) {
        setContactPhoneNumber(invoice.contactPhoneNumber);
        return;
      }

      setContactPhoneNumber(selectedContact.phone);
    }
  }, [selectedContact]);

  const handleReceiveReturnDetailsChange = (state: ReceiveDetailsProps | ReturnDetailsProps) => {
    // Check if the venue has changed:
    if ("receiveBy" in state) {
      const data = state as ReceiveDetailsProps;
      const newVenueId = data.selectedReceiveVenue;

      if (newVenueId && venues) {
        const venue = venues.find((v) => v.id === newVenueId);
        if (venue) {
          // Get the notes from the venue, and add them to the notes field
          let updatedNotes = invoiceInternalNotes;
          updatedNotes = `${invoiceInternalNotes}${invoiceInternalNotes ? "\n\n" : ""}Notes from venue: ${venue.venueName}\n${venue.venueNotes}`;
          setInvoiceInternalNotes(updatedNotes);

          // Set the delivery fee if it exists
          if (venue.deliveryFee !== undefined) {
            setDeliveryFee(venue.deliveryFee);
          }
        }
      }
    }
    if ("returnBy" in state) {
      const data = state as ReturnDetailsProps;
      const newVenueId = data.selectedReturnVenue;

      if (newVenueId && venues) {
        const venue = venues.find((v) => v.id === newVenueId);
        if (venue) {
          // Get the notes from the venue, and add them to the notes field
          let updatedNotes = invoiceInternalNotes;
          updatedNotes = `${invoiceInternalNotes}${invoiceInternalNotes ? "\n\n" : ""}Notes from venue: ${venue.venueName}\n${venue.venueNotes}`;
          setInvoiceInternalNotes(updatedNotes);

          // Set the delivery fee if it exists
          if (venue.deliveryFee !== undefined) {
            setDeliveryFee(venue.deliveryFee);
          }
        }
      }
    }

    // Create a new state object by merging the current state with the incoming state
    const newState = {
      ...receiveReturnDetails,
      ...state,
    };

    setCustomerAddressChecked({
      receive: newState.useCustomerAddressReceive,
      return: newState.useCustomerAddressReturn,
    });

    // Update the state
    setReceiveReturnDetails(newState);
  };

  const [title, setTitle] = useState("");

  const [invoiceNumbers, setInvoiceNumbers] = useState<{
    hasError: boolean;
    adelie: string | null;
    qbo: string | null;
    errorLabel: string;
  }>({
    hasError: false,
    adelie: null,
    qbo: null,
    errorLabel: "",
  });

  useEffect(() => {
    if (!invoice) {
      return setTitle("New Order");
    }

    setTitle(`#${invoice?.invoiceNumber} (${capitalize(invoice?.type)})`);

    if (invoice?.type === "draft") return;

    if (!orgData) return;

    const qboEnabled = orgData?.integrations?.qbOnline || orgData?.integrations?.qbDesktop;

    if (!qboEnabled) return;
    // console.log(matchNums(invoice));
    if (matchNums(invoice).isMatch) return;

    setInvoiceNumbers({
      hasError: true,
      adelie: matchNums(invoice).adelie,
      qbo: matchNums(invoice).qbo,
      errorLabel: `RentKit: ${matchNums(invoice).adelie} | QuickBooks: ${matchNums(invoice).qbo}`,
    });
  }, [invoice]);

  const matchNums = (invoice: Invoice) => {
    const adelie = `${invoice?.invoiceNumber}`;
    const qbo = invoice?.qboInvoiceNumber ?? "No QuickBooks Invoice Number";
    return {
      isMatch: adelie === qbo,
      adelie: `${adelie}`,
      qbo: `${qbo}`,
    };
  };

  const handleConfirmInvoiceChange = async () => {
    if (!Number.isInteger(newInvoiceNumber)) {
      alert("Invalid number");
      return;
    }

    const call = functions.httpsCallable("changeInvoiceNumber");

    setChangingInvoice(true);
    setInvoiceChangeResult(null);

    try {
      const res = await call({
        orgId: orgData.id,
        invoiceId: invoice?.id,
        invoiceNumber: newInvoiceNumber,
      });

      const status = res.data.status;

      if (status === 200) {
      }

      setInvoiceChangeResult(res.data);
    } finally {
      setChangingInvoice(false);
    }
  };

  const handleDelete = async () => {
    if (!invoice) return;

    await deleteDocument({
      data: {
        collection: TABLE_INVOICES_COLLECTION,
        id: invoice.id,
      },
    });

    deleteDocument({
      data: {
        collection: INVOICES_COLLECTION,
        id: invoice.id,
      },
    }).then(() => {
      history.push(ROUTE_INVOICES);
    });
  };

  const handleCancel = async () => {
    // Set invoice status to 'cancel'
  };

  const [openChangeInvoiceNumber, setOpenChangeInvoiceNumber] = useState(false);
  // Convert to number
  const handleNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setNewInvoiceNumber(value);
  }, []);

  useEffect(() => {
    if (orgData) {
      if (invoice) {
        const tagsQueryHook = invoice?.tagsQueryHook;
        setSelectedTags(tagsQueryHook);
      }
    }
  }, [orgData, tags]);

  //
  const [taxAnchor, setTaxAnchor] = useState<null | HTMLElement>(null);

  const handleTaxChangeClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setTaxAnchor(event.currentTarget as HTMLElement);
  }, []);

  // Save Menu
  const [anchorSaveMenuEl, setAnchorSaveMenuEl] = useState<null | HTMLElement>(null);

  const handleSaveClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorSaveMenuEl(event.currentTarget as HTMLElement);
  }, []);

  const handleClose = () => {
    setAnchorSaveMenuEl(null);
  };

  const savePopperOpen = Boolean(anchorSaveMenuEl);
  const savePopoverId = savePopperOpen ? "save-popover" : undefined;
  // >>>> Save Menu: End

  const [chatDrawer, setChatDrawer] = useState<{
    visible: boolean;
    invoice: Invoice | null;
  }>({
    visible: false,
    invoice: null,
  });

  const handleChatClick = useCallback((i: Invoice) => {
    setChatDrawer({
      visible: true,
      invoice: i,
    });
  }, []);

  // *********************************
  // MARK: Make Payment Link
  // *********************************

  const [paymentLinkUrlCopied, setPaymentLinkUrlCopied] = useState(false);

  // Copy Payment Link to Clipboard
  const copyToClipboard = () => {
    paymentLinkUrl &&
      navigator.clipboard
        .writeText(paymentLinkUrl)
        .then(() => {
          setPaymentLinkUrlCopied(true);
          setTimeout(() => setPaymentLinkUrlCopied(false), 1500);
        })
        .catch((err) => console.log(err));
  };

  const makePaymentLink = async(event: React.MouseEvent<HTMLButtonElement>) => {
    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    let commandClicked = false;
    if (event.metaKey || event.ctrlKey) {
      console.log("Command/Ctrl + Click detected");
      commandClicked = true;
    } 

    // create a send record.

    // await handleComplete({ type: invoice.type, redirect: null }); // TODO
    await handleSaveInvoice({
      showToast: false,
      type: invoice?.type || "draft",
    });

    setMakingPaymentLink(true);

    const balanceFulfilled = dollarToCents(invoice?.balanceFulfilled);
    const total = dollarToCents(invoiceTotalData.total);
    const balance = total - balanceFulfilled;
    console.log("Balance: ", balance);
    console.log("Total: ", total);

    let balanceRemaining = balance;
    console.log("Balance Remaining: ", balanceRemaining);

    if (commandClicked) {
      balanceRemaining = Math.round(balanceRemaining * (orgData.defaultDepositPercent / 100));
    }

    const sendRecordPayload = {
      createdOn: new Date(),
      createdBy: userData.id,
      paymentAmt: balanceRemaining,
      sendPaymentLink: true,
    };

    const ref = firestore.collection(`${fsOrgPrefix}${INVOICES_COLLECTION}/${invoice?.id}/${SEND_RECORDS}`).doc();
    await ref.set({
      id: ref.id,
      ...sendRecordPayload,
    });

    // Generate the payment link:

    let paymentsLink = "";
    paymentsLink = `https://payments.rentkit.com/?invoice=${invoice?.id}&sendRecord=${ref.id}`;

    let estimateLink = "";
    estimateLink = `https://payments.rentkit.com/estimate/?estimate=${invoice?.id}&sendRecord=${ref.id}`;

    let linkToUse = "";
    if (invoice?.type === "invoice") {
      linkToUse = paymentsLink;
    } else if (invoice?.type === "estimate") {
      linkToUse = estimateLink;
    }

    setMakingPaymentLink(false);
    enqueueSnackbar("Payment Link Copied To Your Clipboard.", {
      variant: "success",
    });
    setPaymentLinkUrl(linkToUse);
    navigator.clipboard.writeText(linkToUse);
  };

  // *********************************
  // MARK: Delivery Fee Calc
  // *********************************

  const [receiveFeeCalculated, setReceiveFeeCalculated] = useState<{
    cost: number;
    distance: number;
  } | null>(null);
  const [returnFeeCalculated, setReturnFeeCalculated] = useState<{
    cost: number;
    distance: number;
  } | null>(null);

  const [loadingReceiveFee, setLoadingReceiveFee] = useState(false);
  const [loadingReturnFee, setLoadingReturnFee] = useState(false);
  const [applyRoundTrip, setApplyRoundTrip] = useState(true);

  const getShippingFee = async (method: string) => {
    if (!orgData) return;

    if (method === "onSite") {
      setLoadingReturnFee(true);
    } else {
      setLoadingReceiveFee(true);
    }

    const url = `${functionsBaseUrl}getShippingFee`;

    const data = {
      orgId: orgData.id,
      address: {
        line1: method === "onSite" ? receiveReturnDetails.manualReturnAddress : receiveReturnDetails.manualReceiveAddress,
        line2: "",
        city: method === "onSite" ? receiveReturnDetails.manualReturnCity : receiveReturnDetails.manualReceiveCity,
        state: method === "onSite" ? receiveReturnDetails.manualReturnState : receiveReturnDetails.manualReceiveState,
        zipCode: method === "onSite" ? receiveReturnDetails.manualReturnZip : receiveReturnDetails.manualReceiveZip,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      if (method === "onSite") {
        setReturnFeeCalculated(responseData);
      } else {
        setReceiveFeeCalculated(responseData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      if (method === "onSite") {
        setLoadingReturnFee(false);
      } else {
        setLoadingReceiveFee(false);
      }
    }
  };

  // *********************************
  // MARK: QBO Sync Invoice
  // *********************************
  function onManualSyncComplete(success: boolean) {
    setShowSyncModal(false);
    if (success) {
      enqueueSnackbar("Sync complete", { variant: "success" });
    } else {
      enqueueSnackbar("Unable to sync invoice. Please contact RentKit support.", { variant: "error" });
    }
  }

  const prepareToShowPickList = async () => {
    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    await handleSaveInvoice({
      showToast: false,
      type: invoice?.type || "draft",
    });
    setShowMakePickList(true);
  };

  // *********************************
  // MARK: Duplicate Invoice
  // *********************************
  const [duplicating, setDuplicating] = useState(false);

  const handleDuplicate = async () => {
    if (!invoice) return;

    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    const result = await handleSaveInvoice({
      showToast: false,
      type: invoice.type,
    });
    const snap = result?.snap;

    setDuplicating(true);

    const payload = ConfigureDuplicateInvoice(snap, userData, nextInvoiceNumber);

    updateOrgDocument({
      data: {
        payload: {
          currentInvoiceCount: nextInvoiceNumber,
        },
        id: orgData && orgData.id,
      },
    })
      .then(() => {
        newDocument({
          data: { collection: INVOICES_COLLECTION, payload },
        })
          .then(async (newId) => {
            await sleep(2_000);
            enqueueSnackbar(`Invoice duplicated as #${nextInvoiceNumber}`, { variant: "success" });

            // reload window with new id
            history.push(`${ROUTE_EDIT_INVOICE}${newId}`);
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar("Something went wrong", { variant: "error" });
          })
          .finally(() => {
            setDuplicating(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setDuplicating(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  };

  // ****************************
  // MARK: Send Email
  // ****************************

  const [anchorSendMenuEl, setAnchorSendMenuEl] = useState<null | HTMLElement>(null);

  const handleSendClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorSendMenuEl(event.currentTarget as HTMLElement);
  }, []);

  const handleCloseSendMenu = () => {
    setAnchorSendMenuEl(null);
  };

  const sendPopperOpen = Boolean(anchorSendMenuEl);
  const sendPopoverId = sendPopperOpen ? "save-popover" : undefined;

  const [sendType, setSendType] = useState("invoice");
  const handleSendEmail = async ({ type }: { type: string }) => {
    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    setSendType(type);

    let saveType = type === "invoice" ? "invoice" : "estimate";
    if (type === "signatureRequest") {
      saveType = invoice?.type || "invoice";
    }

    try {
      await handleSaveInvoice({
        showToast: false,
        type: saveType,
      });

      setAnchorSendMenuEl(null);
      setOpenSendEmailDialog(true);
    } catch (error) {
      console.error("Error saving invoice:", error);
    }
  };

  const prepareToShowSendEmail = async (event: MouseEvent<HTMLElement>) => {
    setAnchorSendMenuEl(event.currentTarget as HTMLElement);
  };

  useEffect(() => {
    if (orgData.trackInventoryWeight) {
      const totalWeight = selectedItems.reduce((accumulator, currentItem) => {
        if (currentItem.weight !== undefined && currentItem.selectedQty !== undefined) {
          return accumulator + currentItem.weight * currentItem.selectedQty;
        } else {
          return accumulator;
        }
      }, 0);
      setTotalWeight(totalWeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  // MARK: Availability Number
  const rentalAvailabilityNumber = useCallback(
    (item: any) => {
      const availabilityItem = availableInventory.find((a) => a.id === item.id) as any;

      if (!availabilityItem) {
        return null;
      }

      const availability = availabilityItem.stock - availabilityItem.unavailableStock;

      return availability;
    },
    [availableInventory]
  );

  useEffect(() => {
    let overbooked = false;

    for (const item of selectedItems) {
      const availabilityItem = availableInventory.find((a) => a.id === item.id) as any;

      if (availabilityItem) {
        const availability = availabilityItem.stock - availabilityItem.unavailableStock;

        if (availability && availability - item.selectedQty < 0) {
          overbooked = true;
          break; // Exit early if overbooked
        }
      }
    }

    setHasOverbooked(overbooked);
  }, [availableInventory, selectedItems]);

  const bundleAvailabilityData = useCallback(
    (i: any) => {
      const availabilityItem = availableInventory.find((a) => a.id === i.id) as any;

      if (!availabilityItem) {
        return null;
      }

      const bundleAvailability = availabilityItem.stock - (availabilityItem.unavailableStock ?? 0);

      // Calculate availability based on subcomponents
      const subComponentAvailabilities = availabilityItem.bundleItems.map((subItem: any) => {
        const subItemInInventory = fullInventory.find((invItem) => invItem.id === subItem.bundleItemId);
        if (subItemInInventory) {
          const subItemAvailability = (subItemInInventory.stock ?? 0) - ((subItemInInventory as any).unavailableStock ?? 0);
          return {
            lcd: Math.floor(subItemAvailability / subItem.bundleItemQty),
            avail: subItemAvailability, // This should be the available stock, not unavailable
          };
        }
        return {
          lcd: 0,
          avail: 0,
        };
      });

      // Find the limiting factor (minimum availability among bundle and subcomponents)
      let limitingAvailability = Math.min(bundleAvailability, ...subComponentAvailabilities.map((item: any) => item.lcd));
      if (!i.autoCalculateStock) {
        limitingAvailability = availabilityItem.stock;
      }

      const bundleData: { [key: string]: number } = {
        [i.id]: limitingAvailability,
      };

      // Add subcomponent availabilities to bundleData
      availabilityItem.bundleItems.forEach((subItem: any, index: number) => {
        bundleData[subItem.bundleItemId] = subComponentAvailabilities[index].avail;
      });

      if (limitingAvailability && limitingAvailability - i.selectedQty < 0) {
        console.log("Overbooked Inventory");
        setHasOverbooked(true);
      }

      return bundleData;
    },
    [availableInventory, fullInventory]
  );

  const handleSetItemToReplace = useCallback(
    (item: any) => {
      setInventoryItemToReplace(item);
      pickInventory.onToggle();
    },
    [pickInventory]
  );

  const handleInsertLinkedItems = useCallback((item: SelectedItem, linkedItems: InventoryItem[]) => {
    console.log("handleInsertLinkedItems", item);
    console.log("linkedItems", linkedItems);

    // Now use the handleAdd to insert each item.
    for (const linkedItem of linkedItems) {
      handleNewItemSelected(linkedItem, 1);
    }
  }, [handleNewItemSelected]);

  const handleSubrentRequest = useCallback(() => {
    console.log("handleSubrentRequest");
    setShowSubrentRequest(true);
  }, []);

  // *********************************
  // MARK: SAVE INVOICE
  // *********************************

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    title: "",
    content: "",
    onClose: () => {},
  });

  const showConfirmDialog = (title: any, content: any) => {
    return new Promise((resolve) => {
      setDialogConfig({ title, content, onClose: resolve });
      setDialogOpen(true);
    });
  };

  const handleSaveInvoice = async ({ showToast = true, type = "invoice" } = {}) => {
    if (demoMode) {
      enqueueSnackbar("Demo mode is enabled. Changes will not be saved.", { variant: "info" });
      return;
    }

    // Access the current state directly from the ref
    //  const currentAvailability = inventoryAvailabilityRef.current;
    //  const overbooked = currentAvailability.state.showOverbookedError;
    let overbooked = false; // todo

    const latestOverbooked = overbookedRef.current;
    if (latestOverbooked) {
      const confirmed = await showConfirmDialog("Inventory Overbooked", "One or more items are overbooked. Do you want to continue saving?");
      if (!confirmed) {
        enqueueSnackbar("Save cancelled due to overbooked inventory.", { variant: "info" });
        return;
      }
    }

    // TODO: Add validation for required fields
    if (customer === null) {
      enqueueSnackbar("Please select a customer.", { variant: "error" });
      throw new Error("Please select a customer.");
    }

    const formattedItems = selectedItems.map((item) => {
      if (item.rowType === "subtotal") {
        return item;
      }
      return CheckSelectedItem(item);
    });

    const selectedItemsObj = {
      items: formattedItems,
      count: selectedItems.length,
    };

    const latestRentalRange = rentalRangeRef.current;
    const latestReceiveReturnDetails = receiveReturnDetailsRef.current;
    const configuredSelectedItems = configureSelectedItems(selectedItemsObj);
    const qrItems = configureQuickReportProps(selectedItemsObj, type, invoice);

    const configuredReceiveReturnTimeDetails = configureReceiveReturnTimes(orgData, latestReceiveReturnDetails, latestRentalRange, selectedItems);

    console.log("configuredReceiveReturnTimeDetails", configuredReceiveReturnTimeDetails);

    const specifiedReceiveTime = latestReceiveReturnDetails.specifiedReceiveTime
        ? formatDateOnWrite({ date: latestReceiveReturnDetails.specifiedReceiveTime, orgTimezone: orgData.orgTimezone })
        : null
    const specifiedReturnTime = latestReceiveReturnDetails.specifiedReturnTime
        ? formatDateOnWrite({ date: latestReceiveReturnDetails.specifiedReturnTime, orgTimezone: orgData.orgTimezone })
        : null
    const specifiedReceiveTimeEnd = latestReceiveReturnDetails.specifiedReceiveTimeEnd
        ? formatDateOnWrite({ date: latestReceiveReturnDetails.specifiedReceiveTimeEnd, orgTimezone: orgData.orgTimezone })
        : null
    const specifiedReturnTimeEnd = latestReceiveReturnDetails.specifiedReturnTimeEnd
        ? formatDateOnWrite({ date: latestReceiveReturnDetails.specifiedReturnTimeEnd, orgTimezone: orgData.orgTimezone })
        : null


    if (configuredReceiveReturnTimeDetails.hasSpecifiedReceiveTime) {
      if (!specifiedReceiveTime) {
        enqueueSnackbar("Please specify a receive time, or uncheck the receive window.", { variant: "error" });
        throw new Error("Please specify a receive time, or uncheck the receive window.");
      }
    }
    if (configuredReceiveReturnTimeDetails.hasSpecifiedReturnTime) {
      if (!specifiedReturnTime) {
        enqueueSnackbar("Please specify a return time, or uncheck the return window.", { variant: "error" });
        throw new Error("Please specify a return time, or uncheck the return window.");
      }
    }

    const configureReceiveReturnAddressDetails = configureDeliveryDetailsMUI(customer, latestReceiveReturnDetails, customerAddressChecked);
    /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
      // "customerQueryHook": "",
      // "customerAddressChecked": {
      //   "receive": true,
      //   "return": true
      // },
      "deliveryCustomerShippingAddressStr": "",
      "deliveryCustomerDisplayName": "",
      "deliveryCustomerShippingAddress": "",
      "deliveryCustomerShippingCity": "",
      "deliveryCustomerShippingState": "",
      "deliveryCustomerShippingZip": "",
      "onSiteCustomerShippingAddressStr": "",
      "onSiteCustomerDisplayName": "",
      "onSiteCustomerShippingAddress": "",
      "onSiteCustomerShippingCity": "",
      "onSiteCustomerShippingState": "",
      "onSiteCustomerShippingZip": ""
    */

    // Calculate difference between rental range start and end
    const rentalRangeStart = latestRentalRange[0];
    const rentalRangeEnd = latestRentalRange[1];

    let rentalDays = 0;
    if (rentalRangeStart && rentalRangeEnd) {
      const rentalRangeDifference = rentalRangeEnd.getTime() - rentalRangeStart.getTime();
      rentalDays = Math.round(rentalRangeDifference / (1000 * 3600 * 24));
    }

    const latestIsSalesInvoice = await new Promise((resolve) => {
      setIsSalesInvoice((currentState) => {
        resolve(currentState);
        return currentState;
      });
    });

    const updateData: any = {
      isSalesInvoice: latestIsSalesInvoice,
      totalWeight: totalWeight,
      ...qrItems,
      ...configuredSelectedItems,
      /* ^^^^^^^^^^^^^^^^^^^^^^^
        items
        count
      */

      ...configuredReceiveReturnTimeDetails,
      /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        rentalDates
        rentalDateStart
        mobileStartDate
        hasSpecifiedReceiveTime
        // specifiedReceiveTime
        // specifiedReceiveTimeEnd
        hasSpecifiedReturnTime
        hasReceiveWindow
        // specifiedReturnTime
        // specifiedReturnTimeEnd
        rentalDateEnd
        mobileEndDate
        hasReturnWindow
        rentalTurnaroundEnd
      */

      ...configureReceiveReturnAddressDetails,

      specifiedReceiveTime: specifiedReceiveTime,
      specifiedReturnTime: specifiedReturnTime,
      specifiedReceiveTimeEnd: specifiedReceiveTimeEnd,
      specifiedReturnTimeEnd: specifiedReturnTimeEnd,

      rentalRange: rentalDays,

      total: dollarToCents(invoiceTotalData.total),
      subtotal: dollarToCents(invoiceTotalData.subtotal),
      taxAmt: dollarToCents(invoiceTotalData.taxAmt),

      taxRate: selectedTaxRate ? selectedTaxRate.rate : 0,
      taxMetaData: selectedTaxRate,

      receiveMethod: latestReceiveReturnDetails.receiveBy,
      returnMethod: latestReceiveReturnDetails.returnBy,
      receiveVenueQueryHook: latestReceiveReturnDetails.selectedReceiveVenue,
      returnVenueQueryHook: latestReceiveReturnDetails.selectedReturnVenue,

      invoicePaymentDueDate: paymentDueDate ? formatDateOnWrite({ date: paymentDueDate, orgTimezone: orgData.orgTimezone }) : null,
      eventDate: eventDate ? formatDateOnWrite({ date: eventDate, orgTimezone: orgData.orgTimezone }) : null,
      orgId: orgData.id,
      customer: customer,
      customerQueryHook: customer?.id ?? "",
      contactPhoneNumber: contactPhoneNumber,
      selectedContact: selectedContact,
      internalNotes: invoiceInternalNotes,
      notes: invoiceNotes,
      salesRepId: selectedSalesRep,
      salesRepName: users.find((user) => user.id === selectedSalesRep)?.name ?? "",

      updatedBy: userData.id,
      updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
      deliveryFee: dollarToCents(deliveryFee),
      deliveryFeeTaxable: deliveryFeeTaxable,
      type: type,

      totalDiscountNumber: dollarToCents(invoiceTotalData.totalDiscountNumber),
      totalDiscountPercent: invoiceTotalData.totalDiscountPercent,
      totalDiscountType: invoiceTotalData.totalDiscountType,
      hasTotalDiscount: invoiceTotalData.hasTotalDiscount,
    };

    if (!invoice) {
      updateData.createdBy = userData.id;
      updateData.createdOn = firebase.firestore.FieldValue.serverTimestamp();
      updateData.invoiceNumber = nextInvoiceNumber;
      updateData.invoiceSource = "adelie";
    }

    try {
      let refreshed: typeof firebase.firestore.DocumentSnapshot;

      if (invoice) {
        await DB.collection("orgs").doc(orgData.id).collection("invoices").doc(invoice.id).update(updateData);
        refreshed = await DB.collection("orgs").doc(orgData.id).collection("invoices").doc(invoice.id).get();

        // Sync to QBO if integrated.
        if (orgData.integrations.qbOnline === true && invoice.type === "invoice") {
          const call = functions.httpsCallable("manualInvoiceSync");

          call({
            orgId: orgData.id,
            invoiceId: invoice.id,
          });
        }

        const checked = checkInvoices(refreshed, orgData.orgTimezone);
        setInvoice(checked);

        if (showToast) {
          enqueueSnackbar("Invoice updated", { variant: "success" });
        }

        return {
          invoice: checked,
          snap: refreshed,
        };
      } else {
        const newRef = DB.collection("orgs").doc(orgData.id).collection("invoices").doc();
        updateData.id = newRef.id;
        await newRef.set(updateData);
        refreshed = await newRef.get();

        history.replace({
          pathname: `${ROUTE_EDIT_INVOICE}${refreshed.id}`,
        });
      }
    } catch (error) {
      console.log("Error updating invoice: ", error);
      enqueueSnackbar("Error updating invoice", { variant: "error" });
    }
  };

  // MARK: Render Item Content
  const renderItemContent = useCallback(
    (item: any, index: number) => {
      switch (item.rowType) {
        case "singleItem":
          const extraItemIds = [
            ...(item.linkedItemQueryHooks || []), 
            ...(item.addOnItemQueryHooks || []), 
            ...(item.replacementItemQueryHooks || [])
          ];
          const uniqueExtraItemIds = Array.from(new Set(extraItemIds));
          const uniqueExtraItems = uniqueExtraItemIds.map((id) => fullInventory.find((item) => item.id === id)).filter((item) => item !== undefined);

          if (item.type === "rental" || item.type === "sale") {
            return (
              <RentalRow
                item={item}
                extraItems={uniqueExtraItems}
                vendors={vendors}
                orgData={orgData}
                index={index}
                isSalesInvoice={isSalesInvoice}
                selectedItems={selectedItems}
                availability={rentalAvailabilityNumber(item)}
                loadingAvailability={fetchingInvoices}
                handleUpdateSelectedItem={handleUpdateSelectedItem}
                handleInsertLinkedItems={handleInsertLinkedItems}
                handleSubrentRequest={handleSubrentRequest}
                onChangeItem={() => handleSetItemToReplace(item)}
                onDeleteRow={() => removeItem(index)}
                handleInsertItem={() => handleInsertItemAtIndex(index)}
                handleInsertSpecial={memoizedHandleInsertSpecial}
                handleSubstituteItemClicked={handleSubstituteItemClicked}
              />
            );
          } else if (item.type === "bundle") {
            return (
              <BundleRow
                item={item}
                orgData={orgData}
                index={index}
                isSalesInvoice={isSalesInvoice}
                selectedItems={selectedItems}
                availability={availableInventory}
                loadingAvailability={fetchingInvoices}
                fullInventory={fullInventory}
                handleUpdateSelectedItem={handleUpdateSelectedItem}
                onChangeItem={() => handleSetItemToReplace(item)}
                onDeleteRow={() => removeItem(index)}
                handleInsertItem={() => handleInsertItemAtIndex(index)}
                handleInsertSpecial={memoizedHandleInsertSpecial}
              />
            );
          }
          break;
        case "subtotal":
          return (
            <SubtotalRow
              item={item}
              index={index}
              selectedItems={selectedItems}
              handleUpdateSelectedItem={handleUpdateSelectedItem}
              onDeleteRow={() => removeItem(index)}
              handleInsertItem={() => handleInsertItemAtIndex(index)}
              handleInsertSpecial={memoizedHandleInsertSpecial}
            />
          );

        case "flatFee":
          return <FlatFeeRow item={item} index={index} handleUpdateSelectedItem={handleUpdateSelectedItem} onDeleteRow={() => removeItem(index)} />;

        case "descriptionOnly":
          return <DescriptionOnlyRow item={item} index={index} handleUpdateSelectedItem={handleUpdateSelectedItem} onDeleteRow={() => removeItem(index)} />;
        default:
          return null;
      }
    },
    [
      orgData,
      isSalesInvoice,
      rentalAvailabilityNumber,
      fetchingInvoices,
      availableInventory,
      fullInventory,
      handleUpdateSelectedItem,
      handleSetItemToReplace,
      removeItem,
      handleInsertItemAtIndex,
      memoizedHandleInsertSpecial,
      selectedItems,
      // loadingDailyStatus,
      // getDailyStatusAvailability,
    ]
  );




  // Keyboard Shortcuts
  useEffect(() => {
    const handleCommandShiftDDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === "d") {
        event.preventDefault(); // Prevent the default action (select all text)
        handleCommandShiftD();
      }
    };

    const handleCommandSDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault(); // Prevent the default action (save)
        handleCommandS();
      }
    };
    const handleCommandShiftSDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key === "s") {
        event.preventDefault(); // Prevent the default action (save)
        handleCommandShiftS();
      }
    };
    window.addEventListener("keydown", handleCommandSDown);
    window.addEventListener("keydown", handleCommandShiftSDown);
    window.addEventListener("keydown", handleCommandShiftDDown);
    return () => {
      window.removeEventListener("keydown", handleCommandSDown);
      window.removeEventListener("keydown", handleCommandShiftSDown);
      window.removeEventListener("keydown", handleCommandShiftDDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedItems,
    receiveReturnDetails,
    customer,
    invoiceNotes,
    invoiceInternalNotes,
    contactPhoneNumber,
    selectedSalesRep,
    selectedContact,
    selectedTags,
    deliveryFee,
    // hasTotalDiscount,
    // totalDiscountType,
    // totalDiscountPercent,
    // totalDiscountNumber,
    deliveryFeeTaxable,
    // hasTotalDiscount,
    invoiceTotalData,
    selectedTaxRate,
    selectedItems,
    eventDate,
    paymentDueDate,
    newInvoiceNumber,
    invoice,
    userData,
    handleDuplicate,
  ]);



  // ****************************
  // MARK: Main JSX
  // ****************************

  return (
    <Box sx={{ m: 1 }}>
      {/* MARK: Sticky Header */}
      <StickyHeader
        invoice={invoice}
        orgData={orgData}
        title={title}
        selectedTags={selectedTags}
        tags={tags}
        invoiceNumbers={invoiceNumbers}
        handleChatClick={handleChatClick}
        handleSaveClick={handleSaveClick}
        handleNumberChange={handleNumberChange}
        handleConfirmInvoiceChange={handleConfirmInvoiceChange}
        handleDuplicate={handleDuplicate}
        duplicating={duplicating}
        setOpenChangeInvoiceNumber={setOpenChangeInvoiceNumber}
        makePaymentLink={makePaymentLink}
        makingPaymentLink={makingPaymentLink}
        downloadingPDF={downloadingPDF}
        printInvoicePullSheet={printPullSheet}
        downloadInvoicePDF={downloadInvoicePDF}
        setDownloadingPDF={setDownloadingPDF}
        setShowMakePickList={setShowMakePickList}
        setOpenSendEmailDialog={prepareToShowSendEmail}
        openChangeInvoiceNumber={openChangeInvoiceNumber}
        changingInvoice={changingInvoice}
        hasPaymentProcessor={hasPaymentProcessor}
        invoiceChangeResult={invoiceChangeResult}
        confirmDelete={confirmDelete}
        confirmCancel={confirmCancel}
        showHistoryDialog={showHistoryDialog}
        setShowSyncModal={setShowSyncModal}
        navigateToPayment={navigateToPayment}
        demoMode={demoMode}
        setShowSubrentRequest={setShowSubrentRequest}
      />

      {demoMode && (
        <Box
          sx={{
            p: 1,
            borderRadius: 1,
            backgroundColor: "purple",
          }}
        >
          <Stack
            gap={2}
            direction={"row"}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color={"white"} fontWeight={"bold"}>
              You are in demo mode. No saves are made so you can play around freely. Some features disabled. Please let us know if you find any bugs or have
              feedback.
            </Typography>

            <Button
              onClick={() => {
                window.open("https://www.youtube.com/watch?v=3OgL3F4pEjs", "_blank");
              }}
              variant="contained"
            >
              Learn More
            </Button>
          </Stack>
        </Box>
      )}

      <TabContext value={tab}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            pr: 2,
          }}
        >
          <TabList onChange={handleTabChange} aria-label="invoice tabs">
            <Tab
              label="Order"
              value="1"
              sx={{
                fontSize: "0.8rem", // Smaller font size
                padding: "6px 12px", // Smaller padding
                minWidth: "50px", // Smaller minimum width
              }}
            />

            {invoice && (
              <Tab
                label="Payments"
                value="2"
                sx={{
                  fontSize: "0.8rem",
                  padding: "6px 12px",
                  minWidth: "50px",
                }}
              />
            )}
            {invoice && (
              <Tab
                label="Email Tracking"
                value="3"
                sx={{
                  fontSize: "0.8rem",
                  padding: "6px 12px",
                  minWidth: "50px",
                }}
              />
            )}
            {invoice && (
              <Tab
                label="Invoice History"
                value="4"
                sx={{
                  fontSize: "0.8rem",
                  padding: "6px 12px",
                  minWidth: "50px",
                }}
              />
            )}
          </TabList>

          <Typography fontWeight={"bold"} fontSize={responsiveFontSize} color={"green"}>
            {`Total: ${FormatToLocalCurrency(invoiceTotalData.total, orgData)}`}
          </Typography>
        </Stack>

        <TabPanel sx={{ my: 2, p: 0 }} value="1">
          <>
            {/* //MARK: - Made By Kit */}
            {invoice?.madeByKit && (
              <MadeByKitSection
                invoice={invoice}
                accordianSx={accordianSx}
                accordianSummarySx={accordianSummarySx}
                madeByKitAccordianExpanded={madeByKitAccordianExpanded}
                setMadeByKitAccordianExpanded={setMadeByKitAccordianExpanded}
                responsiveFontSize={responsiveFontSize}
              />
            )}

            <Box sx={{ borderRadius: 2, my: 2 }} bgcolor={theme.palette.background.default}>
              <Accordion
                disableGutters={true}
                sx={{
                  ...accordianSx,
                }}
                defaultExpanded={customerDetailsAccordianExpanded}
                onChange={() => setCustomerDetailsAccordianExpanded(!customerDetailsAccordianExpanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="customer-detail-accordian"
                  id="customer-detail-accordian"
                  sx={{
                    ...accordianSummarySx,
                    borderRadius: customerDetailsAccordianExpanded ? "8px 8px 0 0" : "8px",
                  }}
                >
                  <Stack direction={"row"} gap={0.5}>
                    <Typography fontWeight={"bold"} fontSize={responsiveFontSize}>
                      Customer
                    </Typography>

                    <Tooltip title="Note there is a difference between Customer and Contact information. The customer info is what is attached to the Customer itself. Contact information can be a selected additional contact, or you can enter a phone number manually. This is for if the customer and the person actually handling receipt of items are different. If you select a contact, it will override an existing contact phone number.">
                      <Iconify width={20} icon="fluent:question-circle-12-filled" color={theme.palette.text.secondary} />
                    </Tooltip>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <>
                    <Grid container sx={{ mt: 2 }} gap={isMDAndDown ? 2 : 2}>
                      {/* //MARK: - Customer Info */}
                      <Grid item xs={12} lg={3}>
                        <Stack gap={2}>
                          <Stack gap={2} direction={"row"} alignItems={"center"}>
                            {customer && (
                              <Tooltip title="Edit Customer">
                                <IconButton
                                  onClick={() => {
                                    showEditCustomerModal.onTrue();
                                  }}
                                  size="small"
                                >
                                  <Iconify width={20} icon="tabler:edit" color={theme.palette.text.secondary} />
                                </IconButton>
                              </Tooltip>
                            )}
                            <TextField
                              onClick={() => {
                                if (payments.length > 0) {
                                  return;
                                }

                                showCustomerPicker.onToggle();
                              }}
                              disabled={payments.length > 0}
                              inputProps={{
                                sx: {
                                  fontSize: responsiveFontSize,
                                  readOnly: true,
                                },
                              }}
                              sx={{
                                maxWidth: "300px",
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              InputLabelProps={{
                                style: { fontSize: responsiveFontSize },
                              }}
                              size="small"
                              label="Customer"
                              variant="outlined"
                              value={customer?.customerDisplayName ?? ""}
                            />

                            {customer && orgData && orgData.qboRealmId && orgData.qboRealmId !== "" && (customer.qbId === "" || !customer.qbId) && (
                              <span
                                style={{
                                  marginLeft: "4px",
                                }}
                              >
                                <Tooltip
                                  title={
                                    "This customer is not linked to a QuickBooks customer and will cause this invoice to fail to sync to QuickBooks. You can still save, but be aware you will need to sync this customer at some point in order to sync this invoice to QuickBooks"
                                  }
                                >
                                  <Iconify width={20} icon="ion:warning" color={COLOR_RED_0} />
                                </Tooltip>
                              </span>
                            )}

                            {customer && customer.checkNotesReminder && (
                              <span
                                style={{
                                  marginLeft: "4px",
                                }}
                              >
                                <Tooltip title={`Customer Notes: ${customer.notes}`}>
                                  <Iconify width={20} icon="ion:warning" color={COLOR_YELLOW_0} />
                                </Tooltip>
                              </span>
                            )}
                          </Stack>

                          <Stack gap={1}>
                            <Stack direction={"row"} alignItems={"end"}>
                              <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary}>
                                {customer?.customerShippingAddressStr}
                              </Typography>

                              {customer?.customerShippingAddressStr && (
                                <>
                                  <Tooltip title="Open in Google Maps">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleOpenInGoogleMaps(customer?.customerShippingAddressStr)}
                                      sx={{ ml: 1, p: 0.5 }}
                                    >
                                      <Iconify width={20} icon="tabler:map-pin-filled" color={theme.palette.text.secondary} />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Copy Address">
                                    <IconButton size="small" onClick={() => copy(customer?.customerShippingAddressStr)} sx={{ p: 0.5 }}>
                                      <Iconify width={20} icon="tabler:copy" color={theme.palette.text.secondary} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Stack>

                            <Stack direction={"row"} alignItems={"end"}>
                              <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary}>
                                {customer?.email}
                              </Typography>

                              {customer?.email && (
                                <>
                                  <Tooltip title="Copy Email">
                                    <IconButton size="small" onClick={() => copy(customer?.email)} sx={{ ml: 1, p: 0.5 }}>
                                      <Iconify width={20} icon="tabler:copy" color={theme.palette.text.secondary} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Stack>

                            <Stack direction={"row"} alignItems={"end"}>
                              <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary}>
                                {customer?.phone}
                              </Typography>

                              {customer?.phone && (
                                <>
                                  <Tooltip title="Copy Phone">
                                    <IconButton size="small" onClick={() => copy(customer?.phone)} sx={{ ml: 1, p: 0.5 }}>
                                      <Iconify width={20} icon="tabler:copy" color={theme.palette.text.secondary} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>

                      {/* //MARK: - Contact Info */}
                      <Grid item xs={12} lg={2}>
                        <Stack gap={2}>
                          {customer && customer.additionalContacts && customer.additionalContacts.length > 0 && (
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": {
                                  maxWidth: "25ch",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                id={"sales-rep"}
                                inputProps={{
                                  style: { fontSize: responsiveFontSize },
                                }}
                                InputLabelProps={{
                                  style: { fontSize: responsiveFontSize },
                                }}
                                select
                                size="small"
                                fullWidth
                                label="Contact"
                                value={selectedContact?.id ?? ""}
                                onChange={(e) => {
                                  const selectedContact = customer.additionalContacts.find((contact) => contact.id === e.target.value);
                                  if (selectedContact) {
                                    setSelectedContact(selectedContact);
                                  }
                                }}
                              >
                                {customer.additionalContacts.map((contact) => (
                                  <MenuItem key={contact.id} value={contact.id}>
                                    <Typography fontSize={responsiveFontSize}>{contact.name}</Typography>
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          )}

                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": { maxWidth: "25ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              inputProps={{
                                style: { fontSize: responsiveFontSize },
                              }}
                              inputRef={phoneNumberRef}
                              InputLabelProps={{
                                style: { fontSize: responsiveFontSize },
                              }}
                              size="small"
                              label="Contact Phone Number"
                              variant="outlined"
                              value={contactPhoneNumber}
                              onChange={(e) => setContactPhoneNumber(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (phoneNumberRef.current) {
                                    phoneNumberRef.current.blur();
                                  }
                                }
                              }}
                            />
                          </Box>
                        </Stack>
                      </Grid>

                      {isMDAndDown && (
                        <Grid xs={12}>
                          <Divider
                            sx={{
                              my: 1,
                              borderStyle: "dashed",
                              borderColor: theme.palette.grey[400],
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} lg={2}>
                        <Box
                          component="form"
                          sx={{
                            "& .MuiTextField-root": { maxWidth: "25ch" },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id={"sales-rep"}
                            inputProps={{
                              style: { fontSize: responsiveFontSize },
                            }}
                            InputLabelProps={{
                              style: { fontSize: responsiveFontSize },
                            }}
                            select
                            size="small"
                            // fullWidth
                            sx={{ width: "100%" }}
                            label="Sales Rep"
                            value={selectedSalesRep ?? ""}
                            onChange={(e) => setSelectedSalesRep(e.target.value)}
                          >
                            {users &&
                              users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  <Typography fontSize={responsiveFontSize}>{user.name}</Typography>
                                </MenuItem>
                              ))}
                          </TextField>
                        </Box>
                      </Grid>

                      {isMDAndDown && (
                        <Grid xs={12}>
                          <Divider
                            sx={{
                              my: 1,
                              borderStyle: "dashed",
                              borderColor: theme.palette.grey[400],
                            }}
                          />
                        </Grid>
                      )}

                      {/* //MARK: - Date Pickers */}
                      <Grid item xs={12} lg={true}>
                        <Stack alignItems={isMDAndDown ? "start" : "end"} gap={2}>
                          {isSalesInvoice ? null : (
                            <Tooltip 
                              title={fetchingInvoices ? "Disabled while fetching availability data" : ""}
                              placement="top"
                            >
                              <span> {/* Wrapper needed because Tooltip can't be applied directly to disabled elements */}
                                <DateRangePicker
                                  disabled={fetchingInvoices}
                                  value={[rentalRange[0], rentalRange[1]]}
                                  onAccept={(newValue) => handleDateRangeChange(newValue)}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      InputProps: {
                                        endAdornment: <Calendar />,
                                        style: {
                                          fontSize: responsiveFontSize + 1,
                                        },
                                      },
                                      InputLabelProps: {
                                        style: {
                                          fontSize: responsiveFontSize + 1,
                                          fontWeight: "bold",
                                          color: "black",
                                        },
                                      },
                                    },
                                    actionBar: {
                                      actions: ["today"],
                                    },
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}

                          {orgData.eventDateEnabled && (
                            <DatePicker
                              label={isSalesInvoice ? "Date" : "Event Date"}
                              minDate={rentalRange[0]}
                              sx={{
                                width: "auto",
                              }}
                              value={eventDate}
                              onChange={(newValue) => {
                                setEventDate(newValue);
                                setUserSetEventDate(true);
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  InputProps: {
                                    style: {
                                      fontSize: responsiveFontSize + 1,
                                    },
                                  },
                                  InputLabelProps: {
                                    style: {
                                      fontSize: responsiveFontSize + 1,
                                      fontWeight: "bold",
                                      color: "black",
                                    },
                                  },
                                },
                                actionBar: {
                                  actions: ["today"],
                                },
                              }}
                            />
                          )}

                          <DatePicker
                            label="Payment Due Date"
                            sx={{
                              width: "auto",
                            }}
                            value={paymentDueDate}
                            onChange={(newValue) => {
                              setPaymentDueDate(newValue);
                              setUserSetPaymentDueDate(true);
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  style: {
                                    fontSize: responsiveFontSize + 1,
                                  },
                                },
                                InputLabelProps: {
                                  style: {
                                    fontSize: responsiveFontSize + 1,
                                    fontWeight: "bold",
                                    color: "black",
                                  },
                                },
                              },
                              actionBar: {
                                actions: ["today"],
                              },
                            }}
                          />

                          {orgData.salesInvoiceEnabled && (
                            <Stack alignItems={"center"} direction="row" gap={1}>
                              <Tooltip
                                title={
                                  "If enabled, this invoice will be marked as a sales invoice and certain properties related only to rentals will not be shown."
                                }
                              >
                                <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary}>
                                  Sales Invoice
                                </Typography>
                              </Tooltip>
                              <Switch
                                checked={isSalesInvoice}
                                onChange={(e) => {
                                  setIsSalesInvoice(e.target.checked);
                                }}
                              />
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* MARK: LINE ITEMS */}
            <Box sx={{ borderRadius: 2 }} bgcolor={theme.palette.background.default}>
              <Accordion
                disableGutters={true}
                sx={{ boxShadow: "none", padding: 0 }}
                defaultExpanded={selectedItemsAccordianExpanded}
                onChange={() => setSelectedItemsAccordianExpanded(!selectedItemsAccordianExpanded)}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        pointerEvents: "auto",
                      }}
                    />
                  }
                  aria-controls="invoice-itens-accordian"
                  id="invoice-itens-accordian"
                  sx={{
                    pointerEvents: "none",
                    ...accordianSummarySx,
                    borderRadius: "8px 8px 0 0",
                  }}
                >
                  <Stack
                    direction="row"
                    gap={0.5}
                    sx={{
                      pointerEvents: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={3}>
                      <Typography fontWeight={"bold"} fontSize={responsiveFontSize}>
                        Line Items
                      </Typography>

                      {fetchingInvoices && (
                        <Typography fontWeight={"bold"} color={"yellow"} fontSize={responsiveFontSize - 2}>
                          Loading Availability Data...
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Box sx={{ mt: 1 }}>
                    {/* //MARK: - Header */}
                    {useMediaQuery(theme.breakpoints.up("lg")) && (
                      <LineItemsHeader
                        noItems={selectedItems.length === 0}
                        handleAdd={handleAdd}
                        isSalesInvoice={isSalesInvoice}
                        showShortcuts={showShortcuts}
                        systemOS={systemOS}
                      />
                    )}

                    {isMDAndDown && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={handleAdd}
                        sx={{
                          fontSize: responsiveFontSize - 2,
                        }}
                      >{`Add Item${showShortcuts ? ` - ${systemOS === "mac" ? "⌘ A" : "ctrl A"}` : ""}`}</Button>
                    )}

                    {selectedItems && (
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="selectedItems">
                          {(droppableProvided) => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                              {memoizedSelectedItems.map((item, index) => (
                                <DraggableItem key={`${item.uuid || item.key || index}-${index}`} item={item} index={index}>
                                  <Box sx={{ my: item.rowType === "subtotal" ? 1 : 2, px: 1 }}>{renderItemContent(item, index)}</Box>
                                </DraggableItem>
                              ))}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}

                    {(selectedItems.length > 9 || isSMAndDown) && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={handleAdd}
                        sx={{
                          fontSize: responsiveFontSize - 2,
                          ml: 2,
                        }}
                      >{`Add Item${showShortcuts ? ` - ${systemOS === "mac" ? "⌘ A" : "ctrl A"}` : ""}`}</Button>
                    )}

                    {invoice && invoice.type === "estimate" && (
                      <Typography fontSize={responsiveFontSize - 1} color="orange" sx={{ mt: 2 }}>
                        Availability numbers are if the estimate is converted to an invoice.
                      </Typography>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>

              {isLGAndUp && selectedItemsAccordianExpanded && (
                <Divider
                  sx={{
                    mt: 2,
                    mb: 4,
                    borderStyle: "dashed",
                    borderColor: theme.palette.primary.main,
                  }}
                />
              )}

              <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                <Stack direction="column" gap={0.5}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {paymentLinkUrl && (
                      <>
                        <TextField
                          size="small"
                          label="URL"
                          value={paymentLinkUrl}
                          contentEditable={false}
                          variant="outlined"
                          sx={{ width: "300px" }}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                        <IconButton onClick={copyToClipboard} color="primary">
                          {paymentLinkUrlCopied ? <Check /> : <FileCopyRounded />}
                        </IconButton>
                      </>
                    )}
                  </Stack>
                </Stack>

                {selectedItems.length > 0 && (
                  <Stack direction={"column"} gap={0.5}>
                    {/* Delivery Fee and Discount Column */}
                    <Stack spacing={2} justifyContent="flex-end" alignItems={"flex-end"} direction={"column"} sx={{ width: 1 }}>
                      <Stack direction={"row"} spacing={1}>
                        {/* MARK: Delivery Fee */}

                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: responsiveFontSize } }}
                              // inputProps={{ style: { fontSize: responsiveFontSize - 2} }}
                              checked={deliveryFeeTaxable}
                              onChange={(e) => {
                                setDeliveryFeeTaxable(e.target.checked);
                              }}
                            />
                          }
                          label={<Typography fontSize={responsiveFontSize}>{isMDAndDown ? "Taxable" : "Taxed"}</Typography>}
                        />

                        {(receiveReturnDetails.receiveBy === "delivery" || receiveReturnDetails.returnBy === "onSite") && (
                          <Tooltip title="Calculate Delivery Fee">
                            <IconButton
                              onClick={() => {
                                setShowDeliveryFeeCalculator(true);
                              }}
                              color="primary"
                            >
                              <Iconify icon="tabler:math" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <TextField
                          inputRef={deliveryFeeRef}
                          className={classes.input}
                          inputProps={{
                            style: { fontSize: responsiveFontSize },
                          }}
                          InputLabelProps={{
                            style: { fontSize: responsiveFontSize },
                          }}
                          size="small"
                          label="Delivery Fee"
                          type="number"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  sx={{
                                    typography: "subtitle2",
                                    color: "text.disabled",
                                  }}
                                >
                                  {currencySymbol}
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="0.00"
                          value={deliveryFee === 0 ? "" : deliveryFee}
                          onChange={(e) => setDeliveryFee(Number(e.target.value))}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (deliveryFeeRef.current) {
                                deliveryFeeRef.current.blur();
                              }
                            }
                          }}
                        />
                      </Stack>

                      <Stack direction={"row"} spacing={2}>
                        <Tooltip title="Choose Percent Based or Amount Discount">
                          <PercentNumberSwitch checked={invoiceTotalData.totalDiscountType === "percent" ? true : false} onChange={handleDiscountTypeChange} />
                        </Tooltip>

                        {/* {invoiceTotalData.hasTotalDiscount && (
                              <Tooltip title={FormatToLocalCurrency(configureAmtOff(), orgData)}>
                                <Chip label="Hi" />
                              </Tooltip>
                            )} */}

                        {invoiceTotalData.totalDiscountType === "percent" ? (
                          <TextField
                            inputRef={rateRef}
                            className={classes.input}
                            inputProps={{ style: { fontSize: responsiveFontSize } }}
                            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
                            size="small"
                            label="Percent"
                            variant="outlined"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            placeholder="0"
                            // value={invoiceTotalData.totalDiscountPercent === 0 ? "" : invoiceTotalData.totalDiscountPercent}
                            value={invoiceTotalData.totalDiscountPercent === 0 ? "" : invoiceTotalData.totalDiscountPercent * 100}
                            onChange={handlePercentDiscountUpdate}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (rateRef.current) {
                                  rateRef.current.blur();
                                }
                              }
                            }}
                          />
                        ) : (
                          <TextField
                            inputRef={discountRef}
                            className={classes.input}
                            inputProps={{
                              style: { fontSize: responsiveFontSize },
                            }}
                            InputLabelProps={{
                              style: { fontSize: responsiveFontSize },
                            }}
                            size="small"
                            label="Discount"
                            type="number"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box
                                    sx={{
                                      typography: "subtitle2",
                                      color: "text.disabled",
                                    }}
                                  >
                                    {currencySymbol}
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                            placeholder="0.00"
                            value={invoiceTotalData.totalDiscountNumber === 0 ? "" : invoiceTotalData.totalDiscountNumber}
                            onChange={handleNumberDiscountUpdate}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (discountRef.current) {
                                  discountRef.current.blur();
                                }
                              }
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>

                    <Stack spacing={1} alignItems="flex-end" sx={{ mt: 3, textAlign: "right", typography: "body2" }}>
                      <Stack direction="row">
                        <Box sx={{ color: "text.secondary" }}>Subtotal</Box>
                        <Box sx={{ width: 160, typography: "subtitle2" }}>{FormatToLocalCurrency(invoiceTotalData.subtotal, orgData) || "-"}</Box>
                      </Stack>

                      {/* <Stack direction="row">
                        <Box sx={{ color: "text.secondary" }}>Delivery Fee</Box>
                        <Box
                          sx={{
                            width: 160,
                          }}
                        >
                          {deliveryFee ? `${FormatToLocalCurrency(deliveryFee)}` : "-"}
                        </Box>
                      </Stack> */}

                      <Stack direction="row">
                        <Box sx={{ color: "text.secondary" }}>Discount</Box>
                        <Box
                          sx={{
                            width: 160,
                            ...(invoiceTotalData.hasTotalDiscount && { color: "error.main" }),
                          }}
                        >
                          {invoiceTotalData.hasTotalDiscount ? `- ${FormatToLocalCurrency(calculateDiscountAmount(), orgData)}` : "-"}
                        </Box>
                      </Stack>

                      <Stack direction="row">
                        <Box sx={{ color: "text.secondary" }}>Taxable Subtotal</Box>
                        <Box
                          sx={{
                            width: 160,
                          }}
                        >
                          {invoiceTotalData.totalTaxableAmt ? `${FormatToLocalCurrency(invoiceTotalData.totalTaxableAmt, orgData)}` : "-"}
                        </Box>
                      </Stack>
                      <Stack direction="row">
                        <Box sx={{ color: "text.secondary" }}>Non Taxable Subtotal</Box>
                        <Box
                          sx={{
                            width: 160,
                          }}
                        >
                          {invoiceTotalData.totalNonTaxableAmt ? `${FormatToLocalCurrency(invoiceTotalData.totalNonTaxableAmt, orgData)}` : "-"}
                        </Box>
                      </Stack>

                      {/* MARK: Taxes */}
                      <Stack direction="row" alignItems={"center"}>
                        {orgData &&
                          orgData.qboRealmId &&
                          orgData.qboRealmId !== "" &&
                          selectedTaxRate &&
                          (selectedTaxRate.qbId === "" || !selectedTaxRate.qbId) && (
                            <Tooltip title={"This tax rate is not linked to a QuickBooks rate and will fail to sync to QuickBooks"}>
                              <Iconify width={20} icon="ion:warning" color={COLOR_RED_0} />
                            </Tooltip>
                          )}

                        <Tooltip title="Change Tax Rate">
                          <IconButton onClick={handleTaxChangeClick} color="primary">
                            <Iconify icon="heroicons-solid:receipt-tax" />
                          </IconButton>
                        </Tooltip>

                        <Menu anchorEl={taxAnchor} open={Boolean(taxAnchor)} onClose={() => setTaxAnchor(null)}>
                          {taxes.map((tax) => (
                            <MenuItem
                              key={tax.id}
                              onClick={() => {
                                setSelectedTaxRate(tax);
                                setTaxAnchor(null);
                              }}
                            >
                              {tax.id === selectedTaxRate?.id && (
                                <ListItemIcon>
                                  <Iconify icon="heroicons-solid:check-circle" color={"green"} />
                                </ListItemIcon>
                              )}
                              {`${tax.name} - ${(tax?.rate * 100).toFixed(2)}%`}
                            </MenuItem>
                          ))}
                        </Menu>

                        <Box sx={{ color: "text.secondary" }}>{`Taxes (${selectedTaxRate?.name} - ${(selectedTaxRate?.rate * 100).toFixed(2)}%)`}</Box>
                        <Box sx={{ width: 160 }}>{invoiceTotalData.taxAmt ? FormatToLocalCurrency(invoiceTotalData.taxAmt, orgData) : "-"}</Box>
                      </Stack>

                      <Stack direction="row" sx={{ typography: "subtitle1" }}>
                        <Box>Total</Box>
                        <Box sx={{ width: 160 }}>{FormatToLocalCurrency(invoiceTotalData.total, orgData) || "-"}</Box>
                      </Stack>

                      <Divider
                        sx={{
                          width: "100%",
                          pt: 2,
                          borderStyle: "dashed",
                          borderColor: theme.palette.primary.main,
                        }}
                      />

                      {orgData && orgData.trackInventoryWeight && totalWeight > 0 && (
                        <Box sx={{ color: "text.secondary" }}>{`Total Weight: ${totalWeight} (lb/kg)`}</Box>
                      )}

                      {(invoice?.balanceFulfilled ?? 0) > 0 && (
                        <Box sx={{ color: "text.secondary" }}>{`Fulfilled: ${FormatToLocalCurrency(invoice?.balanceFulfilled, orgData)}`}</Box>
                      )}

                      {(invoiceTotalData.total - (invoice?.balanceFulfilled ?? 0)) != null && (
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {`Balance Remaining: ${FormatToLocalCurrency(invoiceTotalData.total - (invoice?.balanceFulfilled ?? 0), orgData)}`}
                        </Typography>
                      )}

                      {invoice?.paymentComplete && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            {`Paid In Full`}
                          </Typography>

                          {invoice && invoice?.paymentComplete && <CheckCircleIcon color="success" />}
                        </Stack>
                      )}

                      {invoice?.estimateSignatures && invoice?.estimateSignatures.length > 0 && (
                        <Box>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              {orgData?.id === "thdqZKWV6zwaUGeIfKms" ? `Signed For Estimate: V${contractVersion}` : "Signed For Estimate"}
                            </Typography>
                            <CheckCircleIcon color="success" />
                          </Stack>
                        </Box>
                      )}

                      {invoice?.paymentSignatures && invoice?.paymentSignatures.length > 0 && (
                        <Box>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                              {orgData?.id === "thdqZKWV6zwaUGeIfKms" ? `Signed For Invoice: V${contractVersion}` : "Signed For Invoice"}
                            </Typography>
                            <CheckCircleIcon color="success" />
                          </Stack>
                        </Box>
                      )}
                      {/* </Box> */}
                    </Stack>
                  </Stack>
                )}
              </Box>
            </Box>

            {/* //MARK: - RECEIVE RETURN */}
            {isSalesInvoice ? null : (
              <Box sx={{ borderRadius: 2, mt: 2 }} bgcolor={theme.palette.background.default}>
                <Accordion
                  disableGutters={true}
                  sx={{ boxShadow: "none" }}
                  defaultExpanded={receiveDetailsAccordianExpanded}
                  onChange={() => setReceiveDetailsItemsAccordianExpanded(!receiveDetailsAccordianExpanded)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="receive-return-accordian"
                    id="receive-return-accordian"
                    sx={{
                      borderRadius: receiveDetailsAccordianExpanded ? "8px 8px 0 0" : "8px",
                      ...accordianSummarySx,
                    }}
                  >
                    <Typography fontWeight={"bold"} fontSize={responsiveFontSize}>
                      Receive and Return Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {receiveDetails && returnDetails && receiveReturnDetails && (
                        <>
                          <ReceiveDetailsMUI
                            orgData={orgData}
                            invoice={invoice}
                            receiveReturnDetails={receiveDetails}
                            customer={customer}
                            rentalRange={rentalRange}
                            venues={venues}
                            updateParentState={handleReceiveReturnDetailsChange}
                          />

                          <ReturnDetailsMUI
                            orgData={orgData}
                            invoice={invoice}
                            receiveReturnDetails={returnDetails}
                            customer={customer}
                            rentalRange={rentalRange}
                            venues={venues}
                            updateParentState={handleReceiveReturnDetailsChange}
                          />
                        </>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {/* //MARK: - NOTES */}
            <Box sx={{ borderRadius: 2, my: 2, mb: 6 }} bgcolor={theme.palette.background.default}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                disableGutters={true}
                sx={{ boxShadow: "none" }}
                defaultExpanded={additionalNotesAccordianExpanded}
                onChange={() => setAdditionalNotesAccordianExpanded(!additionalNotesAccordianExpanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="additional-notes-accordian"
                  id="additional-notes-accordian"
                  sx={{
                    ...accordianSummarySx,
                    borderRadius: additionalNotesAccordianExpanded ? "8px 8px 0 0" : "8px",
                  }}
                >
                  <Typography fontWeight={"bold"} fontSize={responsiveFontSize}>
                    Additional Notes
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Box sx={{}}>
                    <Typography sx={{ mb: 2 }} fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize}>
                      Notes visible to customer
                    </Typography>

                    <TextField
                      inputProps={{
                        style: { fontSize: responsiveFontSize },
                      }}
                      InputLabelProps={{
                        style: { fontSize: responsiveFontSize },
                      }}
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      label="Notes"
                      value={invoiceNotes}
                      onChange={(e) => setInvoiceNotes(e.target.value)}
                      onBlur={(e) => setInvoiceNotes(e.target.value)}
                    />

                    <Typography sx={{ my: 2 }} fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize}>
                      {`${orgData.companyName}`} Internal Notes
                    </Typography>

                    <TextField
                      inputProps={{
                        style: { fontSize: responsiveFontSize },
                      }}
                      InputLabelProps={{
                        style: { fontSize: responsiveFontSize },
                      }}
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      label="Internal Notes"
                      value={invoiceInternalNotes}
                      onChange={(e) => setInvoiceInternalNotes(e.target.value)}
                      onBlur={(e) => setInvoiceInternalNotes(e.target.value)}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </>
        </TabPanel>

        <TabPanel value="2">
          <InvoicePaymentHistory invoiceId={invoice?.id} />
        </TabPanel>

        <TabPanel value="3">
          <InvoiceSendRecords invoiceId={invoice?.id} />
        </TabPanel>

        {invoice && (
          <TabPanel value="4">
            <InvoiceHistoryTab
              currenInvoice={invoice}
              orgData={orgData}
              users={users}
              handleReplaceInvoice={(i) => {
                window.location.reload();
              }}
              demoMode={demoMode}
            />
          </TabPanel>
        )}
      </TabContext>

      {/* <BreakpointIndicator /> */}

      {/* <Typography variant="body1" gutterBottom>
          Press <strong>Ctrl + O</strong> to open the popover with options. Use the arrow keys to navigate and Enter to select an option.
        </Typography> */}
      {/* <InvoiceCommandPopper handleSaveInvoice={handleSaveInvoice} handleSendEmail={handleSendEmail} /> */}

      {/* //MARK: Save Menu */}

      <Popover
        id={savePopoverId}
        open={savePopperOpen}
        anchorEl={anchorSaveMenuEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              disabled={payments.length > 0}
              onClick={() => {
                handleSaveInvoice({
                  type: "draft",
                });
              }}
            >
              <ListItemText
                primary={invoice ? (invoice.type === "draft" ? `Save - ${systemOS === "mac" ? "⌘ S" : "Ctrl S"}` : "Revert To Draft") : `Save As Draft`}
                secondary={payments.length > 0 ? "Can't revert. Payments made." : ""}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "0.8rem",
                  },
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              // disabled={payments.length > 0}
              onClick={async () => {
                if (invoice?.type === "invoice") {
                  const confirmed = await showConfirmDialog(
                    "Revert to Estimate?",
                    "Reverting an Invoice to an Estimate may have some side effects, make sure you want to do so. If you have payments made on the invoice, you may need to refund them. If you are integrated with QuickBooks you will need to delete the invoice from QuickBooks. This can be done in your QuickBooks account, or in the Sync Tools in the Integrations tab."
                  );
                  if (!confirmed) {
                    return;
                  }
                }
                handleSaveInvoice({
                  type: "estimate",
                });
              }}
            >
              <ListItemText
                primary={
                  invoice && invoice.type === "invoice"
                    ? "Revert To Estimate"
                    : invoice?.type === "estimate"
                    ? `Save - ${systemOS === "mac" ? "⌘ S" : "Ctrl S"}`
                    : `Save As Estimate`
                }
                secondary={payments.length > 0 ? "Proceed with caution." : ""}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "0.8rem",
                  },
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleSaveInvoice();
              }}
            >
              <ListItemText
                primary={invoice && invoice.type === "invoice" ? `Save - ${systemOS === "mac" ? "⌘ S" : "Ctrl S"}` : `Save As Invoice`}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "0.8rem",
                  },
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      {/* //MARK: Send Email Menu*/}

      <Popover
        id={sendPopoverId}
        open={sendPopperOpen}
        anchorEl={anchorSendMenuEl}
        onClose={handleCloseSendMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              disabled={invoice?.type === "invoice" || !hasPaymentProcessor}
              onClick={() => {
                handleSendEmail({
                  type: "estimate",
                });
              }}
            >
              <ListItemText
                primary={"Send Estimate Email"}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disabled={!hasPaymentProcessor}
              onClick={() => {
                handleSendEmail({
                  type: "invoice",
                });
              }}
            >
              <ListItemText
                primary={"Send Invoice Email"}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleSendEmail({
                  type: "signatureRequest",
                });
              }}
            >
              <ListItemText
                primary={"Send Signature Request"}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <Divider />

          <ListItem disablePadding>
            <ListItemButton onClick={openEmailTemplateDialog}>
              <ListItemText
                primary={"Send From Template"}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.8rem",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      {/* MARK: DIALOGS */}

      {showAddCustomerModal.value && (
        <AddCustomerModal
          visible={showAddCustomerModal}
          onCancel={() => {
            showAddCustomerModal.onFalse();
          }}
          onComplete={async (id: string) => {
            showAddCustomerModal.onFalse();
            try {
              const added = await DB.collection("orgs").doc(orgData.id).collection("customers").doc(id).get();
              const formattedCustomer = checkCustomer(added) as Customer;
              handleAddCustomer(formattedCustomer);
            } catch (err) {
              console.log(err);
              enqueueSnackbar("Error adding customer", { variant: "error" });
            }
          }}
        />
      )}

      {showEditCustomerModal.value && (
        <EditCustomerModal
          hideDeactivate={true}
          visible={showEditCustomerModal}
          onCancel={() => {
            showEditCustomerModal.onFalse();
          }}
          onComplete={async () => {
            showEditCustomerModal.onFalse();
            try {
              const edited = await DB.collection("orgs").doc(orgData.id).collection("customers").doc(customer?.id).get();
              const formattedCustomer = checkCustomer(edited) as Customer;
              handleAddCustomer(formattedCustomer);

              // Update just the customer property of invoice.
              invoice &&
                (await DB.collection("orgs").doc(orgData.id).collection("invoices").doc(invoice.id).update({
                  customer: formattedCustomer,
                }));
            } catch (err) {
              console.log(err);
              enqueueSnackbar("Error editing customer", { variant: "error" });
            }
          }}
          onContactAdded={null}
          selected={customer}
          showPaymentOptions={false}
        />
      )}

      {invoice && orgData.qboRealmId !== "" && (
        <div>
          <SyncQuickBooksModal
            visible={showSyncModal}
            onCancel={() => setShowSyncModal(false)}
            onComplete={onManualSyncComplete}
            invoice={invoice}
            orgData={orgData}
            handleComplete={handleSaveInvoice}
          />
        </div>
      )}

      {openSendEmailDialog && (
        <Suspense fallback={<CircularProgress />}>
          <SendInvoiceModal
            open={openSendEmailDialog}
            venues={venues}
            invoiceTextData={invoiceTextData}
            i18n={i18n}
            logoData={logoBase64}
            itemImages={itemImages}
            files={files}
            invoice={invoice}
            customer={customer}
            signatureData={signatureData}
            sendType={sendType}
            settingsId={"invoiceEmailSettings"} // TODO
            isNew={false}
            setOpenSendEmailDialog={setOpenSendEmailDialog}
            emailInvoiceSettings={emailInvoiceSettings}
            emailEstimateSettings={emailEstimateSettings}
          />
        </Suspense>
      )}

      {showDeliveryFeeCalculator && (
        <ShippingCalculator
          showDeliveryFeeCalculator={showDeliveryFeeCalculator}
          setShowDeliveryFeeCalculator={setShowDeliveryFeeCalculator}
          orgData={orgData}
          receiveReturnDetails={receiveReturnDetails}
          getShippingFee={getShippingFee}
          setDeliveryFee={setDeliveryFee}
          invoiceChangeResult={invoiceChangeResult}
          responsiveFontSize={responsiveFontSize}
          receiveFeeCalculated={receiveFeeCalculated}
          setReceiveFeeCalculated={setReceiveFeeCalculated}
          returnFeeCalculated={returnFeeCalculated}
          setReturnFeeCalculated={setReturnFeeCalculated}
          loadingReceiveFee={loadingReceiveFee}
          setLoadingReceiveFee={setLoadingReceiveFee}
          loadingReturnFee={loadingReturnFee}
          setLoadingReturnFee={setLoadingReturnFee}
          applyRoundTrip={applyRoundTrip}
          setApplyRoundTrip={setApplyRoundTrip}
        />
      )}

      {categories && pickInventory.value && !fetchingInvoices && availableInventory && (
        <InventoryPickerDialog
          orgData={orgData}
          inventory={inventory}
          itemsToFilter={itemsToFilter}
          availability={availableInventory}
          // peakInventory={peakInventory ?? null}
          selectedItems={selectedItems}
          title="Inventory"
          systemOS={systemOS}
          open={pickInventory.value}
          onClose={() => {
            pickInventory.onFalse();
            setInventoryItemToReplace(null);
            setItemsToFilter([]);
          }}
          selected={(selectedId: string) => false}
          handleAddItem={handleAddItem}
          // handleAddDiscount={handleAddDiscount}
          handleAddFlatFee={handleAddFlatFee}
          // handleAddDamageWaiver={handleAddDamageWaiver}
          // handleAddPercentageFee={handleAddPercentageFee}
          // handleAddSubtotal={handleAddSubtotal}
          handleAddLabelRow={handleAddDescriptionOnly}
          createNewItem={() => {}}
          addAnItem={handleNewItemSelected}
          replaceAnItem={handleReplaceAnItem}
          itemToReplace={inventoryItemToReplace}
          insertAtIndex={indexToInsert}
          onSelect={(item) => {
            // console.log("Selected item", item);
            pickInventory.onFalse();
          }}
          action={null}
          // categories={categories || []}
        />
      )}

      {invoice && (
        <InternalNotesDrawer
          showTags={true}
          visible={chatDrawer.visible}
          handleCloseDrawer={setChatDrawer}
          setSelectedTags={setSelectedTags}
          invoice={invoice}
          tags={tags}
          selectedTags={selectedTags}
        />
      )}

      {showCustomerPicker.value && (
        <CustomerPickerDialog
          title="Customers"
          systemOS={systemOS}
          open={showCustomerPicker.value}
          onClose={() => {
            showCustomerPicker.onFalse();
          }}
          createNewCustomer={handleCreateNewCustomer}
          addCustomer={handleAddCustomer}
          replaceCustomer={handleReplaceCustomer}
          customerToReplace={customer ?? null}
          action={
            <Button onClick={handleCreateNewCustomer} size="small" startIcon={<Iconify icon="mingcute:add-line" />} sx={{ alignSelf: "flex-end" }}>
              New
            </Button>
          }
        />
      )}

      {showMakePickList && invoice && orgData && (
        <MakePickListDialog
          orgData={orgData}
          showMakePickList={showMakePickList}
          setShowMakePickList={setShowMakePickList}
          allInvoices={[invoice]}
          deliveryInvoices={[invoice]}
          pickupInvoices={[invoice]}
          customerPickupInvoices={[invoice]}
          customerDropOffInvoices={[invoice]}
          startDate={invoice.rentalDateStart.toDate()}
          endDate={invoice.rentalDateEnd.toDate()}
          singleInvoice={true}
        />
      )}

      {showSubrentRequest && invoice && orgData && (
        <SubrentRequest
          orgData={orgData}
          showSubrentRequest={showSubrentRequest}
          setShowSubrentRequest={setShowSubrentRequest}
          invoice={invoice}
          vendors={vendors}
          selectedItems={selectedItems}
        />
      )}

      <ConfirmDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        title="Delete Invoice?"
        content="This cannot be undone! Please note, deleting an invoice will remove it from your account completely and it cannot be recovered. It will immediately affect all associated reporting tied to this transaction (ie. QuickReport, Available Inentory, Routes...etc.) All linked payments will remain and it may affect your books. Be sure to compare transactions in QuickBooks to ensure proper account balancing."
        action={
          <Button disabled={payments.length > 0} variant="contained" color="error" onClick={handleDelete}>
            {payments.length > 0 ? "Unable To Delete. Paymentes have been made." : "Delete"}
          </Button>
        }
      />

      <ConfirmationDialog
        open={dialogOpen}
        onClose={(result) => {
          setDialogOpen(false);
          dialogConfig.onClose(result);
        }}
        onConfirm={() => {
          setDialogOpen(false);
          dialogConfig.onClose(true);
        }}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
      <ConfirmDialog
        open={confirmCancel.value}
        onClose={confirmCancel.onFalse}
        title="Cancel Invoice?"
        content="Are you sure you want to cancel this order?"
        action={
          <Button variant="contained" color="error" onClick={handleCancel}>
            Yes
          </Button>
        }
        cancelButtonText="No"
      />

      {invoice && users && showHistoryDialog && (
        <Suspense fallback={<div>Loading...</div>}>
          <HistoryDrawer
            showHistoryDialog={showHistoryDialog}
            currenInvoice={invoice}
            users={users}
            handleReplaceInvoice={(i) => {
              window.location.reload();
            }}
          />
        </Suspense>
      )}

      {invoice && users && openTemplateDialog && (
        <Dialog onClose={() => setOpenTemplateDialog(false)} open={openTemplateDialog}>
          <SendEmailTemplateView
            orgData={orgData}
            title={"Send Email From Template"}
            initialEmail={invoice.customer?.email}
            specifyEmailSentTo={true}
            userData={userData}
            invoiceIds={[invoice.id]}
            close={() => setOpenTemplateDialog(false)}
            complete={() => {
              enqueueSnackbar("Sending Emails. Results will be sent to Dashboard notifications", {
                variant: "success",
              });
              setOpenTemplateDialog(false);
            }}
          />
        </Dialog>
      )}
    </Box>
  );
}

export default MuiInvoice;



export const handleOpenInGoogleMaps = (address: string) => {
  const formattedAddress = encodeURIComponent(address);
  const url = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
  window.open(url, "_blank");
};

type Props = {
  orgData: OrganizationData;
  existingInvoice?: Invoice;
  cartItems?: any;
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

interface ConfirmationDialogProps {
  open: boolean;
  onClose: (result: boolean) => void;
  onConfirm: () => void;
  title: string;
  content: string;
}

interface DialogConfig {
  title: string;
  content: string;
  onClose: (result: boolean) => void;
}

interface SaveInvoiceOptions {
  showToast?: boolean;
  type?: string;
}

const DraggableItem = React.memo(({ item, index, children }:
  { item: any, index: number, children: React.ReactNode }
) => {
  const itemId = item.uuid || item.key || `item-${index}`;
  const draggableId = `${itemId}-${index}`;
  
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.5 : 1,
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
});

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, onConfirm, title, content }) => (
  <Dialog open={open} onClose={() => onClose(false)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={() => onClose(false)}>Cancel</Button>
      <Button variant="contained" onClick={() => onConfirm()} autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);


// function BreakpointIndicator() {
//   const theme = useTheme();
//   const matchesXS = useMediaQuery(theme.breakpoints.only("xs"));
//   const matchesSM = useMediaQuery(theme.breakpoints.only("sm"));
//   const matchesMD = useMediaQuery(theme.breakpoints.only("md"));
//   const matchesLG = useMediaQuery(theme.breakpoints.only("lg"));
//   const matchesXL = useMediaQuery(theme.breakpoints.only("xl"));

//   let currentBreakpoint = "Unknown";
//   if (matchesXL) currentBreakpoint = "XL";
//   else if (matchesLG) currentBreakpoint = "LG";
//   else if (matchesMD) currentBreakpoint = "MD";
//   else if (matchesSM) currentBreakpoint = "SM";
//   else if (matchesXS) currentBreakpoint = "XS";

//   return (
//     <TextField
//       label="Current Breakpoint"
//       value={currentBreakpoint}
//       InputProps={{
//         readOnly: true,
//       }}
//       variant="outlined"
//     />
//   );
// }

// const configureAmtOff = () => {
//   if (invoiceTotalData.totalDiscountType === "percent") {
//     return (invoiceTotalData.subtotal / (1 - invoiceTotalData.totalDiscountPercent) - invoiceTotalData.subtotal).toFixed(2);
//   } else if (invoiceTotalData.totalDiscountType === "number") {
//     return invoiceTotalData.totalDiscountNumber.toFixed(2);
//   }
// };

// const configureProRate = (taxableAmt: number, totalAmt: number) => {
//   if (taxableAmt === 0 || totalAmt === 0) return 0;
//   return taxableAmt / totalAmt;
// };

// const configureAdjustedTaxAmt = (taxableAmt: number, discount: number, prorate: number, adjSubtotal: number | undefined) => {
//   if (taxableAmt === 0 || adjSubtotal === 0) return 0;

//   const amountOff = (adjSubtotal ?? 0) * discount;
//   const proRatedAmtOff = amountOff * prorate;

//   return taxableAmt - proRatedAmtOff;
// };



















  // New method for calculating the monetary components
  // MARK: Calc Components
  // const calculateMonetaryComponents = () => {
  //   const delivery = deliveryFee;

  //   let taxableSubtotal = 0;
  //   let nonTaxableSubtotal = 0;
  //   let discountedSubtotal = 0;
  //   let discountedTaxableSubtotal = 0;

  //   let localDiscountLineItemsAmount = 0;

  //   // add delivery fee
  //   // subtotal += delivery;

  //   // Sum row totals
  //   // Row types addition: damageWaiver | percentFee | flatFee | singleItem-rental | singleItem-bundle
  //   // Row types subtraction: discount
  //   // Row types ignore: subtotal | descriptionOnly

  //   selectedItems.forEach((i) => {
  //     if (["damageWaiver", "percentFee", "flatFee", "singleItem"].includes(i.rowType)) {
  //       if (i.selectedTaxable) {
  //         taxableSubtotal += i.rowTotal ?? 0;
  //       } else {
  //         nonTaxableSubtotal += i.rowTotal ?? 0;
  //       }
  //     }

  //     if (i.rowType === "discount") {
  //       localDiscountLineItemsAmount += i.rowTotal ?? 0;
  //     }
  //   });

  //     // 3. Add delivery fee to appropriate subtotal
  //     if (deliveryFeeTaxable) {
  //       taxableSubtotal += deliveryFee;
  //     } else {
  //       nonTaxableSubtotal += deliveryFee;
  //     }
  
  //   // 4. Calculate total subtotal
  //   let subtotal = taxableSubtotal + nonTaxableSubtotal;

  //   if (invoiceTotalData.hasTotalDiscount) {
  //     if (invoiceTotalData.totalDiscountType === "percent") {
  //       const calcTaxableSub = taxableSubtotal - invoiceTotalData.totalDiscountPercent * taxableSubtotal;
  //       const calcNonTaxableSub = nonTaxableSubtotal - invoiceTotalData.totalDiscountPercent * nonTaxableSubtotal;

  //       nonTaxableSubtotal = calcNonTaxableSub > 0 ? calcNonTaxableSub : 0;
  //       taxableSubtotal = calcTaxableSub > 0 ? calcTaxableSub : 0;

  //     } else if (invoiceTotalData.totalDiscountType === "number") {
  //       const taxRatio = taxableSubtotal / subtotal;
  //       // Calculate the discount amount based on ratio for nonTaxableSubtotal and taxableSubtotal
  //       const nonTaxableDiscount = invoiceTotalData.totalDiscountNumber * (1 - taxRatio);
  //       const taxableDiscount = invoiceTotalData.totalDiscountNumber * taxRatio;

  //       nonTaxableSubtotal =  (nonTaxableSubtotal - nonTaxableDiscount) > 0 ? (nonTaxableSubtotal - nonTaxableDiscount) : 0;
  //       taxableSubtotal = (taxableSubtotal - taxableDiscount) > 0 ? (taxableSubtotal - taxableDiscount) : 0;
  //     }
  //   }

  //   // setDiscountLineItemsAmount(localDiscountLineItemsAmount);

  //   const componentData = {
  //     subtotal,
  //     taxableSubtotal,
  //     nonTaxableSubtotal,
  //     discountedSubtotal,
  //     discountedTaxableSubtotal,
  //     localDiscountLineItemsAmount,
  //   };

  //   return componentData;
  // };

  // // MARK: Finalize with Taxes
  // const finalizeWithTaxes = (customer: Customer | null | undefined) => {
  //   if (!orgData) return;

  //   // CHECK IF LAST UPDATED IS BEFORE CUTOFF DATE
  //   const isBeforeCutoffDate = invoice?.updatedOn ? invoice.updatedOn.toDate() < cutoffDate : false;
  //   if (isBeforeCutoffDate) {
  //     console.log("isBeforeCutoffDate: ", isBeforeCutoffDate);
  //     return configureTax(customer);
  //   }

  //   // Calculate monetary components - subtotal, taxableSubtotal, nonTaxableSubtotal, discountedSubtotal, discountedTaxableSubtotal
    
  //   const configureSubtotalResult = calculateMonetaryComponents();
  //   let newSubtotal               = configureSubtotalResult.subtotal;                   
  //   let taxableSubtotal           = configureSubtotalResult.taxableSubtotal;
  //   let nonTaxableSubtotal        = configureSubtotalResult.nonTaxableSubtotal;

  //   let discountedSubtotal        = configureSubtotalResult.discountedSubtotal;         

  //   const customerIsTaxExempt = customer?.isTaxExempt ?? false;

  //   let totalTaxableAmt = customerIsTaxExempt ? 0 : taxableSubtotal;

  //   const { total, hasTotalDiscount, totalDiscountType, totalDiscountPercent, totalDiscountNumber } = invoiceTotalData;

  //   let finalTaxableAmt = taxableSubtotal;

  //   // Convert to pennies and calculate
  //   const taxableAmtInPennies = Math.round(finalTaxableAmt * 100);
  //   const taxRateInHundredths = Math.round(selectedTaxRate.rate * 10000);
  //   const taxInPennies = Math.round((taxableAmtInPennies * taxRateInHundredths) / 10000);
  //   // Convert back to dollars
  //   const finalTaxRounded = taxInPennies / 100;

  //   const roundedNewSubtotal = Nifty.RoundToTwoDecimals(newSubtotal);
  //   const calculatedTotal = finalTaxRounded + taxableSubtotal + nonTaxableSubtotal;

  //   if (total === calculatedTotal) {
  //     return finalTaxRounded;
  //   } else {
  //     setInvoiceTotalData({
  //       taxAmt: finalTaxRounded,
  //       taxRate: selectedTaxRate?.rate ?? 0,
  //       total: calculatedTotal,
  //       subtotal: roundedNewSubtotal,
  //       discountedSubtotal: discountedSubtotal,
  //       hasTotalDiscount: hasTotalDiscount,
  //       totalDiscountType: totalDiscountType,
  //       totalDiscountPercent: totalDiscountPercent,
  //       totalDiscountNumber: totalDiscountNumber,
  //       totalTaxableAmt: totalTaxableAmt,
  //       totalNonTaxableAmt: nonTaxableSubtotal,
  //     });

  //     return finalTaxRounded;
  //   }
  // };

  // // **************************************************
  // // Old method for calculating the monetary components - REMOVE ONE YEAR FROM FEB 12, 2025
  // // **************************************************

  // // MARK: Configure Subtotal
  // const configureInvoiceSubtotal = () => {

  //   const delivery = deliveryFee;
  //   let subtotal = 0;
  //   let taxableSubtotal = 0;
  //   let localDiscountLineItemsAmount = 0;

  //   // delivery fee
  //   subtotal = subtotal + delivery;

  //   // Sum row totals
  //   // Row types addition: damageWaiver | percentFee | flatFee | singleItem-rental | singleItem-bundle
  //   // Row types subtraction: discount
  //   // Row types ignore: subtotal | descriptionOnly

  //   selectedItems.forEach((i) => {
  //     if (["damageWaiver", "percentFee", "flatFee", "singleItem"].includes(i.rowType)) {
  //       subtotal += i.rowTotal ?? 0;

  //       if (i.selectedTaxable) {
  //         taxableSubtotal += i.rowTotal ?? 0;
  //       }
  //     }

  //     if (i.rowType === "discount") {
  //       localDiscountLineItemsAmount += i.rowTotal ?? 0;
  //     }
  //   });

  //   if (invoiceTotalData.hasTotalDiscount) {
  //     if (invoiceTotalData.totalDiscountType === "percent") {
  //       const calcSub = subtotal - invoiceTotalData.totalDiscountPercent * subtotal;
  //       subtotal = calcSub > 0 ? calcSub : 0;
  //     } else if (invoiceTotalData.totalDiscountType === "number") {
  //       const discount = subtotal - invoiceTotalData.totalDiscountNumber;
  //       subtotal = discount >= 0 ? discount : 0;
  //     }
  //   }

  //   setDiscountLineItemsAmount(localDiscountLineItemsAmount);
  //   return { subtotal, taxableSubtotal, localDiscountLineItemsAmount };
  // };

  // // MARK: Configure Tax
  // const configureTax = (customer: Customer | null | undefined) => {
  //   if (!orgData) return;
  
  //   const configureSubtotalResult = configureInvoiceSubtotal();
  //   const newSubtotal = configureSubtotalResult.subtotal;

  //   const invItems = [...selectedItems];
  //   const customerIsTaxExempt = customer?.isTaxExempt ?? false;
  //   const deliveryFeeTaxableAmt = deliveryFeeTaxable ? deliveryFee : 0;
  
  //   let totalTaxableAmt = customerIsTaxExempt ? 0 : deliveryFeeTaxableAmt;
  //   let totalItemAmt = deliveryFee;
  
  //   invItems.forEach((i) => {
  //     if (i.rowType !== ROW_TYPE_SUBTOTAL) {
  //       totalItemAmt += i?.rowTotal ?? 0;
  //       if (i.selectedTaxable) {
  //         totalTaxableAmt += i?.rowTotal ?? 0;
  //       }
  //     }
  //   });
  
  //   const configureProRate = (taxableAmt: number, totalAmt: number) => {
  //     if (taxableAmt === 0 || totalAmt === 0) return 0;
  //     return taxableAmt / totalAmt;
  //   };
  
  //   const configureAdjustedTaxAmt = (
  //     taxableAmt: number,
  //     discount: number,
  //     prorate: number,
  //     adjSubtotal: number | undefined
  //   ) => {
  //     if (taxableAmt === 0 || adjSubtotal === 0) return 0;
      
  //     const amountOff = (adjSubtotal ?? 0) * discount;
  //     const proRatedAmtOff = amountOff * prorate;
  
  //     return taxableAmt - proRatedAmtOff;
  //   };
  
  //   const {
  //     total,
  //     taxRate,
  //     subtotal,
  //     hasTotalDiscount,
  //     totalDiscountType,
  //     totalDiscountPercent,
  //     totalDiscountNumber,
  //   } = invoiceTotalData;
  
  //   let finalTaxableAmt = 0;
  //   if (hasTotalDiscount) {
  //     if (totalDiscountType === "percent") {
  //       finalTaxableAmt = configureAdjustedTaxAmt(
  //         totalTaxableAmt,
  //         totalDiscountPercent / 100,
  //         configureProRate(totalTaxableAmt, totalItemAmt),
  //         newSubtotal
  //       );
  //     } else {
  //       const convertedPercent = totalDiscountNumber / (newSubtotal ?? 0);
  //       finalTaxableAmt = configureAdjustedTaxAmt(
  //         totalTaxableAmt,
  //         convertedPercent,
  //         configureProRate(totalTaxableAmt, totalItemAmt),
  //         newSubtotal
  //       );
  //     }
  //   } else {
  //     finalTaxableAmt = totalTaxableAmt;
  //   }
  
  //   const finalTaxRounded = Nifty.RoundToTwoDecimals(
  //     finalTaxableAmt !== 0 ? finalTaxableAmt * selectedTaxRate.rate : 0
  //   );
  
  //   const roundedNewSubtotal = Nifty.RoundToTwoDecimals(newSubtotal);
  //   const calculatedTotal = finalTaxRounded + roundedNewSubtotal;
  
  //   if (total === calculatedTotal) {
  //     return finalTaxRounded;
  //   } else {
  //     setInvoiceTotalData({
  //       taxAmt: finalTaxRounded,
  //       taxRate: selectedTaxRate?.rate ?? 0,
  //       total: calculatedTotal,
  //       subtotal: roundedNewSubtotal,
  //       hasTotalDiscount: hasTotalDiscount,
  //       totalDiscountType: totalDiscountType,
  //       totalDiscountPercent: totalDiscountPercent,
  //       totalDiscountNumber: totalDiscountNumber,
  //       totalTaxableAmt: totalTaxableAmt,
  //       totalNonTaxableAmt: 0,
  //       discountedSubtotal: 0,
  //     });
  //     return finalTaxRounded;
  //   }
  // };

  // END: old method for calculating the monetary components
  // *******************************************************



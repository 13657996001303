import { ReactNode } from 'react'
import { Box, Card, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'

interface StatsCardProps {
  title: string
  total: string | number
  icon: ReactNode
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
}

export function StatsCard({ title, total, icon, color = 'primary' }: StatsCardProps) {
  const theme = useTheme()

  return (
    <Card sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>

          <Typography variant="h4">{total}</Typography>
        </Box>

        <Box
          sx={{
            width: 48,
            height: 48,
            display: 'flex',
            borderRadius: 1.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette[color].main, 0.12),
            '& svg': {
              width: 24,
              height: 24,
              color: theme.palette[color].main,
            },
          }}
        >
          {icon}
        </Box>
      </Box>
    </Card>
  )
} 
// FirestoreContext.tsx
import React, { createContext, useContext } from "react";
import { Invoice } from "../interfaces/invoice";
import { InventoryItem } from "../interfaces/inventoryItem";
import { Venue } from "../interfaces/venue";
import { TaxRate } from "../interfaces/taxRate";

// Define the FirestoreContext
export const FirestoreInvoicesContext = createContext<{
  invoicesData: {
    todaysInvoices: Invoice[];
    yesterdayInvoices: Invoice[];
    tomorrowInvoices: Invoice[];
    lastWeekInvoices: Invoice[];
    nextWeekInvoices: Invoice[];
  };
  inventory: InventoryItem[];
  loadingInventory: boolean;
  users: any[];
  tags: any[];
  categories: any[];
  venues: Venue[];
  taxes: TaxRate[];
  files: any[];
  invoiceTextData: any;
  emailInvoiceSettings: any;
  emailEstimateSettings: any;
}>({
  invoicesData: {
    todaysInvoices: [],
    yesterdayInvoices: [],
    tomorrowInvoices: [],
    lastWeekInvoices: [],
    nextWeekInvoices: [],
  },
  inventory: [],
  loadingInventory: true,
  users: [],
  tags: [],
  categories: [],
  venues: [],
  taxes: [],
  files: [],
  invoiceTextData: null,
  emailInvoiceSettings: null,
  emailEstimateSettings: null,
});
// Export the FirestoreContext
export const useFirestoreContext = () => {
  const context = useContext(FirestoreInvoicesContext);

  if (!context)
    throw new Error("useFirestoreContext must be use inside FirestoreProvider");

  return context;
};

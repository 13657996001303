import { useMemo, useState, useEffect } from 'react'
import {
  Box,
  Card,
  Stack,
  Container,
  Typography,
  MenuItem,
  Link,
  Button,
  Dialog,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro'
import { formatDistanceToNow } from 'date-fns'
import { LoadingButton } from '@mui/lab'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { authSelector } from '../auth/authSlice'
import { checkInvoices } from '../../app/utils/models/checkers/checkInvoices'
import { INVOICES_COLLECTION } from '../../app/utils/models/collections/collectionConstants'
import { ROUTE_EDIT_INVOICE } from '../../app/routes'
import { enqueueSnackbar } from 'notistack'
import SendEmailTemplateView from '../invoices/send-email-template-view'

// Components
import { StatsCard } from './components/stats-card'
import { FormatToLocalCurrency } from '../../app/utils/currency/formatToLocalCurrency'

// Icons
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

type OverdueStatus = 'critical' | 'moderate' | 'recent'

interface OverdueInvoice {
  id: string
  invoiceNumber: string
  customerName: string
  dueDate: Date
  daysOverdue: number
  balanceRemaining: number
  status: OverdueStatus
}

interface OverduePaymentsDashboardProps {
  orgData: any
}

export function OverduePaymentsDashboard({ orgData }: OverduePaymentsDashboardProps) {
  const theme = useTheme()
  const history = useHistory()
  const firestore = useFirestore()
  const { fsOrgPrefix, userData } = useSelector(authSelector)
  const [filterStatus, setFilterStatus] = useState<'all' | OverdueStatus>('all')
  const [loading, setLoading] = useState(true)
  const [overdueInvoices, setOverdueInvoices] = useState<OverdueInvoice[]>([])
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false)
  const [reminderInvoiceId, setReminderInvoiceId] = useState<string | null>(null)

  useEffect(() => {
    const fetchOverdueInvoices = async () => {
      setLoading(true)
      try {
        const today = new Date()
        const ref = firestore
          .collection(`${fsOrgPrefix}${INVOICES_COLLECTION}`)
          .where('invoicePaymentDueDate', '<', today)
          .where('invoicePaymentDueDate', '>', new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()))
          .where('type', '==', 'invoice')
          .orderBy('invoicePaymentDueDate', 'desc')

        const snap = await ref.get()
        const invoices = snap.docs
          .map(doc => {
            const invoice = checkInvoices(doc, orgData.orgTimezone)
            // Only include invoices with remaining balance
            if (!invoice.balanceRemaining || invoice.balanceRemaining <= 0) return null

            const dueDate = invoice.invoicePaymentDueDate.toDate()
            const daysOverdue = Math.floor((today.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24))
            
            let status: OverdueStatus = 'recent'
            if (daysOverdue >= 30) status = 'critical'
            else if (daysOverdue >= 15) status = 'moderate'

            return {
              id: invoice.id,
              invoiceNumber: invoice.invoiceNumber,
              customerName: invoice.customer?.customerDisplayName,
              dueDate,
              daysOverdue,
              balanceRemaining: invoice.balanceRemaining,
              status
            }
          })
          .filter((invoice): invoice is OverdueInvoice => invoice !== null)

        setOverdueInvoices(invoices)
      } catch (error) {
        console.error('Error fetching overdue invoices:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchOverdueInvoices()
  }, [firestore, fsOrgPrefix, orgData.orgTimezone])

  const filteredInvoices = useMemo(() => {
    if (filterStatus === 'all') return overdueInvoices
    return overdueInvoices.filter(invoice => invoice.status === filterStatus)
  }, [overdueInvoices, filterStatus])

  const totalOverdue = useMemo(() => 
    overdueInvoices.reduce((sum, invoice) => sum + invoice.balanceRemaining, 0),
    [overdueInvoices]
  )

  const avgDaysOverdue = useMemo(() => {
    if (overdueInvoices.length === 0) return 0
    const total = overdueInvoices.reduce((sum, invoice) => sum + invoice.daysOverdue, 0)
    return Math.round(total / overdueInvoices.length)
  }, [overdueInvoices])

  const columns: GridColDef[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice #",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <Link
          href={`${ROUTE_EDIT_INVOICE}${params.row.id}`}
          target="_blank"
          rel="noopener"
          sx={{
            color: "primary.main",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {params.row.invoiceNumber}
        </Link>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer",
      flex: 1.5,
      minWidth: 200,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <Typography variant="body2">{params.row.dueDate.toLocaleDateString()}</Typography>,
    },
    {
      field: "daysOverdue",
      headerName: "Days Overdue",
      flex: 1,
      minWidth: 130,
      type: "number", // Treat the data as a number for filtering and sorting
      valueGetter: (params) => Number(params.row.daysOverdue),
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: params.row.daysOverdue >= 30 ? "error.main" : params.row.daysOverdue >= 15 ? "warning.main" : "text.secondary",
          }}
        >
          {params.row.daysOverdue} days
        </Typography>
      ),
    },
    {
      field: "balanceRemaining",
      headerName: "Amount Due",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => FormatToLocalCurrency(params.row.balanceRemaining, orgData),
      renderCell: (params) => <Typography variant="body2">{FormatToLocalCurrency(params.row.balanceRemaining, orgData)}</Typography>,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <LoadingButton variant="text" color="primary" onClick={() => handleSendReminder(params.row.id)}>
          Send Reminder
        </LoadingButton>
      ),
    },
  ];

  const handleSendReminder = async (invoiceId: string) => {
   
    setReminderInvoiceId(invoiceId)
    setSelectedRows([invoiceId])
    setOpenTemplateDialog(true)
  }

  const handleCloseTemplateDialog = () => {
    setOpenTemplateDialog(false)
    if (reminderInvoiceId) {
      setReminderInvoiceId(null)
      setSelectedRows([]) // Clear selection if it was from a reminder button
    }
  }

  const handleTemplateComplete = () => {
    enqueueSnackbar("Sending Emails. Results will be sent to Dashboard notifications", {
      variant: "success",
    })
    handleCloseTemplateDialog()
  }

  return (
    <Container maxWidth={false}>
      {loading ? (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="60vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={3}>
          {/* Summary Cards */}
          <Box
            display="grid"
            gap={2}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            }}
          >
            <StatsCard
              title="Total Overdue"
              total={FormatToLocalCurrency(totalOverdue, orgData)}
              icon={<AttachMoneyIcon />}
              color="error"
            />

            <StatsCard
              title="Average Days Overdue"
              total={`${avgDaysOverdue} days`}
              icon={<AccessTimeIcon />}
              color="warning"
            />

            <StatsCard
              title="Requiring Action"
              total={`${overdueInvoices.length} invoices`}
              icon={<NotificationsActiveIcon />}
              color="info"
            />
          </Box>

          {/* Main Content */}
          <Card>
            <Box sx={{ p: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="h6">Overdue Invoices</Typography>
                  {selectedRows.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenTemplateDialog(true)}
                    >
                      Send Emails
                    </Button>
                  )}
                </Stack>
                
                <Box sx={{ minWidth: 220 }}>
                  <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value as any)}
                    style={{
                      padding: '8px 12px',
                      borderRadius: '8px',
                      border: `1px solid ${theme.palette.divider}`,
                      backgroundColor: theme.palette.background.paper,
                      width: '100%'
                    }}
                  >
                    <option value="all">All Overdue</option>
                    <option value="critical">Critical (30+ days)</option>
                    <option value="moderate">Moderate (15-30 days)</option>
                    <option value="recent">Recent (1-14 days)</option>
                  </select>
                </Box>
              </Stack>

              <Box sx={{ height: '100%' }}>
                <DataGridPro
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  rows={filteredInvoices}
                  columns={columns}
                  checkboxSelection
                  disableRowSelectionOnClick
                  pageSizeOptions={[10, 25, 50]}
                  loading={loading}
                  onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection as string[])
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sorting: {
                      sortModel: [{ field: 'daysOverdue', sort: 'desc' }],
                    },
                  }}
                />
              </Box>
            </Box>
          </Card>
        </Stack>
      )}

      <Dialog 
        onClose={handleCloseTemplateDialog} 
        open={openTemplateDialog}
      >
        <SendEmailTemplateView 
          orgData={orgData} 
          userData={userData}
          invoiceIds={selectedRows} 
          close={handleCloseTemplateDialog} 
          complete={handleTemplateComplete}
        />
      </Dialog>
    </Container>
  )
}

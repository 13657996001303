import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// @mui
import { functions } from "../../../app/config/firebase";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { DB } from "../../../app/config/firebase";
import firebase from "firebase/app";
import "firebase/firestore";

import { AppBar, Box, Dialog, IconButton, Stack, ToggleButton, ToggleButtonGroup, Toolbar, useTheme, Card, CardContent, FormControl, InputLabel, Select, MenuItem, Button, Divider, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState, useEffect, useMemo } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { Invoice } from "../../../app/interfaces/invoice";
import { Vendor } from "../../../app/interfaces/vendor";
import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// make type
type SubrentRequestProps = {
  orgData: OrganizationData;  
  showSubrentRequest: boolean;
  setShowSubrentRequest: any;
  invoice: Invoice;
  vendors: Vendor[];
  selectedItems: SelectedItem[];
};

type VendorItemMatch = {
  itemId: string;
  vendorId: string;
  isIncluded: boolean;
};

type SubrentEmail = {
  vendorId: string;
  vendor: Vendor;
  items: {
    itemId: string;
    name: string;
    quantity: number;
  }[];
};

interface SubrentalRequest {
  id: string;
  vendorId: string;
  vendorEmail: string;
  vendorName: string;
  companyName: string;
  items: {
    itemId: string;
    name: string;
    quantity: number;
  }[];
  dates: {
    start: firebase.firestore.Timestamp;
    end: firebase.firestore.Timestamp;
  };
  invoiceId: string;
  createdAt?: firebase.firestore.Timestamp;
}

// make function export
const SubrentRequest = ({
  orgData,
  showSubrentRequest,
  setShowSubrentRequest,
  invoice,
  vendors,
  selectedItems,
}: SubrentRequestProps) => {
  const theme = useTheme();
  const [vendorMatches, setVendorMatches] = useState<VendorItemMatch[]>([]);
  const [currentVendorIndex, setCurrentVendorIndex] = useState(0);
  const { userData } = useSelector(authSelector);
  const [subrentalHistory, setSubrentalHistory] = useState<SubrentalRequest[]>([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  
  // Initialize vendor matches based on items' vendorQueryHooks
  useEffect(() => {
    const initialMatches = selectedItems
      .filter(item => item.vendorsQueryHook?.length > 0)
      .map(item => ({
        itemId: item.id,
        vendorId: item.vendorsQueryHook[0], // Default to first vendor if multiple exist
        isIncluded: true // Default to included
      }));
    setVendorMatches(initialMatches);
  }, [selectedItems]);

  // Fetch subrental request history
  useEffect(() => {
    if (!invoice?.id || !orgData?.id) return;

    const unsubscribe = DB
      .collection("orgs")
      .doc(orgData.id)
      .collection("invoices")
      .doc(invoice.id)
      .collection("subrentalRequests")
      .onSnapshot((snapshot) => {
        const requests = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as SubrentalRequest[];
        setSubrentalHistory(requests);
      }, (error) => {
        console.error("Error fetching subrental history:", error);
        enqueueSnackbar("Error loading subrental request history", { variant: "error" });
      });

    return () => unsubscribe();
  }, [invoice?.id, orgData?.id]);

  const handleVendorChange = (itemId: string, vendorId: string) => {
    setVendorMatches(prev => {
      const existing = prev.find(match => match.itemId === itemId);
      if (existing) {
        return prev.map(match => 
          match.itemId === itemId ? { ...match, vendorId } : match
        );
      }
      return [...prev, { itemId, vendorId, isIncluded: true }];
    });
  };

  const handleIncludeToggle = (itemId: string) => {
    setVendorMatches(prev => 
      prev.map(match => 
        match.itemId === itemId 
          ? { ...match, isIncluded: !match.isIncluded } 
          : match
      )
    );
  };

  // Group items by vendor and create email data - only include checked items
  const emailsByVendor = useMemo(() => {
    const emailMap = new Map<string, SubrentEmail>();
    
    vendorMatches
      .filter(match => match.isIncluded) // Only include checked items
      .forEach(match => {
        const item = selectedItems.find(item => item.id === match.itemId);
        if (!item) return;
        
        if (!emailMap.has(match.vendorId)) {
          emailMap.set(match.vendorId, {
            vendorId: match.vendorId,
            vendor: vendors.find(v => v.id === match.vendorId)!,
            items: []
          });
        }
        
        const email = emailMap.get(match.vendorId)!;
        email.items.push({
          itemId: item.id,
          name: item.name,
          quantity: item.selectedQty
        });
      });
    
    return Array.from(emailMap.values());
  }, [vendorMatches, selectedItems]);

  const currentEmail = emailsByVendor[currentVendorIndex];
  const currentVendor = currentEmail ? vendors.find(v => v.id === currentEmail.vendorId) : null;

  const handleNextVendor = () => {
    setCurrentVendorIndex(prev => 
      prev < emailsByVendor.length - 1 ? prev + 1 : prev
    );
  };

  const handlePrevVendor = () => {
    setCurrentVendorIndex(prev => prev > 0 ? prev - 1 : prev);
  };

  const handlePrepareEmail = async () => {
    // Show confirmation dialog first
    setShowConfirmDialog(true);
  };

  const handleConfirmAndSend = async () => {
    setShowConfirmDialog(false);
    
    try {
      const emailRequests = emailsByVendor.map(email => {
        const vendor = vendors.find(v => v.id === email.vendorId);
        if (!vendor) return null;

        return {
          vendorId: email.vendorId,
          vendorEmail: vendor.email,
          vendorName: vendor.firstName || vendor.companyName,
          companyName: orgData.companyName,
          items: email.items,
          dates: {
            start: invoice.rentalDateStart,
            end: invoice.rentalDateEnd
          },
          invoiceId: invoice.id
        };
      }).filter(Boolean);

      if (emailRequests.length === 0) {
        enqueueSnackbar("No valid vendors found to email", { variant: "error" });
        return;
      }

      const call = functions.httpsCallable("emailSubrentRequest");
      await call({
        orgId: orgData.id,
        userId: userData.id,
        emailRequests: emailRequests
      });

      enqueueSnackbar("Subrent requests sent successfully", { variant: "success" });
      setShowSubrentRequest(false);
    } catch (error) {
      console.error("Error sending subrent requests:", error);
      enqueueSnackbar("Error sending subrent requests", { variant: "error" });
    }
  };

  return (
    <>
      <Dialog open={showSubrentRequest} onClose={() => setShowSubrentRequest()} fullScreen maxWidth="md" TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setShowSubrentRequest(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Make Subrent Request
            </Typography>
           
            <Button 
              color="inherit" 
              onClick={handlePrepareEmail}
              disabled={vendorMatches.length === 0}
            >
              Prepare Email
            </Button>
          </Toolbar>
        </AppBar>

        {showHistory ? (
          <Box sx={{ p: 3, height: 'calc(100vh - 64px)', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Subrental Request History
            </Typography>
            {subrentalHistory.length === 0 ? (
              <Typography color="text.secondary">
                No subrental requests found for this invoice
              </Typography>
            ) : (
              <Stack spacing={2}>
                {subrentalHistory.map((request) => (
                  <Paper key={request.id} variant="outlined" sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Typography variant="subtitle1">
                        Sent to: {request.vendorName} ({request.vendorEmail})
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {request.dates?.start?.seconds ? 
                          new Date(request.dates.start.seconds * 1000).toLocaleDateString() 
                          : 'Date not available'} 
                        {' - '}
                        {request.dates?.end?.seconds ? 
                          new Date(request.dates.end.seconds * 1000).toLocaleDateString()
                          : 'Date not available'}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom>
                        Requested Items:
                      </Typography>
                      {request.items.map((item, index) => (
                        <Typography key={index} variant="body2">
                          • {item.name} (Qty: {item.quantity})
                        </Typography>
                      ))}
                      {request.createdAt?.seconds && (
                        <Typography variant="caption" color="text.secondary">
                          Sent on: {new Date(request.createdAt.seconds * 1000).toLocaleString()}
                        </Typography>
                      )}
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            )}
          </Box>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' },
            height: 'calc(100vh - 64px)',
          }}>
            {/* Left Panel - Vendor Selection */}
            <Box sx={{ 
              width: { xs: '100%', md: '30%' },
              height: { xs: '50%', md: 'auto' },
              borderRight: { xs: 0, md: 1 },
              borderBottom: { xs: 1, md: 0 },
              borderColor: 'divider',
              overflowY: 'auto',
              p: 3 
            }}>
              <Typography variant="h6" gutterBottom>
                Select Vendors for Items
              </Typography>
              <Stack spacing={0} divider={<Divider />}>
                {selectedItems
                  .filter(item => item.vendorsQueryHook?.length > 0)
                  .map(item => {
                    const currentMatch = vendorMatches.find(match => match.itemId === item.id);
                    return (
                      <Box key={item.id} sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Checkbox
                            checked={currentMatch?.isIncluded ?? true}
                            onChange={() => handleIncludeToggle(item.id)}
                            size="small"
                          />
                          <Typography variant="subtitle1">
                            {item.name} ({item.selectedQty})
                          </Typography>
                        </Box>
                        <FormControl 
                          fullWidth
                          size="small"
                          sx={{ mr: 4 }} // Indent to align with text
                        >
                          <InputLabel>Select Vendor</InputLabel>
                          <Select
                            value={currentMatch?.vendorId || ''}
                            onChange={(e) => handleVendorChange(item.id, e.target.value as string)}
                            label="Select Vendor"
                            disabled={!currentMatch?.isIncluded}
                          >
                            {vendors
                              .filter(vendor => item.vendorsQueryHook?.includes(vendor.id))
                              .map(vendor => (
                                <MenuItem key={vendor.id} value={vendor.id}>
                                  {vendor.companyName || vendor.id}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    );
                  })}
              </Stack>
              {selectedItems.filter(item => item.vendorsQueryHook?.length > 0).length === 0 && (
                <Typography color="text.secondary" sx={{ mt: 2 }}>
                  No items with configured vendors found
                </Typography>
              )}
            </Box>

            {/* Middle Panel - Email Prep */}
            <Box sx={{ 
              width: { xs: '100%', md: subrentalHistory.length > 0 ? '40%' : '70%' },
              height: { xs: '50%', md: 'auto' },
              borderRight: { xs: 0, md: subrentalHistory.length > 0 ? 1 : 0 },
              borderBottom: { xs: 1, md: 0 },
              borderColor: 'divider',
              p: 3,
              bgcolor: 'background.paper',
              overflowY: 'auto'
            }}>
              {emailsByVendor.length > 0 ? (
                <>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                    mb: 3
                  }}>
                    <Typography variant="h6">
                      Email Preview for {currentVendor?.companyName}
                    </Typography>
                    {emailsByVendor.length > 1 && (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton 
                          onClick={handlePrevVendor}
                          disabled={currentVendorIndex === 0}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ alignSelf: 'center' }}>
                          {currentVendorIndex + 1} of {emailsByVendor.length}
                        </Typography>
                        <IconButton 
                          onClick={handleNextVendor}
                          disabled={currentVendorIndex === emailsByVendor.length - 1}
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>

                  <Paper sx={{ p: 3, mb: 3 }} variant="outlined">
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      To: {currentVendor?.email}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Subject: {orgData.companyName} inquires for these subrental items
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1" paragraph>
                      Hello {currentVendor?.firstName || currentVendor?.companyName}, can you please reply with the availability of these items that {orgData.companyName} has requested?
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Requested Items:
                    </Typography>
                    <Stack spacing={1}>
                      {currentEmail?.items.map(item => (
                        <Box key={item.itemId}>
                          <Typography>
                            • {item.name} (Qty: {item.quantity})
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        {(() => {
                          const startDate = invoice.rentalDateStart.toDate();
                          const endDate = invoice.rentalDateEnd.toDate();
                          const isSameDay = startDate.toDateString() === endDate.toDateString();
                          
                          if (isSameDay) {
                            return `Are these items available on ${startDate.toLocaleDateString()}?`;
                          } else {
                            return `Are these items available from ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}?`;
                          }
                        })()}
                      </Typography>
                      {/* <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button
                          variant="contained"
                          startIcon={<ThumbUpIcon />}
                          sx={{
                            bgcolor: 'success.main',
                            '&:hover': { bgcolor: 'success.dark' },
                            color: 'white'
                          }}
                          disabled
                        >
                          Yes to All
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<ThumbsUpDownIcon />}
                          sx={{
                            bgcolor: 'warning.main',
                            '&:hover': { bgcolor: 'warning.dark' },
                            color: 'white'
                          }}
                          disabled
                        >
                          Yes to Some
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<ThumbDownIcon />}
                          sx={{
                            bgcolor: 'error.main',
                            '&:hover': { bgcolor: 'error.dark' },
                            color: 'white'
                          }}
                          disabled
                        >
                          No to All
                        </Button>
                      </Stack> */}
                    </Box>
                  </Paper>
                </>
              ) : (
                <Typography color="text.secondary">
                  Select vendors for items to preview the email
                </Typography>
              )}
            </Box>

            {/* Right Panel - History */}
            {subrentalHistory.length > 0 && (
              <Box sx={{ 
                width: { xs: '100%', md: '30%' },
                height: { xs: '50%', md: 'auto' },
                p: 3,
                bgcolor: 'background.paper',
                overflowY: 'auto'
              }}>
                <Typography variant="h6" gutterBottom>
                  Request History
                </Typography>
                <Stack spacing={2}>
                  {subrentalHistory.map((request) => (
                    <Paper key={request.id} variant="outlined" sx={{ p: 2 }}>
                      <Stack spacing={1}>
                        <Typography variant="subtitle1">
                          Sent to: {request.vendorName} ({request.vendorEmail})
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {request.dates?.start?.seconds ? 
                            new Date(request.dates.start.seconds * 1000).toLocaleDateString() 
                            : 'Date not available'} 
                          {' - '}
                          {request.dates?.end?.seconds ? 
                            new Date(request.dates.end.seconds * 1000).toLocaleDateString()
                            : 'Date not available'}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          Requested Items:
                        </Typography>
                        {request.items.map((item, index) => (
                          <Typography key={index} variant="body2">
                            • {item.name} (Qty: {item.quantity})
                          </Typography>
                        ))}
                        {request.createdAt?.seconds && (
                          <Typography variant="caption" color="text.secondary">
                            Sent on: {new Date(request.createdAt.seconds * 1000).toLocaleString()}
                          </Typography>
                        )}
                      </Stack>
                    </Paper>
                  ))}
                </Stack>
              </Box>
            )}
          </Box>
        )}
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Confirm Subrental Requests
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {`You are about to send ${emailsByVendor.length} subrental request${emailsByVendor.length !== 1 ? 's' : ''} to:`}
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            {emailsByVendor.map((email, index) => {
              const vendor = vendors.find(v => v.id === email.vendorId);
              return (
                <Box key={email.vendorId} sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    {vendor?.companyName || vendor?.firstName || 'Unknown Vendor'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${email.items.length} item${email.items.length !== 1 ? 's' : ''} requested`}
                  </Typography>
                  <Box sx={{ ml: 2 }}>
                    {email.items.map((item, idx) => (
                      <Typography key={idx} variant="body2" color="text.secondary">
                        • {item.name} (Qty: {item.quantity})
                      </Typography>
                    ))}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirmAndSend} variant="contained" autoFocus>
            Send Requests
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubrentRequest;


  
  
  
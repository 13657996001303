  // New method for calculating the monetary components

import { Customer } from "../../../app/interfaces/customer";
import { Invoice } from "../../../app/interfaces/invoice";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import { TaxRate } from "../../../app/interfaces/taxRate";
import { ROW_TYPE_SUBTOTAL } from "../../../app/utils/models/modelConstants/modelConstants";
import Nifty from "../../../app/utils/Nifty";

  // MARK: Calc Components
  const calculateMonetaryComponents = ({
    selectedItems,
    invoiceTotalData,
    selectedTaxRate,
    deliveryFee,
    deliveryFeeTaxable,
  }: {
    selectedItems: SelectedItem[];
    invoiceTotalData: any;
    selectedTaxRate: TaxRate;
    deliveryFee: number;
    deliveryFeeTaxable: boolean;
  }) => {
    const delivery = deliveryFee;

    let taxableSubtotal = 0;
    let nonTaxableSubtotal = 0;
    let discountedSubtotal = 0;
    let discountedTaxableSubtotal = 0;

    let localDiscountLineItemsAmount = 0;
    let tipAmount = 0;
    let tipDiscountedAmount = 0;

    // add delivery fee
    // subtotal += delivery;

    // Sum row totals
    // Row types addition: damageWaiver | percentFee | flatFee | singleItem-rental | singleItem-bundle
    // Row types subtraction: discount
    // Row types ignore: subtotal | descriptionOnly

    selectedItems.forEach((i) => {
      if (["damageWaiver", "percentFee", "flatFee", "singleItem"].includes(i.rowType)) {
        if (i.selectedTaxable) {
          taxableSubtotal += i.rowTotal ?? 0;
        } else {
          nonTaxableSubtotal += i.rowTotal ?? 0;

          if (i.name === "Tip") {
            tipAmount += i.rowTotal ?? 0;
          }
        }
      }

      if (i.rowType === "discount") {
        localDiscountLineItemsAmount += i.rowTotal ?? 0;
      }
    });

      // 3. Add delivery fee to appropriate subtotal
      if (deliveryFeeTaxable) {
        taxableSubtotal += deliveryFee;
      } else {
        nonTaxableSubtotal += deliveryFee;
      }
  
    // 4. Calculate total subtotal
    let subtotal = taxableSubtotal + nonTaxableSubtotal;

    if (invoiceTotalData.hasTotalDiscount) {
      if (invoiceTotalData.totalDiscountType === "percent") {
        const calcTaxableSub = taxableSubtotal - (invoiceTotalData.totalDiscountPercent * taxableSubtotal);
        const calcNonTaxableSub = nonTaxableSubtotal - (invoiceTotalData.totalDiscountPercent * nonTaxableSubtotal);

        nonTaxableSubtotal = calcNonTaxableSub > 0 ? calcNonTaxableSub : 0;
        taxableSubtotal = calcTaxableSub > 0 ? calcTaxableSub : 0;
        tipDiscountedAmount = tipAmount - (invoiceTotalData.totalDiscountPercent * tipAmount);

      } else if (invoiceTotalData.totalDiscountType === "number") {
        const taxRatio = taxableSubtotal / subtotal;
       
        // Calculate the discount amount based on ratio for nonTaxableSubtotal and taxableSubtotal
        const nonTaxableDiscount = invoiceTotalData.totalDiscountNumber * (1 - taxRatio);
        const taxableDiscount = invoiceTotalData.totalDiscountNumber * taxRatio;

        const nonTaxableSubtotalWithoutTip = nonTaxableSubtotal - tipAmount;
        const subtotalWithoutTip = subtotal - tipAmount;

        if (nonTaxableSubtotalWithoutTip === 0) {
            taxableSubtotal = taxableSubtotal - invoiceTotalData.totalDiscountNumber;
        } else if (tipAmount > 0 && nonTaxableSubtotalWithoutTip > 0) {

            // The discount needs to be distributed between the taxable and non taxable items.
            // However, the tip amount should not be included in that distribution.
            // Therefore, we need to calculate the ratio of the discount to the subtotal excluding the tip.
            const tippedTaxRatio = nonTaxableSubtotalWithoutTip / subtotalWithoutTip;
            
            const tippedDiscount = invoiceTotalData.totalDiscountNumber * (1 - tippedTaxRatio);
            const nonTippedDiscount = invoiceTotalData.totalDiscountNumber * tippedTaxRatio;

            taxableSubtotal = taxableSubtotal - tippedDiscount;
            nonTaxableSubtotal = nonTaxableSubtotal - nonTippedDiscount;
            
            tipAmount = 0
        } else {
            nonTaxableSubtotal =  (nonTaxableSubtotal - nonTaxableDiscount) > 0 ? (nonTaxableSubtotal - nonTaxableDiscount) : 0;
            taxableSubtotal = (taxableSubtotal - taxableDiscount) > 0 ? (taxableSubtotal - taxableDiscount) : 0;
        } 
      }
    }

    // setDiscountLineItemsAmount(localDiscountLineItemsAmount);

    const componentData = {
      subtotal,
      taxableSubtotal,
      nonTaxableSubtotal,
      discountedSubtotal,
      discountedTaxableSubtotal,
      localDiscountLineItemsAmount,
      tipAmount,
      tipDiscountedAmount,
    };

    return componentData;
  };

  // MARK: Finalize with Taxes
  export const finalizeWithTaxes = (
    customer: Customer | null | undefined,
    invoice: Invoice | null | undefined,
    invoiceTotalData: any,
    selectedTaxRate: TaxRate,
    deliveryFee: number,
    deliveryFeeTaxable: boolean,
    selectedItems: SelectedItem[],
    orgData: OrganizationData | null | undefined,
  ) => {

    if (!orgData) return;

    // WEDNESDAY FEB 12 2025
    // We are going to keep the previous methods for calculating the taxes for orders where the lastUpdated is before today.
    // Or if the order has no balance remaining.
    // Otherwise, we will use the new method for calculating the taxes.

    const cutoffDate = new Date('2025-02-12T15:00:00')

    // CHECK IF LAST UPDATED IS BEFORE CUTOFF DATE
    const isBeforeCutoffDate = invoice?.updatedOn ? invoice.updatedOn.toDate() < cutoffDate : false;
    if (isBeforeCutoffDate) {
      console.log("isBeforeCutoffDate: ", isBeforeCutoffDate);
      return configureTax(customer, invoiceTotalData, selectedItems, orgData, selectedTaxRate, deliveryFee, deliveryFeeTaxable);
    }

    // Calculate monetary components - subtotal, taxableSubtotal, nonTaxableSubtotal, discountedSubtotal, discountedTaxableSubtotal
    
    const configureSubtotalResult = calculateMonetaryComponents({
      selectedItems,
      invoiceTotalData,
      selectedTaxRate,
      deliveryFee,
      deliveryFeeTaxable,
    });

    let newSubtotal               = configureSubtotalResult.subtotal;                   
    let taxableSubtotal           = configureSubtotalResult.taxableSubtotal;
    let nonTaxableSubtotal        = configureSubtotalResult.nonTaxableSubtotal;
    let tipAmount                 = configureSubtotalResult.tipAmount;
    let tipDiscountedAmount       = configureSubtotalResult.tipDiscountedAmount;
    let discountedSubtotal        = configureSubtotalResult.discountedSubtotal;         

    const customerIsTaxExempt = customer?.isTaxExempt ?? false;

    let totalTaxableAmt = customerIsTaxExempt ? 0 : taxableSubtotal;

    const { total, hasTotalDiscount, totalDiscountType, totalDiscountPercent, totalDiscountNumber } = invoiceTotalData;

    let finalTaxableAmt = totalTaxableAmt;

    // Convert to pennies and calculate
    const taxableAmtInPennies = Math.round(finalTaxableAmt * 100);
    const taxRateInHundredths = Math.round(selectedTaxRate.rate * 10000);
    const taxInPennies = Math.round((taxableAmtInPennies * taxRateInHundredths) / 10000);
    // Convert back to dollars
    const finalTaxRounded = taxInPennies / 100;

    const roundedNewSubtotal = Nifty.RoundToTwoDecimals(newSubtotal);

    if (hasTotalDiscount !== true) {
        tipAmount = 0;
        tipDiscountedAmount = 0;
    }

    const calculatedTotal = finalTaxRounded + taxableSubtotal + nonTaxableSubtotal + (tipAmount - tipDiscountedAmount);

    if (total === calculatedTotal) {
        invoiceTotalData.taxAmt = finalTaxRounded;
        return invoiceTotalData;
    } else {

      const totalsData = {
        taxAmt: finalTaxRounded,
        taxRate: selectedTaxRate?.rate ?? 0,
        total: calculatedTotal,
        subtotal: roundedNewSubtotal,
        discountedSubtotal: discountedSubtotal,
        hasTotalDiscount: hasTotalDiscount,
        totalDiscountType: totalDiscountType,
        totalDiscountPercent: totalDiscountPercent,
        totalDiscountNumber: totalDiscountNumber,
        totalTaxableAmt: totalTaxableAmt,
        totalNonTaxableAmt: nonTaxableSubtotal,
      };

      return totalsData;
    }
  };

  // **************************************************
  // Old method for calculating the monetary components - TODO: REMOVE ONE YEAR FROM FEB 12, 2025
  // **************************************************

  // MARK: Configure Subtotal
  const configureInvoiceSubtotal = ({
    selectedItems,
    invoiceTotalData,
    selectedTaxRate,
    deliveryFee,
    deliveryFeeTaxable,
  }: {
    selectedItems: SelectedItem[];
    invoiceTotalData: any;
    selectedTaxRate: TaxRate;
    deliveryFee: number;
    deliveryFeeTaxable: boolean;
  }) => {

    const delivery = deliveryFee;
    let subtotal = 0;
    let taxableSubtotal = 0;
    let localDiscountLineItemsAmount = 0;

    // delivery fee
    subtotal = subtotal + delivery;

    // Sum row totals
    // Row types addition: damageWaiver | percentFee | flatFee | singleItem-rental | singleItem-bundle
    // Row types subtraction: discount
    // Row types ignore: subtotal | descriptionOnly

    selectedItems.forEach((i) => {
      if (["damageWaiver", "percentFee", "flatFee", "singleItem"].includes(i.rowType)) {
        subtotal += i.rowTotal ?? 0;

        if (i.selectedTaxable) {
          taxableSubtotal += i.rowTotal ?? 0;
        }
      }

      if (i.rowType === "discount") {
        localDiscountLineItemsAmount += i.rowTotal ?? 0;
      }
    });

    if (invoiceTotalData.hasTotalDiscount) {
      if (invoiceTotalData.totalDiscountType === "percent") {
        const calcSub = subtotal - invoiceTotalData.totalDiscountPercent * subtotal;
        subtotal = calcSub > 0 ? calcSub : 0;
      } else if (invoiceTotalData.totalDiscountType === "number") {
        const discount = subtotal - invoiceTotalData.totalDiscountNumber;
        subtotal = discount >= 0 ? discount : 0;
      }
    }

    // setDiscountLineItemsAmount(localDiscountLineItemsAmount);
    return { subtotal, taxableSubtotal, localDiscountLineItemsAmount };
  };

  // MARK: Configure Tax
  const configureTax = (
    customer: Customer | null | undefined,
    invoiceTotalData: any,
    selectedItems: SelectedItem[],
    orgData: OrganizationData | null | undefined,
    selectedTaxRate: TaxRate,
    deliveryFee: number,
    deliveryFeeTaxable: boolean,
  ) => {
    if (!orgData) return;
  
    const configureSubtotalResult = configureInvoiceSubtotal({
      selectedItems,
      invoiceTotalData,
      selectedTaxRate,
      deliveryFee,
      deliveryFeeTaxable,
    });
    const newSubtotal = configureSubtotalResult.subtotal;

    const invItems = [...selectedItems];
    const customerIsTaxExempt = customer?.isTaxExempt ?? false;
    const deliveryFeeTaxableAmt = deliveryFeeTaxable ? deliveryFee : 0;
  
    let totalTaxableAmt = customerIsTaxExempt ? 0 : deliveryFeeTaxableAmt;
    let totalItemAmt = deliveryFee;
  
    invItems.forEach((i) => {
      if (i.rowType !== ROW_TYPE_SUBTOTAL) {
        totalItemAmt += i?.rowTotal ?? 0;
        if (i.selectedTaxable) {
          totalTaxableAmt += i?.rowTotal ?? 0;
        }
      }
    });
  
    const configureProRate = (taxableAmt: number, totalAmt: number) => {
      if (taxableAmt === 0 || totalAmt === 0) return 0;
      return taxableAmt / totalAmt;
    };
  
    const configureAdjustedTaxAmt = (
      taxableAmt: number,
      discount: number,
      prorate: number,
      adjSubtotal: number | undefined
    ) => {
      if (taxableAmt === 0 || adjSubtotal === 0) return 0;
      
      const amountOff = (adjSubtotal ?? 0) * discount;
      const proRatedAmtOff = amountOff * prorate;
  
      return taxableAmt - proRatedAmtOff;
    };
  
    const {
      total,
      taxRate,
      subtotal,
      hasTotalDiscount,
      totalDiscountType,
      totalDiscountPercent,
      totalDiscountNumber,
    } = invoiceTotalData;
  
    let finalTaxableAmt = 0;
    if (hasTotalDiscount) {
      if (totalDiscountType === "percent") {
        finalTaxableAmt = configureAdjustedTaxAmt(
          totalTaxableAmt,
          totalDiscountPercent / 100,
          configureProRate(totalTaxableAmt, totalItemAmt),
          newSubtotal
        );
      } else {
        const convertedPercent = totalDiscountNumber / (newSubtotal ?? 0);
        finalTaxableAmt = configureAdjustedTaxAmt(
          totalTaxableAmt,
          convertedPercent,
          configureProRate(totalTaxableAmt, totalItemAmt),
          newSubtotal
        );
      }
    } else {
      finalTaxableAmt = totalTaxableAmt;
    }
  
    const finalTaxRounded = Nifty.RoundToTwoDecimals(
      finalTaxableAmt !== 0 ? finalTaxableAmt * selectedTaxRate.rate : 0
    );
  
    const roundedNewSubtotal = Nifty.RoundToTwoDecimals(newSubtotal);
    const calculatedTotal = finalTaxRounded + roundedNewSubtotal;
  
    if (total === calculatedTotal) {
    //   return finalTaxRounded;
      invoiceTotalData.taxAmt = finalTaxRounded;
      return invoiceTotalData;
    } else {
      const totalsData = {
        taxAmt: finalTaxRounded,
        taxRate: selectedTaxRate?.rate ?? 0,
        total: calculatedTotal,
        subtotal: roundedNewSubtotal,
        hasTotalDiscount: hasTotalDiscount,
        totalDiscountType: totalDiscountType,
        totalDiscountPercent: totalDiscountPercent,
        totalDiscountNumber: totalDiscountNumber,
        totalTaxableAmt: totalTaxableAmt,
        totalNonTaxableAmt: 0,
        discountedSubtotal: 0,
      };
      return totalsData;
    }
  };

  // END: old method for calculating the monetary components
  // *******************************************************

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirebase } from 'react-redux-firebase'
import { authSelector, setAvailableOrgs, switchOrgThunk } from '../authSlice'

import { 
  Button,
  Menu,
  MenuItem,
  Box,
} from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { OrganizationData } from '../../../app/interfaces/organizationData'

export function OrgSwitcher() {
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const firebase = useFirebase()
  const { userData, availableOrgs, currentOrgId } = useSelector(authSelector)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  console.log("OrgSwitcher userData:", userData); // Debug log
  console.log("OrgSwitcher availableOrgs:", availableOrgs); // Debug log

  useEffect(() => {
    if (userData && userData?.isSuper === true) {
      // Load available orgs when component mounts
      const loadOrgs = async () => {
        try {
          const orgsSnapshot = await firestore.collection('orgs').get()
          const orgs = orgsSnapshot.docs.map(doc => ({
            id: doc.id,
            companyName: doc.data().companyName
          }))
          // Sort organizations alphabetically by companyName
          const sortedOrgs = orgs.sort((a, b) => 
            (a.companyName || '').localeCompare(b.companyName || '')
          )
          dispatch(setAvailableOrgs(sortedOrgs))
        } catch (err) {
          console.error('Error loading orgs:', err)
        }
      }
      loadOrgs()
    }
  }, [dispatch, userData, firestore])


  if (!userData || 
      (!userData.isSuper && (!userData.multiLocation || userData.multiLocation.length === 0)) || 
      !availableOrgs.length) {
    return null
  }

  const currentOrg = availableOrgs.find((org: OrganizationData) => org.id === currentOrgId)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOrgSelect = (orgId: string) => {
    dispatch(switchOrgThunk({ firebase, firestore }, orgId))
    handleClose()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<BusinessIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        fullWidth
        sx={{ 
          justifyContent: 'space-between',
          textAlign: 'left',
          minHeight: 40
        }}
      >
        {currentOrg?.companyName || 'Select Organization'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {availableOrgs.map((org: OrganizationData) => (
          <MenuItem 
            key={org.id} 
            onClick={() => handleOrgSelect(org.id)}
            selected={org.id === currentOrgId}
          >
            {org.companyName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
} 
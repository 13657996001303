import { INVOICES_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkInvoices } from "../../../utils/models/checkers/checkInvoices";
import { INVOICE } from "../../../utils/models/modelConstants/modelConstants";
import { invoiceUtcOffsetQuery } from "../../../utils/models/configure/wrapper/invoiceUtcOffsetQuery";
import { ExtendedFirestoreInstance } from "react-redux-firebase";

export function invoices(orgTimezone: string) {
  async function fetchInvoices(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string
  ) {
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .orderBy("invoiceNumber", "desc");
    const snap = await ref.get();
    return snap.docs.map((s) => checkInvoices(s, orgTimezone));
  }
  async function fetchInvoicesByCustomer(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string,
    customerId: string
  ) {
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .where("customerQueryHook", "==", customerId)
      .where("type", "==", INVOICE)
      .orderBy("invoicePaymentDueDate", "asc");
    const snap = await ref.get();
    return snap.docs.map((s) => checkInvoices(s, orgTimezone));
  }
  async function fetchInvoice(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string,
    opts: { id: string }
  ) {
    const { id } = opts;
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .doc(id);
    const snap = await ref.get();
    return checkInvoices(snap, orgTimezone);
  }
  async function fetchInvoiceRawData(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string,
    opts: { id: string }
  ) {
    const { id } = opts;
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .doc(id);
    return await ref.get();
  }
  async function fetchInvoicesInRange(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string,
    opts: {
      rentalDateStart: any;
      rentalDateEnd: any;
      useTurnaround: any;
    }
  ) {
    const {
      rentalDateStart: localRentalDateStart,
      rentalDateEnd: localRentalDateEnd,
      useTurnaround,
    } = opts;
    
    if (!localRentalDateStart || !localRentalDateEnd) return;

  
    const convertedOrgTimes = invoiceUtcOffsetQuery().forDates({
      startDate: localRentalDateStart,
      endDate: localRentalDateEnd,
      orgTimezone,
    });

    const rentalDateStart = convertedOrgTimes.start;
    const endDate = useTurnaround ? "rentalTurnaroundEnd" : "rentalDateEnd";

    // Get the start of the day for the rental start date
    const startOfDay = new Date(rentalDateStart);
    startOfDay.setHours(0, 0, 0, 0);
    
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      // Look for any invoices that:
      // 1. End after our query start date OR
      // 2. Start on the same day as our query
      .where(endDate, ">=", startOfDay);

    const snap = await ref.get();

    return snap.docs
      .map((s) => checkInvoices(s, orgTimezone))
      .filter((i) => {
        const start = new Date(i.rentalDateStart.seconds * 1000);
        const end = new Date(i.rentalDateEnd.seconds * 1000);

        // For same-day rentals, if end is before start, assume it spans to next day
        if (start.toDateString() === end.toDateString() && end < start) {
          end.setDate(end.getDate() + 1);
        }

        const isIncluded = start <= localRentalDateEnd && end >= localRentalDateStart;

        return isIncluded;
      });
  }

  async function fetchMonthlyInvoicesByStartDate(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string,
    opts: {
      rentalDateStart: any;
      rentalDateEnd: any;
    }
  ) {

    const {
      rentalDateStart: localRentalDateStart,
      rentalDateEnd: localRentalDateEnd,
    } = opts;

    if (!localRentalDateStart || !localRentalDateEnd) return;

    const convertedOrgTimes = invoiceUtcOffsetQuery().forDates({
      startDate: localRentalDateStart,
      endDate: localRentalDateEnd,
      orgTimezone,
    });

    const rentalDateStart = convertedOrgTimes.start;
    const ref = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .where("rentalTurnaroundEnd", ">", rentalDateStart)
      .where("type", "==", INVOICE);
      
    const snap = await ref.get();
    return snap.docs
      .map((s) => checkInvoices(s, orgTimezone))
      .filter((i) => {
          const start =  new Date(i.rentalDateStart.seconds * 1000);
          return start < localRentalDateEnd;
        });
  }

  return {
    fetchInvoices, // fetch invoices
    fetchInvoice, //fetch singular invoice
    fetchInvoicesInRange,
    fetchMonthlyInvoicesByStartDate, // use for useInvoicesInMonth hook
    fetchInvoicesByCustomer, // use for Receive Payment hook
    fetchInvoiceRawData, // for duplication
  };
}

import React, { useContext } from "react";
import { ItemTag } from "../../../../../app/system-components/tags/Tags";
import { Card, Col, Collapse, Row } from "antd";
import { useStopCell } from "../../../../../app/services/hooks/useStopCell";
import StopCellHeader from "../shared/StopCellHeader";
import InvoiceNotes from "../shared/InvoiceNotes";
import { RouteContext } from "../../../NewRoute";
import {
  COLOR_LIGHT_GRAY,
  COLOR_ORANGE_HIGHLIGHT,
  COLOR_RED_0,
} from "../../../../../app/ui/colorConstants";

const { Panel } = Collapse;
const StopCellUnassigned = ({ stop, orgData }) => {
  const { actions, state } = useContext(RouteContext);
  const {
    invoiceNumber,
    items,
    notes,
    inRoutes,
    typeLabel,
    timeWindow,
    currentlySelected,
  } = useStopCell({
    stop,
    routes: state.routes,
    currentRouteId: state.currentRouteId,
    selectedStop: state.selectedStop,
  });
  return (
    <div
      style={{
        background: currentlySelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF",
      }}
    >
      <Collapse
        defaultActiveKey={null}
        bordered={true}
        style={{ padding: 0 }}
        ghost={true}
        activeKey={null}
      >
        <Panel
          showArrow={false}
          className={"Stop-Header"}
          header={
            <StopCellHeader
              orgData={orgData}
              stop={stop}
              invoiceNumber={invoiceNumber}
              inRoutes={inRoutes}
              typeLabel={typeLabel}
              timeWindow={timeWindow}
            />
          }
          key={`${invoiceNumber}`}
        ></Panel>
      </Collapse>
      <InvoiceNotes notes={notes} />
    </div>
  );
};

export default StopCellUnassigned;

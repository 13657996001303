import Checkbox from "@mui/material/Checkbox";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Iconify from "../../../app/iconify";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import {
  INV_ITEM_RATE,
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE_RANGE,
} from "../../../app/utils/models/modelConstants/modelConstants";

import { makeStyles } from "@mui/styles";
import { firelikeId } from "../../../app/utils/models/checkers/firelikeid";
import { enqueueSnackbar } from "notistack";
import { green, red, yellow } from "@mui/material/colors";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import RowDragHandle from "./DragHandle";
import { InventoryItem } from "../../../app/interfaces/inventoryItem";

import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import BundleItemsSummary from "../components/BundleItemsSummary";
import { DailyInventoryStatus } from "../../../app/interfaces/dailyInventoryStatus";

// ----------------------------------------------------------------------

type Props = {
  item: any;
  orgData: OrganizationData;
  index: number;
  isSalesInvoice: boolean;
  selectedItems: SelectedItem[];
  availability: any;
  // dailyInventoryStatus: DailyInventoryStatus[] | null;
  // peakInventory: DailyInventoryStatus | null;
  loadingAvailability: boolean;
  // loadingDailyStatus?: boolean;

  fullInventory: InventoryItem[];
  handleInsertItem: (index: number) => void;
  // handleInsertSubtotal: (index: number) => void;
  // handleInsertLabel: (index: number) => void;
  // handleInsertDamageWaiver: (index: number) => void;
  // handleInsertFlatFee: (index: number) => void;

  handleInsertSpecial: (itemType: string, index: number, itemData: any, qty: number) => void;

  handleUpdateSelectedItem: (index: number, item: any) => void;
  onChangeItem: (item: SelectedItem) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function BundleRow({
  item,
  orgData,
  index,
  selectedItems,
  isSalesInvoice,
  availability,
  // dailyInventoryStatus,
  // peakInventory,
  loadingAvailability,
  // loadingDailyStatus,
  fullInventory,
  handleInsertItem,
  handleInsertSpecial,
  handleUpdateSelectedItem,
  onChangeItem,
  onDeleteRow,
}: Props) {


  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const durationRef = useRef<HTMLInputElement>(null);

  const responsiveFontSize = useResponsiveFontSize();
  const { currentBreakpoint, isSMAndDown, isMDAndDown } = useCurrentBreakpoint();
  const classes = useStyles();

  const theme = useTheme();

  const [itemName, setItemName] = useState(item?.name || "");
  const [itemDescription, setItemDescription] = useState(
    item?.selectedDescription || ""
  );
  const [duration, setDuration] = useState(item?.selectedDuration || 0);
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [quantity, setQuantity] = useState<number | null>(item?.selectedQty ?? 0);

  const [total, setTotal] = useState(item?.rowTotal || 0);
  const [showBundleItems, setShowBundleItems] = useState(false);

  const [rate, setRate] = useState(
    item?.selectedRateMeta || {
      rate: 0,
      rateName: "",
      rateRange: "",
    }
  );

  const [customAmt, setCustomAmt] = useState<any>(null);
  const [customRange, setCustomRange] = useState<any>("day");

  const configureDurationOpts = (type: string) => {
    if (type === "sale") {
      // return <Option value={"none"}>None</Option>;
    } else {
      const opts = [
        { label: "Per hour", value: "hour" },
        { label: "Per day", value: "day" },
        { label: "Per week", value: "week" },
        { label: "Per month", value: "month" },
      ];
      return opts;
    }
  };
  const handleCustomRate = () => {
    // create custom rate obj with unique name
    const rate = {
      id: firelikeId(),
      [INV_ITEM_RATE]: customAmt,
      [INV_ITEM_RATE_RANGE]: customRange,
      [INV_ITEM_RATE_NAME]: `Custom ${item.rates ? item.rates.length + 1 : 1}`,
    };

    item.rates.push(rate);
    setRate(rate);
    setCustomAmt(0);
    setCustomRange("day");
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    updateItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  // Calculate the total based on quantity, rate, and duration
  const calculateTotal = useCallback(() => {
    if (item.type === "sale") {
      setTotal((quantity ?? 0) * rate.rate);
    } else {
      setTotal(rate.rate * duration * (quantity ?? 0));
    }
  }, [quantity, duration, rate.rate, item.type]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    item.selectedQty = quantity ?? 0;;
    item.selectedDuration = duration;
    item.selectedTaxable = taxable;
    item.rowTotal = total;
    item.selectedRateMeta = rate;

    handleUpdateSelectedItem(item.listIndex, item);
  };

  const handleDurationChange = (event: any) => {
    const value = event.target.value;
    setDuration(value === "" ? "" : Number(value));
  };

  const NumberCircle = React.memo(function NumberCircle({ available, dailyAvailable }: { available: number | null | undefined, dailyAvailable?: number | null }) {

    if (loadingAvailability) {
      return (
        <Tooltip title={"Loading availability data"}>
          <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
            {`...`}
          </Typography>
        </Tooltip>
      );
    }

    if ((available === undefined || available === null || isNaN(available)) && 
        (dailyAvailable === undefined || dailyAvailable === null || isNaN(dailyAvailable))) {
      return (
        <Tooltip title={"No availability data"}>
          <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
            {`...`}
          </Typography>
        </Tooltip>
      );
    }

    const adjusted = available !== null && available !== undefined ? available - (quantity ?? 0) : 0;
    const dailyAdjusted = dailyAvailable !== undefined && dailyAvailable !== null ? dailyAvailable - (quantity ?? 0) : null;

    const getBackgroundColor = (value: number | null) => {
      if (value === null) return undefined;
      if (value < 0) return red[500];
      if (value === 0) return yellow[700];
      return green[500];
    };

    const formatNumber = (value: number | null) => {
      if (value === null) return "...";
      if (value > 999) return "+1k";
      return value.toString();
    };

    // const tooltipText = dailyAdjusted !== null ? 
    //   `Current Method: ${adjusted}, New Method: ${dailyAdjusted}` : 
    //   `Available: ${adjusted}`;

    const tooltipText = `Available: ${adjusted}`;

    return (
      <Tooltip title={tooltipText}>
        <Stack direction="column" spacing={0.5} alignItems="center">
          <Typography
            fontWeight={"bold"}
            fontSize={10}
            sx={{
              color: getBackgroundColor(adjusted),
            }}
          >
            {formatNumber(adjusted)}
          </Typography>
          {/* {dailyAdjusted !== null && (
            <Typography
              fontWeight={"bold"}
              fontSize={10}
              sx={{
                color: getBackgroundColor(dailyAdjusted),
              }}
            >
              {formatNumber(dailyAdjusted)}
            </Typography>
          )} */}
        </Stack>
      </Tooltip>
    );
  });

  const getAvailabilityForId = (id: string) => {
    if (!availability) {
      return null;
    }

    const foundAvailability = availability.find((a: any) => a.id === id);
    if (!foundAvailability) {
      return null;
    }

    // Calculate quantity from direct items (excluding current bundle)
    const qtyFromSelectedItems = selectedItems
      .filter((i) => i.uuid !== item.uuid && i.id === id)
      .reduce((total, item) => total + (item.selectedQty || 0), 0);

    // Calculate quantity from bundle items (excluding current bundle)
    let qtyFromBundleItems = 0;
    selectedItems.forEach((si) => {
      if (si.uuid !== item.uuid && si.type === "bundle" && si.bundleItems) {
        si.bundleItems.forEach((bundleItem) => {
          if (bundleItem.bundleItemId === id) {
            qtyFromBundleItems += (bundleItem.bundleItemQty || 0) * si.selectedQty;
          }
        });
      }
    });

    const totalQty = qtyFromSelectedItems + qtyFromBundleItems;
    const baseAvailability = (foundAvailability.stock ?? 0) - (foundAvailability.unavailableStock ?? 0) - totalQty;

    // For bundles, calculate constrained availability based on sub-items
    if (item.type === "bundle" && item.bundleItems) {
      const subComponentAvailabilities = item.bundleItems.map((subItem: any) => {
        const subItemInInventory = fullInventory.find((i) => i.id === subItem.bundleItemId);
        if (!subItemInInventory) return 0;

        const subItemStatus = availability.find((a: any) => a.id === subItem.bundleItemId);
        if (!subItemStatus) return 0;

        // Calculate sub-item's base availability
        const subItemQty = selectedItems
          .filter((i) => i.uuid !== item.uuid && i.id === subItem.bundleItemId)
          .reduce((total, item) => total + (item.selectedQty || 0), 0);

        let subItemBundleQty = 0;
        selectedItems.forEach((si) => {
          if (si.uuid !== item.uuid && si.type === "bundle" && si.bundleItems) {
            si.bundleItems.forEach((bi) => {
              if (bi.bundleItemId === subItem.bundleItemId) {
                subItemBundleQty += (bi.bundleItemQty || 0) * si.selectedQty;
              }
            });
          }
        });

        const subItemAvailability = (subItemInInventory.stock ?? 0) - 
          (subItemStatus.unavailableStock ?? 0) - 
          (subItemQty + subItemBundleQty);

        // Divide by the quantity required per bundle to get number of possible bundles
        return Math.floor(subItemAvailability / (subItem.bundleItemQty || 1));
      });

      // Return the most constraining number of possible bundles
      return Math.min(baseAvailability, ...subComponentAvailabilities);
    }

    return baseAvailability;
  };

  return (
    <Box>
      <Grid sx={{ backgroundColor: theme.palette.background.default }} container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="top" columns={24}>
        {/* //MARK: - Item */}
        <Grid item xs={24} sm={12} lg={6}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <RowDragHandle
              triggerType="rightClick"
              index={index}
              menuItems={[
                {
                  text: "Insert Item",
                  onClick: () => handleInsertItem(index),
                },
                {
                  text: "Insert Subtotal",
                  onClick: () => handleInsertSpecial("subtotal", index, null, 1),
                },
                // {
                //   text: "Insert Label",
                //   onClick: () => handleInsertLabel(index),
                // },
                // {
                //   text: "Insert Damage Waiver",
                //   onClick: onChangeItem,
                // },
                {
                  text: "Delete",
                  onClick: () => onDeleteRow(item.listIndex),
                },
              ]}
            />
            <Chip
              label="Bundle"
              onClick={() => {
                setShowBundleItems(!showBundleItems);
              }}
            />
            <TextField
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  minRows: 1,
                  maxRows: isMDAndDown ? 2 : 3,
                  style: { resize: "none", fontSize: responsiveFontSize },
                },
              }}
              onClick={() => { onChangeItem(item)}}
              inputProps={{
                sx: {
                  fontSize: responsiveFontSize,
                  readOnly: true,
                },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
              size="small"
              label="Item Name"
              variant="outlined"
              fullWidth
              value={itemName}
            />
          </Stack>
        </Grid>

        {/* //MARK: - Description */}
        <Grid item xs={24} sm={12} lg={7} xl={8}>
          <TextField
            inputProps={{ style: { fontSize: responsiveFontSize } }}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 1,
                maxRows: isMDAndDown ? 2 : 4,
                style: { resize: "none", fontSize: responsiveFontSize },
              },
            }}
            inputRef={descriptionRef}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            fullWidth
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            multiline
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.shiftKey) {
                e.preventDefault();
                setItemDescription((prev: string) => prev + "\n");
              } else if (e.key === "Enter") {
                e.preventDefault();
                if (descriptionRef.current) {
                  descriptionRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - Quantity */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            inputRef={quantityRef}
            className={classes.input}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Quantity"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 1,
                style: { fontSize: responsiveFontSize },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <NumberCircle available={getAvailabilityForId(item.id)} />
                </InputAdornment>
              ),
            }}
            placeholder="0"
            value={quantity === null ? "" : quantity}
            onChange={(e) => {
              const val = e.target.value === "" ? null : Number(e.target.value);
              setQuantity(val);
            }}   
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (quantityRef.current) {
                  quantityRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - Rate */}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            disabled={true}
            inputProps={{
              sx: {
                fontSize: responsiveFontSize,
                readOnly: true,
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={handleClick}>
                  <Iconify icon="carbon:edit" />
                </IconButton>
              ),
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Rate"
            variant="outlined"
            fullWidth
            value={`$${rate.rate} / ${rate.rateRange}`}
          />
        </Grid>

        {/* //MARK: - Duration */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            required={true}
            disabled={isSalesInvoice}
            inputRef={durationRef}
            className={classes.input}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Duration"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
                style: { fontSize: responsiveFontSize },
              },
            }}
            placeholder="0"
            value={duration}
            onChange={handleDurationChange}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (durationRef.current) {
                  durationRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - TAXABLE */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={taxable}
                onChange={(e) => {
                  setTaxable(e.target.checked);
                }}
              />
            }
            label={<Typography fontSize={responsiveFontSize}>{isMDAndDown ? "Taxable" : "Taxed"}</Typography>}
          />
        </Grid>

        {/* //MARK: - TOTAL */}
        <Grid item xs={12} sm={12} md={4} lg={1}>
          <div style={{ width: "100%", textAlign: "left", paddingRight: "16px" }}>
            {useMediaQuery(theme.breakpoints.up("lg")) && <Typography fontSize={responsiveFontSize}>{FormatToLocalCurrency(total, orgData)}</Typography>}
            {useMediaQuery(theme.breakpoints.down("lg")) && (
              <Typography fontSize={responsiveFontSize}>Total: {FormatToLocalCurrency(total, orgData)}</Typography>
            )}
          </div>
        </Grid>

        {/* //MARK: - ACTIONS */}
        <Grid item alignContent={"start"} xs={12} sm={12} md={4} lg={1}>
          <Box display="flex" justifyContent={isMDAndDown ? "flex-end" : "flex-start"} width="100%">
            <Button size="small" color="error" startIcon={<Iconify icon="solar:trash-bin-trash-bold" />} onClick={() => onDeleteRow(item.listIndex)} />
          </Box>
        </Grid>
      </Grid>

      {showBundleItems && availability && (
        <Box>
          <Stack sx={{ p: 1, ml: 4 }}>
            {
              loadingAvailability ? (
                <Typography gutterBottom fontSize={responsiveFontSize}>
                  Loading availability data...
                </Typography>
              ) : (
                <BundleItemsSummary 
                  data={availability.find((a: any) => a.id === item.id)} 
                  fullInventory={fullInventory} 
                  item={item} 
                  currentQuantity={quantity}
                  selectedItems={selectedItems}
                  // dailyInventoryStatus={dailyInventoryStatus}
                  // peakInventory={peakInventory}
                  // loadingDailyStatus={loadingDailyStatus}
                />
              )
            }
          </Stack>
          {!isMDAndDown && (
            <Divider
              sx={{
                mb: 3,
                mt: 1,
              }}
            />
          )}
        </Box>
      )}

      {/* //MARK: - RATE POPOVER */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: { width: "300px" },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <List>
            {item &&
              item.rates &&
              item.rates.map((r: any) => (
                <ListItemButton
                  key={r.id}
                  onClick={() => {
                    setRate(r);
                    handleClose();
                  }}
                >
                  <Typography variant="body2">{`${r.rateName} (${r.rate}${item.type === "sale" ? "" : ` per ${r.rateRange}`})`}</Typography>
                </ListItemButton>
              ))}
          </List>

          <Typography sx={{ mt: 1 }} variant="subtitle2">
            CUSTOM RATE
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Stack direction={"row"} gap={1}>
              <TextField
                className={classes.input}
                size="small"
                label="Amount"
                type="number"
                placeholder="0.00"
                value={customAmt === 0 ? "" : customAmt}
                onChange={(e) => setCustomAmt(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ typography: "subtitle2", color: "text.disabled" }}>$</Box>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                style={{ width: "125px" }}
              />
              <FormControl style={{ width: "140px" }}>
                <InputLabel>Duration</InputLabel>
                <Select
                  onChange={(event) => {
                    setCustomRange(event.target.value);
                  }}
                  value={customRange}
                  size="small"
                >
                  {configureDurationOpts(item.type)?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton
                onClick={() => {
                  if (customAmt) {
                    handleCustomRate();
                  } else {
                    enqueueSnackbar("Please enter a rate amount", {
                      variant: "error",
                    });
                  }
                }}
              >
                <Iconify icon="material-symbols:add-circle" color={theme.palette.success.dark} />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}




// import Checkbox from "@mui/material/Checkbox";
// import {
//   Badge,
//   Box,
//   Button,
//   Chip,
//   Divider,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   List,
//   ListItemButton,
//   MenuItem,
//   Popover,
//   Select,
//   Stack,
//   TextareaAutosize,
//   TextField,
//   Tooltip,
//   Typography,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import React, { useCallback, useEffect, useRef, useState } from "react";
// import Iconify from "../../../app/iconify";
// import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
// import {
//   INV_ITEM_RATE,
//   INV_ITEM_RATE_NAME,
//   INV_ITEM_RATE_RANGE,
// } from "../../../app/utils/models/modelConstants/modelConstants";

// import { makeStyles } from "@mui/styles";
// import { firelikeId } from "../../../app/utils/models/checkers/firelikeid";
// import { enqueueSnackbar } from "notistack";
// import { green, red, yellow } from "@mui/material/colors";
// import { OrganizationData } from "../../../app/interfaces/organizationData";
// import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
// import RowDragHandle from "./DragHandle";
// import { InventoryItem } from "../../../app/interfaces/inventoryItem";

// import { SelectedItem } from "../../../app/interfaces/SelectedItem";
// import BundleItemsSummary from "../components/BundleItemsSummary";
// import { DailyInventoryStatus } from "../../../app/interfaces/dailyInventoryStatus";

// // ----------------------------------------------------------------------

// type Props = {
//   item: any;
//   orgData: OrganizationData;
//   index: number;
//   isSalesInvoice: boolean;
//   selectedItems: SelectedItem[];
//   availability: any;
//   dailyInventoryStatus: DailyInventoryStatus[] | null;
//   peakInventory: DailyInventoryStatus | null;
//   loadingAvailability: boolean;
//   loadingDailyStatus?: boolean;

//   fullInventory: InventoryItem[];
//   handleInsertItem: (index: number) => void;
//   // handleInsertSubtotal: (index: number) => void;
//   // handleInsertLabel: (index: number) => void;
//   // handleInsertDamageWaiver: (index: number) => void;
//   // handleInsertFlatFee: (index: number) => void;

//   handleInsertSpecial: (itemType: string, index: number, itemData: any, qty: number) => void;

//   handleUpdateSelectedItem: (index: number, item: any) => void;
//   onChangeItem: (item: SelectedItem) => void;
//   onDeleteRow: (index: number) => void;
// };

// const useStyles = makeStyles({
//   input: {
//     "& input[type=number]": {
//       "-moz-appearance": "textfield",
//     },
//     "& input[type=number]::-webkit-outer-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//     "& input[type=number]::-webkit-inner-spin-button": {
//       "-webkit-appearance": "none",
//       margin: 0,
//     },
//   },
// });

// export default function BundleRow({
//   item,
//   orgData,
//   index,
//   selectedItems,
//   isSalesInvoice,
//   availability,
//   dailyInventoryStatus,
//   peakInventory,
//   loadingAvailability,
//   loadingDailyStatus,
//   fullInventory,
//   handleInsertItem,
//   handleInsertSpecial,
//   handleUpdateSelectedItem,
//   onChangeItem,
//   onDeleteRow,
// }: Props) {


//   // console.log("bundle availability", availability)
//   // Text Field Refs
//   const descriptionRef = useRef<HTMLInputElement>(null);
//   const quantityRef = useRef<HTMLInputElement>(null);
//   const durationRef = useRef<HTMLInputElement>(null);

//   const responsiveFontSize = useResponsiveFontSize();
//   const { currentBreakpoint, isSMAndDown, isMDAndDown } = useCurrentBreakpoint();
//   const classes = useStyles();

//   const theme = useTheme();

//   const [itemName, setItemName] = useState(item?.name || "");
//   const [itemDescription, setItemDescription] = useState(
//     item?.selectedDescription || ""
//   );
//   const [duration, setDuration] = useState(item?.selectedDuration || 0);
//   const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
//   const [quantity, setQuantity] = useState<number | null>(item?.selectedQty ?? 0);

//   const [total, setTotal] = useState(item?.rowTotal || 0);
//   const [showBundleItems, setShowBundleItems] = useState(false);

//   const [rate, setRate] = useState(
//     item?.selectedRateMeta || {
//       rate: 0,
//       rateName: "",
//       rateRange: "",
//     }
//   );

//   const [customAmt, setCustomAmt] = useState<any>(null);
//   const [customRange, setCustomRange] = useState<any>("day");

//   const configureDurationOpts = (type: string) => {
//     if (type === "sale") {
//       // return <Option value={"none"}>None</Option>;
//     } else {
//       const opts = [
//         { label: "Per hour", value: "hour" },
//         { label: "Per day", value: "day" },
//         { label: "Per week", value: "week" },
//         { label: "Per month", value: "month" },
//       ];
//       return opts;
//     }
//   };
//   const handleCustomRate = () => {
//     // create custom rate obj with unique name
//     const rate = {
//       id: firelikeId(),
//       [INV_ITEM_RATE]: customAmt,
//       [INV_ITEM_RATE_RANGE]: customRange,
//       [INV_ITEM_RATE_NAME]: `Custom ${item.rates ? item.rates.length + 1 : 1}`,
//     };

//     item.rates.push(rate);
//     setRate(rate);
//     setCustomAmt(0);
//     setCustomRange("day");
//     handleClose();
//   };

//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedValue, setSelectedValue] = useState("");

//   const handleClick = (event: any) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   useEffect(() => {
//     updateItem();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [total]);

//   // Calculate the total based on quantity, rate, and duration
//   const calculateTotal = useCallback(() => {
//     if (item.type === "sale") {
//       setTotal((quantity ?? 0) * rate.rate);
//     } else {
//       setTotal(rate.rate * duration * (quantity ?? 0));
//     }
//   }, [quantity, duration, rate.rate, item.type]);

//   useEffect(() => {
//     calculateTotal();
//   }, [calculateTotal]);

//   const updateItem = () => {
//     item.selectedDescription = itemDescription;
//     item.selectedQty = quantity ?? 0;;
//     item.selectedDuration = duration;
//     item.selectedTaxable = taxable;
//     item.rowTotal = total;
//     item.selectedRateMeta = rate;

//     handleUpdateSelectedItem(item.listIndex, item);
//   };

//   const handleDurationChange = (event: any) => {
//     const value = event.target.value;
//     setDuration(value === "" ? "" : Number(value));
//   };

//   const NumberCircle = React.memo(function NumberCircle({ available, dailyAvailable }: { available: number | null | undefined, dailyAvailable?: number | null }) {

//     if (loadingDailyStatus) {
//       return (
//         <Tooltip title={"Loading availability data"}>
//           <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
//             {`...`}
//           </Typography>
//         </Tooltip>
//       );
//     }

//     if ((available === undefined || available === null || isNaN(available)) && 
//         (dailyAvailable === undefined || dailyAvailable === null || isNaN(dailyAvailable))) {
//       return (
//         <Tooltip title={"No availability data"}>
//           <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
//             {`...`}
//           </Typography>
//         </Tooltip>
//       );
//     }

//     const adjusted = available !== null && available !== undefined ? available - (quantity ?? 0) : 0;
//     const dailyAdjusted = dailyAvailable !== undefined && dailyAvailable !== null ? dailyAvailable - (quantity ?? 0) : null;

//     const getBackgroundColor = (value: number | null) => {
//       if (value === null) return undefined;
//       if (value < 0) return red[500];
//       if (value === 0) return yellow[700];
//       return green[500];
//     };

//     const formatNumber = (value: number | null) => {
//       if (value === null) return "...";
//       if (value > 999) return "+1k";
//       return value.toString();
//     };

//     const tooltipText = dailyAdjusted !== null ? 
//       `Current Method: ${adjusted}, New Method: ${dailyAdjusted}` : 
//       `Available: ${adjusted}`;

//     // const tooltipText = `Available: ${dailyAdjusted}`;

//     return (
//       <Tooltip title={tooltipText}>
//         <Stack direction="column" spacing={0.5} alignItems="center">
//           <Typography
//             fontWeight={"bold"}
//             fontSize={10}
//             sx={{
//               color: getBackgroundColor(adjusted),
//             }}
//           >
//             {formatNumber(adjusted)}
//           </Typography>
//           {dailyAdjusted !== null && (
//             <Typography
//               fontWeight={"bold"}
//               fontSize={10}
//               sx={{
//                 color: getBackgroundColor(dailyAdjusted),
//               }}
//             >
//               {formatNumber(dailyAdjusted)}
//             </Typography>
//           )}
//         </Stack>
//       </Tooltip>
//     );
//   });

//   const getAvailabilityForId = (id: string) => {
    
//     if (!availability) {
//       return null
//     }
    
//     const foundAvailablilty = availability.find((a: any) => a.id === id);
    
//     if (foundAvailablilty) {
//       const total = foundAvailablilty.stock - foundAvailablilty.unavailableStock;
//       return total ?? 0;
//     } else {
//       return null;
//     }
//   }

//   const getDailyStatusForId = (id: string) => {
//     if (!peakInventory || !peakInventory.items || !peakInventory.items[id]) {
//       return null;
//     }

//     const itemStatus = peakInventory?.items[id];
//     if (!itemStatus) return null;

//     const foundAvailability = fullInventory.find((a: any) => a.id === id);
//     if (!foundAvailability) return null;

//     // Calculate quantity from direct items (excluding current bundle)
//     const qtyFromSelectedItems = selectedItems
//       .filter(i => i.uuid !== item.uuid && i.id === id)
//       .reduce((total, item) => total + (item.selectedQty || 0), 0);

//     // Calculate quantity from bundle items (excluding current bundle)
//     let qtyFromBundleItems = 0;
//     selectedItems.forEach((si) => {
//       if (si.uuid !== item.uuid && si.type === 'bundle' && si.bundleItems) {
//         si.bundleItems.forEach((bundleItem) => {
//           if (bundleItem.bundleItemId === id) {
//             qtyFromBundleItems += (bundleItem.bundleItemQty || 0) * si.selectedQty;
//           }
//         });
//       }
//     });

//     const totalQty = qtyFromSelectedItems + qtyFromBundleItems;
//     const baseAvailability = (foundAvailability.stock ?? 0) - itemStatus.outByInvoice - totalQty;

//     // For bundles, calculate constrained availability based on sub-items
//     if (item.type === 'bundle' && item.bundleItems) {
//       const subComponentAvailabilities = item.bundleItems.map((subItem: any) => {
//         const subItemInInventory = fullInventory.find(i => i.id === subItem.bundleItemId);
//         if (!subItemInInventory) return 0;

//         const subItemStatus = peakInventory.items[subItem.bundleItemId];
//         if (!subItemStatus) return 0;

//         // Calculate sub-item's base availability
//         const subItemQty = selectedItems
//           .filter(i => i.uuid !== item.uuid && i.id === subItem.bundleItemId)
//           .reduce((total, item) => total + (item.selectedQty || 0), 0);

//         let subItemBundleQty = 0;
//         selectedItems.forEach((si) => {
//           if (si.uuid !== item.uuid && si.type === 'bundle' && si.bundleItems) {
//             si.bundleItems.forEach((bi) => {
//               if (bi.bundleItemId === subItem.bundleItemId) {
//                 subItemBundleQty += (bi.bundleItemQty || 0) * si.selectedQty;
//               }
//             });
//           }
//         });

//         const subItemAvailability = (subItemInInventory.stock ?? 0) - 
//           subItemStatus.outByInvoice - 
//           (subItemQty + subItemBundleQty);

//         // Divide by the quantity required per bundle to get number of possible bundles
//         return Math.floor(subItemAvailability / (subItem.bundleItemQty || 1));
//       });

//       // Return the most constraining number of possible bundles
//       return Math.min(baseAvailability, ...subComponentAvailabilities);
//     }

//     return baseAvailability;
//   };

//   return (
//     <Box>
//       <Grid sx={{ backgroundColor: theme.palette.background.default }} container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="top" columns={24}>
//         {/* //MARK: - Item */}
//         <Grid item xs={24} sm={12} lg={6}>
//           <Stack direction={"row"} gap={1} alignItems={"center"}>
//             <RowDragHandle
//               triggerType="rightClick"
//               index={index}
//               menuItems={[
//                 {
//                   text: "Insert Item",
//                   onClick: () => handleInsertItem(index),
//                 },
//                 {
//                   text: "Insert Subtotal",
//                   onClick: () => handleInsertSpecial("subtotal", index, null, 1),
//                 },
//                 // {
//                 //   text: "Insert Label",
//                 //   onClick: () => handleInsertLabel(index),
//                 // },
//                 // {
//                 //   text: "Insert Damage Waiver",
//                 //   onClick: onChangeItem,
//                 // },
//                 {
//                   text: "Delete",
//                   onClick: () => onDeleteRow(item.listIndex),
//                 },
//               ]}
//             />
//             <Chip
//               label="Bundle"
//               onClick={() => {
//                 setShowBundleItems(!showBundleItems);
//               }}
//             />
//             <TextField
//               InputProps={{
//                 inputComponent: TextareaAutosize,
//                 inputProps: {
//                   minRows: 1,
//                   maxRows: isMDAndDown ? 2 : 3,
//                   style: { resize: "none", fontSize: responsiveFontSize },
//                 },
//               }}
//               onClick={() => { onChangeItem(item)}}
//               inputProps={{
//                 sx: {
//                   fontSize: responsiveFontSize,
//                   readOnly: true,
//                 },
//               }}
//               sx={{
//                 "& .MuiInputBase-input.Mui-disabled": {
//                   WebkitTextFillColor: "#000000",
//                 },
//               }}
//               InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
//               size="small"
//               label="Item Name"
//               variant="outlined"
//               fullWidth
//               value={itemName}
//             />
//           </Stack>
//         </Grid>

//         {/* //MARK: - Description */}
//         <Grid item xs={24} sm={12} lg={7} xl={8}>
//           <TextField
//             inputProps={{ style: { fontSize: responsiveFontSize } }}
//             InputProps={{
//               inputComponent: TextareaAutosize,
//               inputProps: {
//                 minRows: 1,
//                 maxRows: isMDAndDown ? 2 : 4,
//                 style: { resize: "none", fontSize: responsiveFontSize },
//               },
//             }}
//             inputRef={descriptionRef}
//             InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
//             size="small"
//             label="Description"
//             variant="outlined"
//             fullWidth
//             value={itemDescription}
//             onChange={(e) => setItemDescription(e.target.value)}
//             onBlur={updateItem}
//             multiline
//             onKeyDown={(e) => {
//               if (e.key === "Enter" && e.shiftKey) {
//                 e.preventDefault();
//                 setItemDescription((prev: string) => prev + "\n");
//               } else if (e.key === "Enter") {
//                 e.preventDefault();
//                 if (descriptionRef.current) {
//                   descriptionRef.current.blur();
//                 }
//               }
//             }}
//           />
//         </Grid>

//         {/* //MARK: - Quantity */}
//         <Grid item xs={12} sm={6} md={4} lg={2}>
//           <TextField
//             inputRef={quantityRef}
//             className={classes.input}
//             InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
//             size="small"
//             label="Quantity"
//             type="number"
//             variant="outlined"
//             fullWidth
//             InputProps={{
//               inputProps: {
//                 min: 1,
//                 style: { fontSize: responsiveFontSize },
//               },
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <NumberCircle available={getAvailabilityForId(item.id)} dailyAvailable={getDailyStatusForId(item.id)} />
//                 </InputAdornment>
//               ),
//             }}
//             placeholder="0"
//             value={quantity === null ? "" : quantity}
//             onChange={(e) => {
//               const val = e.target.value === "" ? null : Number(e.target.value);
//               setQuantity(val);
//             }}   
//             onBlur={updateItem}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 if (quantityRef.current) {
//                   quantityRef.current.blur();
//                 }
//               }
//             }}
//           />
//         </Grid>

//         {/* //MARK: - Rate */}
//         <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
//           <TextField
//             disabled={true}
//             inputProps={{
//               sx: {
//                 fontSize: responsiveFontSize,
//                 readOnly: true,
//               },
//             }}
//             InputProps={{
//               endAdornment: (
//                 <IconButton size="small" onClick={handleClick}>
//                   <Iconify icon="carbon:edit" />
//                 </IconButton>
//               ),
//             }}
//             sx={{
//               "& .MuiInputBase-input.Mui-disabled": {
//                 WebkitTextFillColor: "#000000",
//               },
//             }}
//             InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
//             size="small"
//             label="Rate"
//             variant="outlined"
//             fullWidth
//             value={`$${rate.rate} / ${rate.rateRange}`}
//           />
//         </Grid>

//         {/* //MARK: - Duration */}
//         <Grid item xs={12} sm={6} md={4} lg={2}>
//           <TextField
//             required={true}
//             disabled={isSalesInvoice}
//             inputRef={durationRef}
//             className={classes.input}
//             InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
//             size="small"
//             label="Duration"
//             type="number"
//             variant="outlined"
//             fullWidth
//             InputProps={{
//               inputProps: {
//                 min: 0,
//                 style: { fontSize: responsiveFontSize },
//               },
//             }}
//             placeholder="0"
//             value={duration}
//             onChange={handleDurationChange}
//             onBlur={updateItem}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 if (durationRef.current) {
//                   durationRef.current.blur();
//                 }
//               }
//             }}
//           />
//         </Grid>

//         {/* //MARK: - TAXABLE */}
//         <Grid item xs={12} sm={6} md={4} lg={2}>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 inputProps={{ style: { fontSize: responsiveFontSize } }}
//                 checked={taxable}
//                 onChange={(e) => {
//                   setTaxable(e.target.checked);
//                 }}
//               />
//             }
//             label={<Typography fontSize={responsiveFontSize}>{isMDAndDown ? "Taxable" : "Taxed"}</Typography>}
//           />
//         </Grid>

//         {/* //MARK: - TOTAL */}
//         <Grid item xs={12} sm={12} md={4} lg={1}>
//           <div style={{ width: "100%", textAlign: "left", paddingRight: "16px" }}>
//             {useMediaQuery(theme.breakpoints.up("lg")) && <Typography fontSize={responsiveFontSize}>{FormatToLocalCurrency(total, orgData)}</Typography>}
//             {useMediaQuery(theme.breakpoints.down("lg")) && (
//               <Typography fontSize={responsiveFontSize}>Total: {FormatToLocalCurrency(total, orgData)}</Typography>
//             )}
//           </div>
//         </Grid>

//         {/* //MARK: - ACTIONS */}
//         <Grid item alignContent={"start"} xs={12} sm={12} md={4} lg={1}>
//           <Box display="flex" justifyContent={isMDAndDown ? "flex-end" : "flex-start"} width="100%">
//             <Button size="small" color="error" startIcon={<Iconify icon="solar:trash-bin-trash-bold" />} onClick={() => onDeleteRow(item.listIndex)} />
//           </Box>
//         </Grid>
//       </Grid>

//       {showBundleItems && (
//         <Box>
//           <Stack sx={{ p: 1, ml: 4 }}>
//             {
//               loadingDailyStatus ? (
//                 <Typography gutterBottom fontSize={responsiveFontSize}>
//                   Loading availability data...
//                 </Typography>
//               ) : (
//                 <BundleItemsSummary 
//                   data={availability.find((a: any) => a.id === item.id)} 
//                   fullInventory={fullInventory} 
//                   item={item} 
//                   currentQuantity={quantity}
//                   selectedItems={selectedItems}
//                   // dailyInventoryStatus={dailyInventoryStatus}
//                   peakInventory={peakInventory}
//                   loadingDailyStatus={loadingDailyStatus}
//                 />
//               )
//             }
//           </Stack>
//           {!isMDAndDown && (
//             <Divider
//               sx={{
//                 mb: 3,
//                 mt: 1,
//               }}
//             />
//           )}
//         </Box>
//       )}

//       {/* //MARK: - RATE POPOVER */}
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         slotProps={{
//           paper: {
//             sx: { width: "300px" },
//           },
//         }}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//       >
//         <Box sx={{ p: 1 }}>
//           <List>
//             {item &&
//               item.rates &&
//               item.rates.map((r: any) => (
//                 <ListItemButton
//                   key={r.id}
//                   onClick={() => {
//                     setRate(r);
//                     handleClose();
//                   }}
//                 >
//                   <Typography variant="body2">{`${r.rateName} (${r.rate}${item.type === "sale" ? "" : ` per ${r.rateRange}`})`}</Typography>
//                 </ListItemButton>
//               ))}
//           </List>

//           <Typography sx={{ mt: 1 }} variant="subtitle2">
//             CUSTOM RATE
//           </Typography>
//           <Box sx={{ mt: 1 }}>
//             <Stack direction={"row"} gap={1}>
//               <TextField
//                 className={classes.input}
//                 size="small"
//                 label="Amount"
//                 type="number"
//                 placeholder="0.00"
//                 value={customAmt === 0 ? "" : customAmt}
//                 onChange={(e) => setCustomAmt(e.target.value)}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <Box sx={{ typography: "subtitle2", color: "text.disabled" }}>$</Box>
//                     </InputAdornment>
//                   ),
//                   inputProps: { min: 0 },
//                 }}
//                 style={{ width: "125px" }}
//               />
//               <FormControl style={{ width: "140px" }}>
//                 <InputLabel>Duration</InputLabel>
//                 <Select
//                   onChange={(event) => {
//                     setCustomRange(event.target.value);
//                   }}
//                   value={customRange}
//                   size="small"
//                 >
//                   {configureDurationOpts(item.type)?.map((option) => (
//                     <MenuItem key={option.value} value={option.value}>
//                       {option.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>

//               <IconButton
//                 onClick={() => {
//                   if (customAmt) {
//                     handleCustomRate();
//                   } else {
//                     enqueueSnackbar("Please enter a rate amount", {
//                       variant: "error",
//                     });
//                   }
//                 }}
//               >
//                 <Iconify icon="material-symbols:add-circle" color={theme.palette.success.dark} />
//               </IconButton>
//             </Stack>
//           </Box>
//         </Box>
//       </Popover>
//     </Box>
//   );
// }

import "./App.less";
import { Layout, Menu, Spin } from "antd";
import { withRouter, Switch, Route, useHistory, Redirect } from "react-router-dom";
import Sidebar from "./domains/sidebar/Sidebar";

import {
  ROUTE_ADMIN_CREATE_INVENTORY,
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_CUSTOMERS_PAYMENT,
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_SETTINGS_WORDS,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_TRUCKS,
  ROUTE_ADMIN_USERS,
  ROUTE_CREATE_INVOICE,
  ROUTE_CREATE_INVOICE_FROM_CART,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_LOGIN,
  ROUTE_QUICK_REPORT,
  ROUTE_RECEIVE_INVOICE_PAYMENT,
  ROUTE_REPORTS,
  ROUTE_REPORTS_DEPRECIATION,
  ROUTE_REPORTS_SALES,
  ROUTE_REPORTS_USAGE,
  ROUTE_ROUTES,
  ROUTE_VIEW_EDIT_INVOICE,
  ROUTE_ADMIN_VENUES,
  ROUTE_REPORTS_TAX,
  ROUTE_ADMIN_EMBEDDED_CHECKOUT,
  ROUTE_ADMIN_CREATE_PART,
  ROUTE_ADMIN_CREATE_SERVICE,
  ROUTE_ADMIN_VENDORS,
  ROUTE_DASHBOARD,
  ROUTE_CHANGE_LOG,
  ROUTE_MOBILE_APPS,
  ROUTE_ADMIN_SETTINGS_PERMISSIONS,
  ROUTE_ADMIN_FILE_MANAGER,
  ROUTE_ADMIN_BILLING,
  ROUTE_VIEW_PREVIEW_INVOICE,
} from "./app/routes";

// MARK: Core Route Components
import DashboardView from "./domains/dashboard/dashboard-view";
import Invoices from "./domains/invoices/Invoices";
import QuickReport from "./domains/quick-report/QuickReport";
import AdminSettingsEmail from "./domains/admin-settings/AdminSettings.Email";
import AdminSettingsGeneral from "./domains/admin-settings/AdminSettings.General";
import PreviewInvoice from "./domains/invoices/PreviewInvoice";
import InventoryContainer from "./domains/admin-inventory/InventoryContainer";

import Login from "./domains/login/Login";
import AdminCustomers from "./domains/admin-customers/AdminCustomers";
import InventoryCartWrapper from "./domains/inventory/components/InventoryCartWrapper";
import CartInvoice from "./domains/invoices/CartInvoice";
import AdminCustomersReceivePayment from "./domains/admin-customers/AdminCustomersReceivePayment";
import InvoicesReceivePayment from "./domains/invoices/InvoicesReceivePayment";
import NewRoute from "./domains/routes/NewRoute";

import { Suspense, useEffect, useState } from "react";
import { authSelector, verifyAuth } from "./domains/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Spinner } from "./app/ui/spinner";

import { init as i18nInit } from "./i18n";
import { LicenseInfo } from "@mui/x-license-pro";

import { hasPermission } from "./domains/admin-permissions/usePermissions";
import { checkSubscriptionLevel } from "../src/app/interfaces/features-set-permissions";

import ProfileDropdown from "./domains/sidebar/components/ProfileDropdown";
import { Box, Button, Card, Container, Stack, Typography, alpha } from "@mui/material";
import { COLOR_DARK_BG_1 } from "./app/ui/colorConstants";

import { lazy } from 'react';

const EditInvoice = lazy(() => import("./domains/invoices/EditInvoice"));
const AddInvoice = lazy(() => import("./domains/invoices/InvoiceDetail"));
const MuiInvoice = lazy(() => import("./domains/mui-invoice/MuiInvoice"));

const FileManagerView = lazy(() => import("./domains/file-manager/view").then(module => ({ default: module.FileManagerView })));
const BillingPage = lazy(() => import("./domains/admin-billing/billing-page"));
const LockedView = lazy(() => import("./domains/admin-billing/locked-view"));
const AddPart = lazy(() => import("./domains/admin-inventory/components/parts/AddPart"));
const AddServiceItem = lazy(() => import("./domains/admin-inventory/components/serviceItems/AddServiceItem"));
const AdminVendors = lazy(() => import("./domains/admin-vendors/AdminVendors"));
const ChangeLogView = lazy(() => import("./domains/change-log/change-log"));
const MobileAppsView = lazy(() => import("./domains/mobile-apps/mobile-apps"));
const AdminSettingsWords = lazy(() => import("./domains/admin-settings/AdminSettings.Words"));
const AdminEmbeddedCheckout = lazy(() => import("./domains/admin-embedded-checkout/AdminEmbeddedCheckout"));
const AdminVenues = lazy(() => import("./domains/admin-venues/AdminVenues"));
const AddInventory = lazy(() => import("./domains/admin-inventory/components/AddInventory"));
const Reports = lazy(() => import("./domains/reports/Reports"));
const AdminUsers = lazy(() => import("./domains/admin-users/AdminUsers"));
const AdminIntegrations = lazy(() => import("./domains/admin-integrations/AdminIntegrations"));
const AdminTrucks = lazy(() => import("./domains/admin-trucks/AdminTrucks"));
const SalesReport = lazy(() => import("./domains/reports/sales/SalesReport"));
const UsageReport = lazy(() => import("./domains/reports/usage/UsageReport"));
const DepreciationReport = lazy(() => import("./domains/reports/depreciation/DepreciationReport"));
const ForgotPasswordPage = lazy(() => import("./domains/auth/ForgotPasswordPage"));
const TaxReport = lazy(() => import("./domains/reports/tax/TaxReport"));
const PermissionsView = lazy(() => import("./domains/admin-permissions/permissions-view"));

LicenseInfo.setLicenseKey("30a2a71e9e4a5809bafad038d7e4b6a8Tz0xMDk2NzksRT0xNzczNTMyNzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=");

const { Content } = Layout;

function App({ history }) {
  const { authenticated, verifyingAuthInProgress, userData, permissionsData, orgData } = useSelector(authSelector);

  const [sidebarFormat, setSidebarFormat] = useState(false);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const windowHistory = useHistory();
  const [trialEnded, setTrialEnded] = useState(false);

  useEffect(() => {
    if (orgData && orgData.createdOn) {

      if (orgData.subscriptionStatus !== "trial") {
        return;
      }

      const orgCreatedOn = orgData.createdOn.toDate();
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - orgCreatedOn);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 14) {
        setTrialEnded(true);
      }
    }
  }, [orgData]);

  useEffect(() => {
    const initializeI18n = async () => {
      await i18nInit();
    };

    dispatch(verifyAuth({ firestore, firebase }, history));

    if (history.location.pathname === "/" || history.location.pathname === ROUTE_FORGOT_PASSWORD) {
      history.push(ROUTE_DASHBOARD);
    }

    initializeI18n();
  }, []);

  useEffect(() => {
    const path = windowHistory.location.pathname;
    if (
      path !== ROUTE_LOGIN &&
      // path !== ROUTE_ROUTES &&
      path !== ROUTE_FORGOT_PASSWORD
    ) {
      setSidebarFormat(true);
    } else {
      setSidebarFormat(false);
    }
  }, [windowHistory.location.pathname, authenticated]);

  const SuspenseWrapper = ({ children }) => (
    <Suspense fallback={<Spin indicator={<Spinner />} style={{ marginTop: "120px" }} />}>
      {children}
    </Suspense>
  );
  
  const LazyComponent = ({ component: Component, ...props }) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );

  const ProtectedRoute = ({ component: Component, render: RenderComponent, isLazy = false, ...rest }) => {
    const { path, subscriptionFeaturePath } = rest;
  
    const renderContent = (props) => {
      let content;
      if (RenderComponent) {
        content = RenderComponent(props);
      } else if (Component) {
        content = <Component {...props} />;
      }
  
      return isLazy ? <SuspenseWrapper>{content}</SuspenseWrapper> : content;
    };
  
    return (
      <Route
        {...rest}
        render={(props) => {
          if (hasPermission(path, userData, permissionsData)) {
            if (subscriptionFeaturePath && checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
              return renderContent(props);
            } else if (subscriptionFeaturePath && !checkSubscriptionLevel(subscriptionFeaturePath, orgData)) {
              return <LockedView orgData={orgData} userData={userData} subscriptionFeaturePath={subscriptionFeaturePath} />;
            } else {
              return renderContent(props);
            }
          } else {
            return <Redirect to="/unauthorized" />;
          }
        }}
      />
    );
  };

  if (trialEnded) {
    return (
      <Container maxWidth={false} disableGutters>
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            flexDirection: "column",
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          }}
        >
          <Card sx={{ mt: 4, height: "300px" }}>
            {authenticated && userData ? (
              <Stack direction={"row"} alignItems={"center"}>
                <div
                  style={{
                    backgroundColor: COLOR_DARK_BG_1,
                    height: "300px",
                  }}
                >
                  <Menu
                    style={{
                      backgroundColor: COLOR_DARK_BG_1,
                      height: "100%",
                    }}
                    theme={"dark"}
                  >
                    <Menu.Item
                      key={"company_header"}
                      className="CompanyHeader"
                      style={{
                        height: "100%",
                      }}
                    >
                      <ProfileDropdown history={history} />
                    </Menu.Item>
                  </Menu>
                </div>

                <Stack sx={{ p: 2 }}>
                  <Typography variant="h6">Your trial has ended. Please contact support to continue using RentKit.</Typography>

                  <Typography variant="body2">info@rentkit.com</Typography>

                  <Button
                    size="small"
                    sx={{ mt: 2, width: "fit-content" }}
                    variant="contained"
                    onClick={() => {
                      // email to info@rentkit.com
                      const email = "info@rentkit.com";
                      const subject = encodeURIComponent("Trial Ended - Subscription Plan Change Request");
                      const body = encodeURIComponent("Please change my subscription plan to the following: \n");

                      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
                    }}
                  >
                    Contact To Renew
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <Spin indicator={Spinner} style={{ marginTop: "120px" }} />
            )}
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <div>
      <Layout className="App">
        {sidebarFormat && authenticated && permissionsData && userData ? <Sidebar userData={userData} permissionsData={permissionsData} /> : null}

        {!verifyingAuthInProgress ? (
          <Content
            style={{
              marginLeft: sidebarFormat ? "" : 0,
              overflowY: "auto",
            }}
          >
            <Switch>
              <Route exact path={ROUTE_LOGIN} component={Login} />
              <Route exact path={ROUTE_FORGOT_PASSWORD} render={(props) => <LazyComponent component={ForgotPasswordPage} {...props} />} />

              {permissionsData && userData && (
                <>
                  <ProtectedRoute exact path={ROUTE_DASHBOARD} component={DashboardView} />
                  <ProtectedRoute exact path={ROUTE_INVENTORY} component={InventoryCartWrapper} />
                  <ProtectedRoute exact path={ROUTE_INVOICES} component={Invoices} />

                  {orgData && orgData.useLegacyInvoiceView ? (
                    <ProtectedRoute exact path={ROUTE_CREATE_INVOICE} render={(props) => <LazyComponent component={AddInvoice} {...props} />} />
                  ) : (
                    <ProtectedRoute
                      exact
                      path={ROUTE_CREATE_INVOICE}
                      render={(props) => <LazyComponent component={MuiInvoice} {...props} orgData={orgData} />}
                    />
                  )}
                  <ProtectedRoute path={ROUTE_VIEW_EDIT_INVOICE} render={(props) => <LazyComponent component={EditInvoice} {...props} />} />

                  <ProtectedRoute path={ROUTE_VIEW_PREVIEW_INVOICE} component={PreviewInvoice} />
                  <ProtectedRoute exact path={ROUTE_CREATE_INVOICE_FROM_CART} component={CartInvoice} />
                  <ProtectedRoute exact path={ROUTE_RECEIVE_INVOICE_PAYMENT} component={InvoicesReceivePayment} />
                  <ProtectedRoute subscriptionFeaturePath={"routing"} exact path={ROUTE_ROUTES} component={NewRoute} />
                  <ProtectedRoute exact path={ROUTE_QUICK_REPORT} subscriptionFeaturePath={"prepareTheItems"} component={QuickReport} />

                  <ProtectedRoute exact path={ROUTE_REPORTS} render={(props) => <LazyComponent component={Reports} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_REPORTS_SALES} render={(props) => <LazyComponent component={SalesReport} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_REPORTS_TAX} render={(props) => <LazyComponent component={TaxReport} {...props} />} />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"reports.usage"}
                    path={ROUTE_REPORTS_USAGE}
                    render={(props) => <LazyComponent component={UsageReport} {...props} />}
                  />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"reports.depreciation"}
                    path={ROUTE_REPORTS_DEPRECIATION}
                    render={(props) => <LazyComponent component={DepreciationReport} {...props} />}
                  />

                  <ProtectedRoute exact path={ROUTE_ADMIN_USERS} render={(props) => <LazyComponent component={AdminUsers} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_INVENTORY} component={InventoryContainer} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_INVENTORY} render={(props) => <LazyComponent component={AddInventory} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_PART} render={(props) => <LazyComponent component={AddPart} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_CREATE_SERVICE} render={(props) => <LazyComponent component={AddServiceItem} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_CUSTOMERS} component={AdminCustomers} />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"vendors"}
                    path={ROUTE_ADMIN_VENDORS}
                    render={(props) => <LazyComponent component={AdminVendors} {...props} />}
                  />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"venues"}
                    path={ROUTE_ADMIN_VENUES}
                    render={(props) => <LazyComponent component={AdminVenues} {...props} />}
                  />
                  <ProtectedRoute exact path={ROUTE_ADMIN_CUSTOMERS_PAYMENT} component={AdminCustomersReceivePayment} />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"trucks"}
                    path={ROUTE_ADMIN_TRUCKS}
                    render={(props) => <LazyComponent component={AdminTrucks} {...props} />}
                  />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"fileManager"}
                    path={ROUTE_ADMIN_FILE_MANAGER}
                    render={(props) => <LazyComponent component={FileManagerView} {...props} />}
                  />
                  <ProtectedRoute path={ROUTE_ADMIN_INTEGRATIONS} render={(props) => <LazyComponent component={AdminIntegrations} {...props} />} />
                  {/* <ProtectedRoute path={ROUTE_ADMIN_BILLING} render={(props) => <LazyComponent component={BillingPage} {...props} />} /> */}
                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_GENERAL} component={AdminSettingsGeneral} />
                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_EMAIL} component={AdminSettingsEmail} />
                  <ProtectedRoute
                    exact
                    subscriptionFeaturePath={"invoiceCustomization"}
                    path={ROUTE_ADMIN_SETTINGS_WORDS}
                    render={(props) => <LazyComponent component={AdminSettingsWords} {...props} />}
                  />
                  <ProtectedRoute exact path={ROUTE_ADMIN_SETTINGS_PERMISSIONS} render={(props) => <LazyComponent component={PermissionsView} {...props} />} />
                  <ProtectedRoute
                    exact
                    path={ROUTE_ADMIN_EMBEDDED_CHECKOUT}
                    render={(props) => <LazyComponent component={AdminEmbeddedCheckout} {...props} />}
                  />
                  <ProtectedRoute exact path={ROUTE_CHANGE_LOG} render={(props) => <LazyComponent component={ChangeLogView} {...props} />} />
                  <ProtectedRoute exact path={ROUTE_MOBILE_APPS} render={(props) => <LazyComponent component={MobileAppsView} {...props} />} />
                </>
              )}
            </Switch>
          </Content>
        ) : (
          <Spin indicator={Spinner} style={{ marginTop: "120px" }} />
        )}
      </Layout>
    </div>
  );
}

export default withRouter(App);

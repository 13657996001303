export const convertWebPToJpegAndBase64 = async (webpImageUrl) => {
    try {
      // Try regular fetch first
      let response = await fetch(webpImageUrl).catch(() => null);
      
      // If regular fetch fails, try with no-cors
      if (!response) {
        response = await fetch(webpImageUrl, {
          mode: 'no-cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }).catch(() => null);
      }

      if (!response) {
        console.warn('Failed to fetch image:', webpImageUrl);
        return '';
      }

      const blob = await response.blob();
  
      // Create a new HTML canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Create an image element to load the WebP image
      const image = new Image();
      image.crossOrigin = "anonymous";

      return new Promise((resolve) => {
        image.onload = () => {
          try {
            // Set the canvas dimensions to match the image
            canvas.width = image.width;
            canvas.height = image.height;
        
            // Set the background to white
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            
            // Draw the WebP image onto the canvas
            ctx.drawImage(image, 0, 0);
        
            // Convert the canvas content to JPEG as base64 data URI
            const jpegBase64 = canvas.toDataURL('image/jpeg');
            resolve(jpegBase64);
          } catch (err) {
            console.warn('Error processing image:', err);
            resolve('');
          }
        };

        image.onerror = () => {
          console.warn('Error loading image:', webpImageUrl);
          resolve('');
        };

        image.src = URL.createObjectURL(blob);
      });
    } catch (error) {
      console.warn('Error converting WebP to JPEG and Base64:', error);
      return '';
    }
  };

  export const convertPngToJpeg = async (imageUrl) => {
    try {
      // Try regular fetch first
      let response = await fetch(imageUrl).catch(() => null);
      
      // If regular fetch fails, try with no-cors
      if (!response) {
        response = await fetch(imageUrl, {
          mode: 'no-cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }).catch(() => null);
      }

      if (!response) {
        console.warn('Failed to fetch image:', imageUrl);
        return '';
      }

      const blob = await response.blob();
  
      // Create a new HTML canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
  
      // Create an image element to load the PNG image
      const img = new Image();
      img.crossOrigin = "anonymous";

      return new Promise((resolve) => {
        img.onload = () => {
          try {
            // Set the canvas dimensions to match the image
            canvas.width = img.width;
            canvas.height = img.height;
        
            // Set the background to white
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        
            // Draw the image on top of the white background
            ctx.drawImage(img, 0, 0);
        
            // Convert the canvas content to JPEG as base64 data URI
            const jpegUrl = canvas.toDataURL('image/jpeg');
            resolve(jpegUrl);
          } catch (err) {
            console.warn('Error processing image:', err);
            resolve('');
          }
        };

        img.onerror = () => {
          console.warn('Error loading image:', imageUrl);
          resolve('');
        };

        img.src = URL.createObjectURL(blob);
      });
    } catch (error) {
      console.warn('Error converting PNG to JPEG:', error);
      return '';
    }
  };
  
  export const convertImageToBase64 = async (imageUrl) => {
    try {
      // Try regular fetch first
      let response = await fetch(imageUrl).catch(() => null);
      
      // If regular fetch fails, try with no-cors
      if (!response) {
        response = await fetch(imageUrl, {
          mode: 'no-cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }).catch(() => null);
      }

      if (!response) {
        console.warn('Failed to fetch image:', imageUrl);
        return '';
      }

      const blob = await response.blob();
      
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result || '');
        reader.onerror = () => {
          console.warn('Error reading file:', imageUrl);
          resolve('');
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.warn('Error converting image to Base64:', error);
      return '';
    }
  };
  
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Space,
  Checkbox,
  Button,
  Modal,
  Tooltip,
  InputNumber,
  Input,
} from "antd";

import { configureInvoiceSettingsUpdate } from "../../../app/utils/models/configure/configureSettingsUpdate";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { orgWrites } from "../../../app/services/firestore/writes/orgWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { orgQueries } from "../../../app/services/firestore/queries/orgQueries";

import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

import {
  InfoCircleOutlined,
} from "@ant-design/icons";

import { useFirebase } from "react-redux-firebase";

const GeneralInvoiceSettings = () => {
  const firebase = useFirebase();

  const { orgData, fsOrgPrefix, userData } = useSelector(authSelector);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [pageValues, setPageValues] = useState({
    addSubjectLineInvoiceNumber: false,
    automaticallyConvertEstimate: true,
    showInvoiceItemImg: true,
    showItemName: true,
    showDuration: true,
    showRate: true,
    showDescription: true,
    showTotal: true,
    deliveryFeeTaxable: true,
    defaultDepositPercent: 100,
    estimateExpirationLength: 0,
    eventDateEnabled: false,
    salesInvoiceEnabled: false,
    allowPayInFull: false,
    baseRate: 6500,
    rangeRate: 600,
    useLegacyInvoiceView: true,
    availabilityToOrderDurationMatch: true,
    showImagesOnPicklist: false,
    tipOption1: 10,
    tipOption2: 15,
    tipOption3: 20,
    requestTips: false,
    useConfetti: false,
  });

  const [tipOptions, setTipOptions] = useState({
    tipOption1: 10,
    tipOption2: 15,
    tipOption3: 20,
    requestTips: false,
    useConfetti: false,
  });

  useEffect(() => {
    // load org data & set page state
    orgData && loadSettings();
  }, [orgData]);

  const loadSettings = async () => {
    setLoading(true);
    orgData &&
      orgQueries({ firestore }, fsOrgPrefix)
        .fetchOrgDoc(orgData.id)
        .then((data) => {
          setLoading(false);
          setPageValues({
            allowPayInFull: data.allowPayInFull,
            addSubjectLineInvoiceNumber: data.addSubjectLineInvoiceNumber,
            automaticallyConvertEstimate: data.automaticallyConvertEstimate,
            showInvoiceItemImg: data.showInvoiceItemImg,
            showItemName: data.showItemName,
            showDuration: data.showDuration,
            showTotal: data.showTotal,
            deliveryFeeTaxable: data.deliveryFeeTaxable,
            defaultDepositPercent: data.defaultDepositPercent,
            estimateExpirationLength: data.estimateExpirationLength,
            showRate: data.showRate,
            showDescription: data.showDescription,
            eventDateEnabled: data.eventDateEnabled,
            salesInvoiceEnabled: data.salesInvoiceEnabled,
            baseRate: data.baseRate,
            rangeRate: data.rangeRate,
            useLegacyInvoiceView: data.useLegacyInvoiceView,
            availabilityToOrderDurationMatch: data.availabilityToOrderDurationMatch,
            showImagesOnPicklist: data.showImagesOnPicklist,
            tipOption1: data.tipOption1,
            tipOption2: data.tipOption2,
            tipOption3: data.tipOption3,
            requestTips: data.requestTips,
            useConfetti: data.useConfetti,
          });
          form.setFieldsValue({
            allowPayInFull: data.allowPayInFull,
            addSubjectLineInvoiceNumber: data.addSubjectLineInvoiceNumber,
            automaticallyConvertEstimate: data.automaticallyConvertEstimate,
            showInvoiceItemImg: data.showInvoiceItemImg,
            showItemName: data.showItemName,
            showDuration: data.showDuration,
            showRate: data.showRate,
            showDescription: data.showDescription,
            showTotal: data.showTotal,
            deliveryFeeTaxable: data.deliveryFeeTaxable,
            defaultDepositPercent: data.defaultDepositPercent,
            estimateExpirationLength: data.estimateExpirationLength,
            eventDateEnabled: data.eventDateEnabled,
            salesInvoiceEnabled: data.salesInvoiceEnabled,
            baseRate: data.baseRate,
            rangeRate: data.rangeRate,
            useLegacyInvoiceView: data.useLegacyInvoiceView,
            availabilityToOrderDurationMatch: data.availabilityToOrderDurationMatch,
            showImagesOnPicklist: data.showImagesOnPicklist,
            tipOption1: data.tipOption1 || 18,
            tipOption2: data.tipOption2 || 22,
            tipOption3: data.tipOption3 || 25,
            requestTips: data.requestTips,
            useConfetti: data.useConfetti,
            });
          setTipOptions({
            tipOption1: data.tipOption1 || 18,
            tipOption2: data.tipOption2 || 22,
            tipOption3: data.tipOption3 || 25,
            requestTips: data.requestTips,
            useConfetti: data.useConfetti,
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  };

  const onUpdate = (e) => {
    setPageValues({
      ...pageValues,
      [e.target.id]: e.target.value,
    });
  };

  const confirmSave = () => {
    Modal.confirm({
      icon: <></>,
      title: "Save Settings?",
      onOk() {
        return new Promise(async (resolve, reject) => {
          onSaveClicked()
          .then(() => {
            resolve();
          });
        }).catch((err) => {
          console.log(err);
        });
      },
      onCancel() {},
    });
  };

  const onSaveClicked = async () => {
    if (!userData.id || !fsOrgPrefix || !orgData.id)
      return notificationError(
        "Invalid permissions",
        "Unable to update at this time."
      );

    // Validate tip options
    const validTipOptions = Object.entries(tipOptions).every(([_, value]) => 
      !isNaN(Number(value)) && Number(value) >= 0
    );

    if (!validTipOptions && tipOptions?.requestTips) {
      return notificationError(
        "Invalid tip options",
        "Please ensure all tip percentages are valid numbers greater than or equal to 0."
      );
    }

    const payload = configureInvoiceSettingsUpdate(
      { ...pageValues, ...tipOptions },
      userData.id,
    );

    // console.log('Payload', payload)
    orgWrites({ firestore }, fsOrgPrefix)
      .updateOrgDoc(orgData.id, payload)
      .then(() => {
        notificationConfirm("Settings saved", "");
        loadSettings();
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "Please try again later");
      });
  };

  const [defaultDepositPercent, setDefaultDepositPercent] = useState(100);
  const [estimateExpirationLength, setEstimateExpirationLength] = useState(0);

  const firestore = useFirestore();

  const updatePageValuesOnCheckboxChange = async (e, property) => {
    const res = e.target.checked;
    pageValues[`${property}`] = res;
    onUpdate({
      target: {
        id: property,
        value: res,
      },
    });
  };

  const handleDepositPercentageChange = (value) => {
    setDefaultDepositPercent(value);
    onUpdate({
      target: {
        id: "defaultDepositPercent",
        value: value,
      },
    });
  };
  const handleEstimateExpirationLengthChange = (value) => {
    setEstimateExpirationLength(value);
    onUpdate({
      target: {
        id: "estimateExpirationLength",
        value: value,
      },
    });
  };

  const onUpdateTipOptions = (value, option) => {
    // Validate numeric input
    const numValue = Number(value);
    if (isNaN(numValue) || numValue < 0) return;

    setTipOptions(prev => ({
      ...prev,
      [option]: numValue
    }));

    // Update form value
    form.setFieldValue(option, numValue);
  }

  return (
    <Card
      style={{ margin: "0 12px 12px" }}
      size="small"
      title="Invoice Settings"
      extra={
        <Button type="primary" onClick={confirmSave}>
          Save Changes
        </Button>
      }
    >
      <Form form={form}>
        <Row justify="start">
          <Col span={8} style={{ marginRight: "70px" }}>
            <Space>
              <TextSubtitle text={"AFTER ESTIMATE SIGNATURE"} />
              <Tooltip title='When you request a signature via the "Save and Send Estimate" option, if checked, the invoice will be automatically turned into an Invoice type after the customer has signed the invoice.'>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item
              name="automaticallyConvertEstimate"
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(
                    e,
                    "automaticallyConvertEstimate"
                  )
                }
                defaultChecked={true}
              >
                Automatically Convert Estimate To Invoice{" "}
              </Checkbox>
            </Form.Item>

            <Space>
              <TextSubtitle
                text={
                  "ESTIMATE PAYMENT LINK EXPIRES IN X DAYS FROM EMAIL SEND DATE (set 0 for no expiration)"
                }
              />
              <Tooltip title="If you want estimate payment links to expire after a specific number of days after sending each payment request, set this value. The default is 0 and means the link never expires.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>

            {/* Need to add an option for adding tips to the payment link */}
            

            <Form.Item name={"estimateExpirationLength"}>
              <InputNumber
                value={estimateExpirationLength}
                onChange={handleEstimateExpirationLengthChange}
                formatter={(value) => `${value}`}
                min={0}
              />
            </Form.Item>

            <Space>
              <TextSubtitle text={"INVOICE TEMPLATE"} />
              <Tooltip title="When you send an estimate/invoice to a customer, checking the following boxes will toggle the specified columns on/off.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="showInvoiceItemImg" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showInvoiceItemImg")
                }
                defaultChecked={false}
              >
                Show Item Images On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showItemName" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showItemName")
                }
                defaultChecked={false}
              >
                Show Item Name On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showDescription" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showDescription")
                }
                defaultChecked={false}
              >
                Show Description On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showRate" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showRate")
                }
                defaultChecked={false}
              >
                Show Rate On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showDuration" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showDuration")
                }
                defaultChecked={false}
              >
                Show Duration On Invoices{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item name="showTotal" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showTotal")
                }
                defaultChecked={false}
              >
                Show Total On Invoices{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item name="deliveryFeeTaxable" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "deliveryFeeTaxable")
                }
                defaultChecked={true}
              >
                Delivery Fee is Taxable{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="addSubjectLineInvoiceNumber"
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(
                    e,
                    "addSubjectLineInvoiceNumber"
                  )
                }
                defaultChecked={false}
              >
                Automatically Add Invoice Number To Email Subject Line{" "}
              </Checkbox>
            </Form.Item>

            <Space>
              <TextSubtitle text={"DEFAULT SECURITY DEPOSIT PERCENT"} />
              <Tooltip title="If you would like to apply a default security deposit amount to Invoice Payment links, you can set that as a percentage here.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>

            <Form.Item name={"defaultDepositPercent"}>
              <InputNumber
                value={defaultDepositPercent}
                onChange={handleDepositPercentageChange}
                formatter={(value) => `${value}%`}
                min={0}
                max={100}
              />
            </Form.Item>

            <Space>
              <TextSubtitle text={"EVENT DATE"} />
              <Tooltip title="Allow event date to be input on invoices">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="eventDateEnabled" valuePropName={"checked"}>
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "eventDateEnabled")
                }
                defaultChecked={orgData?.eventDateEnabled}
              >
                Enable event date on invoices{" "}
              </Checkbox>{" "}
            </Form.Item>
            <Space>
              <TextSubtitle text={"SALES ONLY INVOICE"} />
              <Tooltip title="Checking this box will allow you to specify the type of transaction document that will be shown to your customer when creating/sending a new order.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="salesInvoiceEnabled" valuePropName={"checked"}>
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "salesInvoiceEnabled")
                }
                defaultChecked={orgData?.salesInvoiceEnabled}
              >
                Allow sales only invoices{" "}
              </Checkbox>{" "}
            </Form.Item>

            <Space>
              <TextSubtitle text={"ALLOW PAYMENT IN FULL"} />
              <Tooltip title="Checking this box will allow your customers to opt in to paying in full on the payment portal, even if the amount you specified was less than the total.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="allowPayInFull" valuePropName={"checked"}>
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "allowPayInFull")
                }
                defaultChecked={orgData?.allowPayInFull}
              >
                Allow invoice payment in full{" "}
              </Checkbox>{" "}
            </Form.Item>



            <Space>
              <TextSubtitle text={"USE LEGACY ORDER VIEW"} />
              <Tooltip title="Checking this box will maintain the old version of the invoice view. Uncheck this to migrate to the new and improved version.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="useLegacyInvoiceView" valuePropName={"checked"}>
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "useLegacyInvoiceView")
                }
                defaultChecked={orgData?.useLegacyInvoiceView}
              >
                Use legacy order view{" "}
              </Checkbox>{" "}
            </Form.Item>



            <Space>
              <TextSubtitle text={"CHECK AVAILABILITY TO ORDER DURATION"} />
              <Tooltip title="When creating an order from the 'Check Availability' view, the default is to set the item duration on the new order to the dates selected in the availability checker. If you would rather the duration be set to 1 when converting to an order, uncheck this setting.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="availabilityToOrderDurationMatch" valuePropName={"checked"}>
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "availabilityToOrderDurationMatch")
                }
                defaultChecked={orgData?.availabilityToOrderDurationMatch}
              >
                {"Match 'Check Availability' dates to order item duration "}
              </Checkbox>{" "}
            </Form.Item>


            <Form.Item name="showImagesOnPicklist" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  updatePageValuesOnCheckboxChange(e, "showImagesOnPicklist")
                }
                defaultChecked={false}
              >
                Show Item Images On Pick Lists{" "}
              </Checkbox>
            </Form.Item>
            
            <Space>
              <TextSubtitle text={"TIP OPTIONS"} />
              <Tooltip title="Set customizable tip percentages for the payment portal.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />

            <Form.Item name="requestTips" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  setTipOptions({
                    ...tipOptions,
                    requestTips: e.target.checked
                  })
                }
                defaultChecked={tipOptions?.requestTips}
              >
                Request Tips
              </Checkbox>
            </Form.Item>

            {tipOptions?.requestTips && (
              <>
                <Form.Item name="useConfetti" valuePropName="checked">  
                  
                  <Checkbox
                    onChange={(e) =>
                      setTipOptions({
                        ...tipOptions,
                        useConfetti: e.target.checked
                      })
                    }
                    defaultChecked={tipOptions?.useConfetti}
                  >
                    Celebrate with Confetti
                  </Checkbox>
                  <Tooltip title="Checking this box will cause a confetti animation to play when the payment is successfully completed.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Form.Item>
                <Form.Item 
                  label="Tip Option 1 (%)" 
                  name="tipOption1"
                  rules={[
                    { required: true, message: 'Please input tip option 1' },
                    { type: 'number', min: 0, message: 'Must be a positive number' }
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    value={tipOptions.tipOption1}
                    onChange={(value) => onUpdateTipOptions(value, "tipOption1")}
                  />
                </Form.Item>
                <Form.Item label="Tip Option 2 (%)" name="tipOption2">
                  <Input
                    type="number"
                    value={tipOptions.tipOption2}
                    onChange={(e) => onUpdateTipOptions(e.target.value, "tipOption2")}
                    min={0}
                  />
                </Form.Item>
                <Form.Item label="Tip Option 3 (%)" name="tipOption3">
                  <Input
                    type="number"
                    value={tipOptions.tipOption3}
                    onChange={(e) => onUpdateTipOptions(e.target.value, "tipOption3")}
                    min={0}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GeneralInvoiceSettings;

import {
  Form,
  Space,
  Input,
  InputNumber,
  Button,
  Radio,
  Select,
  FormListFieldData,
  FormInstance,
} from "antd";
import { FC, useEffect, useState } from "react";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import {
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE,
  INV_ITEM_RATE_RANGE,
  INV_ITEM_EMBEDDED_SHOP_RATE_ID,
  INV_ITEM_SHOW_IN_SHOP,
} from "../../../../app/utils/models/modelConstants/modelConstants";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { OrganizationData } from "../../../../app/interfaces/organizationData";
import { firelikeId } from "../../../../app/utils/models/checkers/firelikeid";

export const InventoryItemRates: FC<{
  type: string;
  form: FormInstance<any>;
}> = ({ type, form }) => {
  return (
    <Form.List name="rates">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field) => (
              <RateRow field={field} remove={remove} type={type} form={form} />
            ))}

            <Form.Item>
              <Button
                onClick={() => add({ id: firelikeId() })}
                icon={<PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              >
                Add rate
              </Button>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

const RateRow: FC<{
  type: string;
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
}> = ({ field, type, remove, form }) => {
  
  const rateDetails:
    { rate: number; 
      rateRange: string; 
      rateName: string; 
      id: string 
    }
    | undefined = form.getFieldValue("rates")[field.name];

  // const [isDailyRate, setIsDailyRate] = useState(true);
  
  // useEffect(() => {
  //   setIsDailyRate(rateDetails ? rateDetails.rateRange === "day" : true);
  // }, [rateDetails]);

  const { orgData }: { orgData: OrganizationData } = useSelector(authSelector);
  const showInShop = form.getFieldValue(INV_ITEM_SHOW_IN_SHOP);
  const allowEmbeddedCheckout = orgData?.embeddedCheckoutEnabled;

  const rateOptions = [
    { label: "Per hour", value: "hour" },
    { label: "Per day", value: "day" },
    { label: "Per week", value: "week" },
    { label: "Per month", value: "month" },
  ];

  const embeddedShopRateId = form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID);
  const embeddedShopRateMonthId = form.getFieldValue('embeddedShopRateMonthId');
  const embeddedShopRateWeekId = form.getFieldValue('embeddedShopRateWeekId');

  const [hardRefresh, setHardRefresh] = useState(0);
  const triggerRender = () => setHardRefresh((s) => s + 1);

  const rateSelectItems =
    type === "sale" ? (
      <Select
        style={{ width: "120px" }}
        disabled={true}
        defaultValue={"none"}
        value={"none"}
      >
        <Select.Option value={"none"}>None</Select.Option>;
      </Select>
    ) : (
      <Select
        style={{ width: "120px" }}
        onChange={(value) => {
          const newValueIsDaily = value === "day";
          const isSelectedShopRate = embeddedShopRateId === rateDetails?.id;

          if (!newValueIsDaily && isSelectedShopRate) {
            form.setFieldsValue({
              [INV_ITEM_EMBEDDED_SHOP_RATE_ID]: undefined,
            });
          }

          triggerRender();
        }}
      >
        {rateOptions.map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
    );

  return (
    <div>
      <Space size={24}>
        <Form.Item
          style={{ width: "120px" }}
          key={field.key + INV_ITEM_RATE_NAME}
          label={<TextSubtitle text={"RATE NAME"} />}
          name={[field.name, INV_ITEM_RATE_NAME]}
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input placeholder="Daily standard" />
        </Form.Item>
        <Form.Item
          key={field.key + INV_ITEM_RATE}
          label={<TextSubtitle text={"RATE"} />}
          name={[field.name, INV_ITEM_RATE]}
          rules={[{ required: true, message: "Rate required" }]}
        >
          <InputNumber
            placeholder={"0"}
            style={{ width: "120px" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>

        {
          type !== "sale" && (
            <Form.Item
              key={field.key + INV_ITEM_RATE_RANGE}
              initialValue={type === "sale" ? "none" : "day"}
              label={<TextSubtitle text={"RANGE"} />}
              name={[field.name, INV_ITEM_RATE_RANGE]}
          rules={[{ required: true, message: "Range required" }]}
            >
              {rateSelectItems}
            </Form.Item>
          )
        }

        {allowEmbeddedCheckout && showInShop && ((rateDetails ? rateDetails.rateRange === "day" : true) || type === "sale") && (
          <Form.Item
            key={field.key + INV_ITEM_EMBEDDED_SHOP_RATE_ID}
            label=" "
            rules={[
              {
                required: true,
                message: "Shop Rate Required",
              },
            ]}
          >
            <Radio.Group value={embeddedShopRateId ?? -1}>
              <Radio
                key={hardRefresh}
                value={rateDetails?.id}
                checked={!!rateDetails?.id}
                onChange={() => {
                  form.setFieldsValue({
                    [INV_ITEM_EMBEDDED_SHOP_RATE_ID]: rateDetails?.id,
                  });
                }}
              >
                Shop Rate
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        { allowEmbeddedCheckout && showInShop && (rateDetails ? rateDetails.rateRange === "week" : true) &&(
          <Form.Item
            key={field.key + 'embeddedShopRateWeekId'}
            label=" "
            rules={[
              {
                required: true,
                message: "Shop Rate Required",
              },
            ]}
          >
            <Radio.Group value={embeddedShopRateWeekId ?? -1}>
              <Radio
                key={hardRefresh}
                value={rateDetails?.id}
                checked={!!rateDetails?.id}
                onChange={() => {
                  form.setFieldsValue({
                    'embeddedShopRateWeekId': rateDetails?.id,
                  });
                }}
              >
                Shop Rate
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        { allowEmbeddedCheckout && showInShop && (rateDetails ? rateDetails.rateRange === "month" : true) &&(
          <Form.Item
            key={field.key + 'embeddedShopRateMonthId'}
            label=" "
            rules={[
              {
                required: true,
                message: "Shop Rate Required",
              },
            ]}
          >
            <Radio.Group value={embeddedShopRateMonthId ?? -1}>
              <Radio
                key={hardRefresh}
                value={rateDetails?.id}
                checked={!!rateDetails?.id}
                onChange={() => {
                  form.setFieldsValue({
                    'embeddedShopRateMonthId': rateDetails?.id,
                  });
                }}
              >
                Shop Rate
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}


        <MinusCircleOutlined
          onClick={() => remove(field.name)}
          style={{ color: COLOR_RED_0, paddingTop: "12px" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}        />
      </Space>
      <br />
    </div>
  );
};
import React, { useEffect, useState } from "react";
import { Upload, Form, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import firebase from "firebase/app";
import "firebase/storage";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";

const ImageUploader = ({ onImageUrl, removeImg, defaultImg }) => {
  const { fsOrgPrefix } = useSelector(authSelector);
  const [imgObj, setImgObj] = useState(null);
  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });
  const [deleteConfirm, setDeleteConfirm] = useState({
    visible: false,
    file: null
  });

  useEffect(() => {
    if (defaultImg) {
      setImgObj([
        {
          name: defaultImg.fileName,
          thumbUrl: defaultImg.downloadURL,
          url: defaultImg.downloadURL,
        },
      ]);
    }
    return () => {
      setImgObj(null);
    };
  }, [defaultImg]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const storageRef = firebase.storage().ref();

    var metadata = {
      contentType: "image/jpeg",
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child(`${fsOrgPrefix}inventory-images/${Date.now()}_${file.name}`)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            onError(error);
            break;

          case "storage/canceled":
            // User canceled the upload
            onError(error);
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          // console.log("File available at", downloadURL);
          onImageUrl(downloadURL, file.name);
          onSuccess("OK");
        });
      }
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Item Image</div>
    </div>
  );
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setImgObj(e && e.fileList);
    return e && e.fileList;
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  const handleCancel = () => {
    setPreview({
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
    });
  };
  const handleRemove = (file) => {
    setDeleteConfirm({
      visible: true,
      file
    });
    return false; // Prevent default removal
  };

  const handleConfirmDelete = () => {
    if (deleteConfirm.file && removeImg) {
      removeImg(deleteConfirm.file);
    }
    setDeleteConfirm({
      visible: false,
      file: null
    });
  };

  const handleCancelDelete = () => {
    setDeleteConfirm({
      visible: false,
      file: null
    });
  };

  return (
    <div>
      <Form.Item
        name="imageUploader"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{ marginBottom: 0 }}
      >
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          onRemove={handleRemove}
          accept="image/*"
          customRequest={uploadImage}
          onPreview={handlePreview}
        >
          {imgObj && imgObj.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>
      <Modal
        open={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={preview.previewImage}
        />
      </Modal>
      <Modal
        title="Confirm Delete"
        open={deleteConfirm.visible}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this image? This action cannot be undone.</p>
      </Modal>
    </div>
  );
};

export default ImageUploader;

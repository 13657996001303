import { Invoice } from "../../../app/interfaces/invoice";
import { FullReceiveReturnDetailsProps, ReceiveDetailsProps, ReturnDetailsProps } from "../types/ReceiveReturnDetails";

export const initializeReceiveReturnDetails = (invoice: Invoice) => {
    const receiveReturnDetails: FullReceiveReturnDetailsProps = {
        receiveBy: invoice.receiveMethod,
        returnBy: invoice.returnMethod,
        receivePlace: null,
        returnPlace: null,
        useCustomerAddressReceive: invoice.customerAddressChecked.receive,
        useCustomerAddressReturn: invoice.customerAddressChecked.return,
        specifyReceiveTime: invoice.hasSpecifiedReceiveTime,
        specifyReturnTime: invoice.hasSpecifiedReturnTime,
        selectedReceiveVenue: invoice.receiveVenueQueryHook || null,
        selectedReturnVenue: invoice.returnVenueQueryHook || null,
        specifiedReceiveTime: invoice.specifiedReceiveTime?.toDate() || null,
        specifiedReturnTime: invoice.specifiedReturnTime?.toDate() || null,
        specifiedReceiveTimeEnd: invoice.specifiedReceiveTimeEnd?.toDate() || null,
        specifiedReturnTimeEnd: invoice.specifiedReturnTimeEnd?.toDate() || null,
        manualReceiveAddress: invoice.deliveryCustomerShippingAddress || "",
        manualReceiveCity: invoice.deliveryCustomerShippingCity || "",
        manualReceiveState: invoice.deliveryCustomerShippingState || "",
        manualReceiveZip: invoice.deliveryCustomerShippingZip || "",
        manualReturnAddress: invoice.onSiteCustomerShippingAddress || "",
        manualReturnCity: invoice.onSiteCustomerShippingCity || "",
        manualReturnState: invoice.onSiteCustomerShippingState || "",
        manualReturnZip: invoice.onSiteCustomerShippingZip || "",
        deliveryCustomerDisplayName: invoice.deliveryCustomerDisplayName || "",
        onSiteCustomerDisplayName: invoice.onSiteCustomerDisplayName || "",
      };

      // SPlit up the receiveReturnDetails into receiveDetails and returnDetails
      const receiveDetails: ReceiveDetailsProps = {
        receiveBy: invoice.receiveMethod,
        receivePlace: null,
        useCustomerAddressReceive: invoice.customerAddressChecked.receive,
        specifyReceiveTime: invoice.hasSpecifiedReceiveTime,
        selectedReceiveVenue: invoice.receiveVenueQueryHook || null,
        specifiedReceiveTime: invoice.specifiedReceiveTime?.toDate() || null,
        specifiedReceiveTimeEnd: invoice.specifiedReceiveTimeEnd?.toDate() || null,
        manualReceiveAddress: invoice.deliveryCustomerShippingAddress || "",
        manualReceiveCity: invoice.deliveryCustomerShippingCity || "",
        manualReceiveState: invoice.deliveryCustomerShippingState || "",
        manualReceiveZip: invoice.deliveryCustomerShippingZip || "",
        deliveryCustomerDisplayName: invoice.deliveryCustomerDisplayName || "",
      };

      const returnDetails: ReturnDetailsProps = {
        returnBy: invoice.returnMethod,
        returnPlace: null,
        useCustomerAddressReturn: invoice.customerAddressChecked.return,
        specifyReturnTime: invoice.hasSpecifiedReturnTime,
        selectedReturnVenue: invoice.returnVenueQueryHook || null,
        specifiedReturnTime: invoice.specifiedReturnTime?.toDate() || null,
        specifiedReturnTimeEnd: invoice.specifiedReturnTimeEnd?.toDate() || null,
        manualReturnAddress: invoice.onSiteCustomerShippingAddress || "",
        manualReturnCity: invoice.onSiteCustomerShippingCity || "",
        manualReturnState: invoice.onSiteCustomerShippingState || "",
        manualReturnZip: invoice.onSiteCustomerShippingZip || "",
        onSiteCustomerDisplayName: invoice.onSiteCustomerDisplayName || "",
      };

      return { receiveReturnDetails, receiveDetails, returnDetails };
};
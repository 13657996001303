import { useContext } from "react";
import { useStopCell } from "../../../../../app/services/hooks/useStopCell";
import StopCellHeader from "../shared/StopCellHeader";
import InvoiceNotes from "../shared/InvoiceNotes";
import { RouteContext } from "../../../NewRoute";
import { COLOR_ORANGE_HIGHLIGHT } from "../../../../../app/ui/colorConstants";

const StopCellAssigned = ({ stop, routes, currentRouteId, orgData }) => {
  const { state } = useContext(RouteContext);

  const { invoiceNumber, items, notes, inRoutes, typeLabel, timeWindow, currentlySelected } = useStopCell({
    stop,
    routes,
    currentRouteId,
    selectedStop: state.selectedStop,
  });

  return (
    <div
      style={{
        background: currentlySelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF",
      }}
    >
      <StopCellHeader
        orgData={orgData}
        hideEdit={false}
        hideDots={true}
        stop={stop}
        invoiceNumber={invoiceNumber}
        inRoutes={inRoutes}
        typeLabel={typeLabel}
        timeWindow={timeWindow}
      />
      <InvoiceNotes notes={notes} />
    </div>
  );
};

export default StopCellAssigned;

import CloseIcon from "@mui/icons-material/Close";
// @mui

import { AppBar, Box, Button, Dialog, Divider, IconButton, Stack, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Invoice } from "../../app/interfaces/invoice";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useRef, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { useReactToPrint } from "react-to-print";
import { dateCalendarClasses } from "@mui/x-date-pickers-pro";
import { InventoryItem } from "../../app/interfaces/inventoryItem";
import Iconify from "../../app/iconify";
import Papa from 'papaparse';
import { firelikeId } from "../../app/utils/models/checkers/firelikeid";
import RenderInventoryView from "./picklist-by-inventory";
import { OrganizationData } from "../../app/interfaces/organizationData";



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// make type
type MakePickListDialogProps = {
  orgData: OrganizationData;  
  showMakePickList: boolean;
  setShowMakePickList: any;
  allInvoices: Invoice[];
  deliveryInvoices: Invoice[];
  pickupInvoices: Invoice[];
  customerPickupInvoices: Invoice[];
  customerDropOffInvoices: Invoice[];
  startDate: Date;
  endDate: Date;
  singleInvoice?: Boolean;
};

// make function export
const MakePickListDialog = ({
  orgData,
  showMakePickList,
  setShowMakePickList,
  allInvoices,
  deliveryInvoices,
  pickupInvoices,
  customerPickupInvoices,
  customerDropOffInvoices,
  startDate,
  endDate,
  singleInvoice,
}: MakePickListDialogProps) => {

  const theme = useTheme();

  const [categories, setCategories] = useState<any[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
  const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
  const [showUncategorized, setShowUncategorized] = useState<boolean>(true);
  const [groupType, setGroupType] = useState<string>("invoice"); // invoice or inventory

  useEffect(() => {
    const cats: any[] = [];
    allInvoices.forEach((invoice) => {
      invoice.selectedItems.items.forEach((item) => {
        item.categories && item.categories.forEach((cat) => {
          // check for unique
          if (!cats.find((c) => c.id === cat.id)) {
            setCheckedCategories([...checkedCategories, cat.id]);
            cats.push(cat);
          }
        });
      });
    });

    const catIds = cats.map((cat) => cat.id);
    setCheckedCategories(catIds);
    setCategories(cats);
  }, [allInvoices]);

  const [state, setState] = React.useState({
    delivery: true,
    onSite: false,
    pickup: true,
    dropoff: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { delivery, onSite, pickup, dropoff } = state;

  useEffect(() => {
    const combined = [];

    if (delivery) {
      combined.push(...deliveryInvoices);
    }

    if (onSite) {
      combined.push(...pickupInvoices);
    }

    if (pickup) {
      combined.push(...customerPickupInvoices);
    }

    if (dropoff) {
      combined.push(...customerDropOffInvoices);
    }

    // filter out duplicates
    const uniqueInvoices = combined
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
   
    setFilteredInvoices(uniqueInvoices);
  }, [delivery, onSite, pickup, dropoff]);

  // Handle checkbox change
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.name === "all" && event.target.checked) {
      setCheckedCategories(categories.map((cat) => cat.id));
      setShowUncategorized(true);
      return;
    }

    if (event.target.name === "all" && !event.target.checked) {
      setCheckedCategories([]);
      setShowUncategorized(false);
      return;
    }

    if (event.target.checked) {
      setCheckedCategories([...checkedCategories, event.target.id]);
    } else {
      setCheckedCategories(checkedCategories.filter((cat) => cat !== event.target.id));
    }
  };

  const handleUncategorizedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUncategorized(event.target.checked);
  }

  const title = () => {
    if (delivery && onSite && pickup && dropoff) {
      return "All Invoices";
    }

    let title = "";
    if (delivery) {
      title += "Delivery, ";
    }

    if (onSite) {
      title += "On Site Pickup, ";
    }

    if (pickup) {
      title += "Customer Pickup, ";
    }

    if (dropoff) {
      title += "Customer Dropoff, ";
    }

    title = title.replace(/,\s*$/, " ");

    if (startDate.toDateString() === endDate.toDateString()) {
      title += `for ${startDate.toLocaleDateString()}`;
    } else {
      title += `for ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`;
    }

    return title;
  }

  const RenderBundle = ({ item }: {item: any}) => {
    const { name, selectedQty, bundleItems, picklistInfo } = item;
  
    return (
      <Box key={`${item.id}-${firelikeId()}`} sx={{ p: 1}}>
        <Stack direction={"row"}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {name} (Quantity: {selectedQty})
          </Typography>
          {picklistInfo && (
            <Typography variant="body2" sx={{ mb: 1 }}>
              {picklistInfo}
            </Typography>
          )}
        </Stack>
        <Stack>
          {bundleItems.map((bundleItem: any) => (
            <Box key={bundleItem.id} sx={{ p: 1 }}>
              <Stack direction={"row"}>
                <Typography sx={{ width: "500px" }}>{bundleItem.bundleItemName}</Typography>
                <Typography fontWeight={"bold"} sx={{ textAlign: "right" }}>
                  {selectedQty > 1
                    ? `${bundleItem.bundleItemQty} x ${selectedQty} = ${bundleItem.bundleItemQty * selectedQty}`
                    : bundleItem.bundleItemQty}
                </Typography>
              </Stack>
              <Divider />
            </Box>
          ))}
        </Stack>
      </Box>
    );
  };

  // MARK: Render Item
  const RenderItem = ({ item }: {item: any}) => (
    <Box key={item.id} sx={{ p: 1 }}>
      <Stack direction={"row"}>
        <Typography sx={{ width: "500px" }}>{item.name}</Typography>
        <Typography fontWeight={"bold"} sx={{ textAlign: "right" }}>
          {item.selectedQty}
        </Typography>
      </Stack>
      {item.picklistInfo && (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {item.picklistInfo}
        </Typography>
      )}
      <Divider />
    </Box>
  );

  // MARK: Render Invoice Header
  const RenderInvoiceHeader = ({ invoice }: {invoice: any}) => {

    const deliveryDetails = () => {
      if (invoice.mobileStartDate === invoice.mobileEndDate) {
        const receiveBy =
          invoice.receiveMethod === "delivery" ? "DELIVERY" : "CUSTOMER PICKUP";
        const returnBy =
          invoice.returnMethod === "delivery" ? "CUSTOMER DROP OFF" : "ON SITE PICKUP";
        return `Same Day (${invoice.mobileStartDate}) Customer receives by ${receiveBy} and returns by ${returnBy}`;
      } else {
        // Handle multi-day rentals if needed
        const receiveBy =
          invoice.receiveMethod === "delivery" ? "DELIVERY" : "CUSTOMER PICKUP";
        const returnBy =
          invoice.returnMethod === "delivery" ?"CUSTOMER DROP OFF" : "ON SITE PICKUP";
        return `Customer Receives by ${receiveBy} on ${invoice.mobileStartDate} and returns by ${returnBy} on ${invoice.mobileEndDate}`;
      }
    };
    
    return (

    <Box sx={{ p: 1, backgroundColor: theme.palette.background.neutral }}>
      <Typography variant="h6">
        {invoice.customer.customerDisplayName} - {invoice.invoiceNumber}
      </Typography>
      <Typography variant="body2" fontWeight={"bold"}>
       {deliveryDetails()}
      </Typography>
    </Box>
    )
  }
  
  const handleGroupTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newGroupType: string,
  ) => {
    setGroupType(newGroupType);
  };

  // MARK: Download CSV
  const handleDownloadCSV = () => {

    const titleRow = {
        customerDisplayName: title(),
        invoiceNumber: '',
        itemName: '',
        itemQty: '',
        bundleItemName: '',
        bundleItemQty: ''
      };

      
    const data = filteredInvoices.flatMap((invoice) => {
      // Add header row for each invoice
      const invoiceHeader = {
        customerDisplayName: invoice.customer.customerDisplayName,
        invoiceNumber: invoice.invoiceNumber,
        itemName: '',
        itemQty: '',
        bundleItemName: '',
        bundleItemQty: ''
      };
  
      const categorizedItems = invoice.selectedItems.items.filter((item) =>
        item.categoriesQueryHook && item.categoriesQueryHook.some((categoryId) => checkedCategories.includes(categoryId))
      );
  
      const uncategorizedItems = invoice.selectedItems.items.filter((item) =>
        item.categoriesQueryHook && item.categoriesQueryHook.length === 0
      );
  
      const allItems = [...categorizedItems, ...uncategorizedItems];
  
      const itemRows = allItems.flatMap((item) => {
        const isBundle = item.bundleItems && item.bundleItems.length > 0;
  
        if (isBundle) {
          // Create the first row with the bundle name
          const firstRow = {
            customerDisplayName: '',
            invoiceNumber: '',
            itemName: item.picklistInfo ? item.name + "\n" + item.picklistInfo : item.name,
            itemQty: item.selectedQty,
            bundleItemName: item.bundleItems[0].picklistInfo ? item.bundleItems[0].bundleItemName + "\n" + item.bundleItems[0].picklistInfo : item.bundleItems[0].bundleItemName,
            bundleItemQty: item.selectedQty > 1
              ? `${item.bundleItems[0].bundleItemQty} x ${item.selectedQty} = ${item.bundleItems[0].bundleItemQty * item.selectedQty}`
              : item.bundleItems[0].bundleItemQty,
          };
  
          // Create the subsequent rows with blank item name and qty
          const otherRows = item.bundleItems.slice(1).map((bundleItem) => ({
            customerDisplayName: '',
            invoiceNumber: '',
            itemName: '',
            itemQty: '',
            bundleItemName: bundleItem.picklistInfo ? bundleItem.bundleItemName + "\n" + bundleItem.picklistInfo : bundleItem.bundleItemName,
            bundleItemQty: item.selectedQty > 1
              ? `${bundleItem.bundleItemQty} x ${item.selectedQty} = ${bundleItem.bundleItemQty * item.selectedQty}`
              : bundleItem.bundleItemQty,
          }));
  
          return [firstRow, ...otherRows];
        } else {
          return {
            customerDisplayName: '',
            invoiceNumber: '',
            itemName: item.picklistInfo ? item.name + "\n" + item.picklistInfo : item.name,
            itemQty: item.selectedQty,
            bundleItemName: '',
            bundleItemQty: '',
          };
        }
      });
  
      return [invoiceHeader, ...itemRows];
    });

    const csvData = [titleRow, ...data];

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'pick_list.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <Dialog open={showMakePickList} onClose={() => setShowMakePickList()} fullScreen maxWidth="md" TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => setShowMakePickList(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Make Pick List
          </Typography>

          <PrintPickListInvoiceBOMComponent
            orgData={orgData}
            filteredInvoices={filteredInvoices}
            checkedCategories={checkedCategories}
            title={title()}
            showUncategorized={showUncategorized}
            groupType={groupType}
          />

          <IconButton onClick={handleDownloadCSV} aria-label="notifications">
            <Tooltip title="Download">
              <Iconify icon="mingcute:download-3-fill" color={"white"} />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between", // Optional: adjust spacing between boxes
        }}
      >
        {/* Show Switches for categories and invoice types */}

        <Box
          sx={{
            width: "auto", // Adjust as needed
            border: "1px solid black", // Optional: just to visualize the box
            padding: 2, // Optional: add some padding
            margin: 2, // Optional: adjust spacing between boxes
          }}
        >
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel sx={{ mt: 3 }} component="legend">
              Group Items By:
            </FormLabel>

            <ToggleButtonGroup sx={{ mb: 3 }} size="small" color="primary" value={groupType} exclusive onChange={handleGroupTypeChange} aria-label="Platform">
              <ToggleButton value="invoice">By Invoice</ToggleButton>
              <ToggleButton value="inventory">By Inventory</ToggleButton>
            </ToggleButtonGroup>

            {!singleInvoice && (
              <>
                <FormLabel component="legend">Select Inbound/Outbound</FormLabel>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={delivery} onChange={handleChange} name="delivery" />} label="Delivery" />
                  <FormControlLabel control={<Checkbox checked={onSite} onChange={handleChange} name="onSite" />} label="On Site Pickup" />
                  <FormControlLabel control={<Checkbox checked={pickup} onChange={handleChange} name="pickup" />} label="Customer Pickup" />
                  <FormControlLabel control={<Checkbox checked={dropoff} onChange={handleChange} name="dropoff" />} label="Customer Dropoff" />
                </FormGroup>
              </>
            )}

            <FormLabel sx={{ mt: 3 }} component="legend">
              Select Categories
            </FormLabel>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={true} onChange={handleCategoryChange} name="all" />} label="Select All" />

              {categories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      checked={checkedCategories.includes(category.id)}
                      defaultChecked={true}
                      onChange={handleCategoryChange}
                      name={category.name}
                      id={category.id}
                    />
                  }
                  label={category.name}
                />
              ))}

              <FormControlLabel
                control={
                  <Checkbox defaultChecked={true} checked={showUncategorized} onChange={handleUncategorizedChange} id="uncategorized" name="uncategorized" />
                }
                label="Uncategorized"
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            flex: 1,
            border: "1px solid black",
            padding: 2,
            margin: 2,
          }}
        >
          {groupType === "invoice" ? (
            <Stack>
              <Typography mb={2} variant="h5">
                {title()}
              </Typography>

              {filteredInvoices.map((invoice) => {
                const categorizedItems = invoice.selectedItems?.items.filter(
                  (item) => item.categoriesQueryHook && item.categoriesQueryHook.some((categoryId) => checkedCategories.includes(categoryId))
                );

                const uncategorizedItems = invoice.selectedItems.items.filter((item) => item.categoriesQueryHook && item.categoriesQueryHook.length === 0);

                if (categorizedItems.length === 0 && (uncategorizedItems.length === 0 || !showUncategorized)) {
                  return null;
                }

                return (
                  <Stack key={invoice.id} direction="column">
                    <RenderInvoiceHeader invoice={invoice} />

                    <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                      {categorizedItems.map((item) => {
                        const isBundle = item.bundleItems && item.bundleItems.length > 0;
                        return isBundle ? <RenderBundle key={item.id} item={item} /> : <RenderItem key={item.id} item={item} />;
                      })}

                      {showUncategorized && uncategorizedItems.length > 0 && (
                        <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                          {uncategorizedItems.map((item) => {
                            const isBundle = item.bundleItems && item.bundleItems.length > 0;
                            return isBundle ? <RenderBundle key={item.id} item={item} /> : <RenderItem key={item.id} item={item} />;
                          })}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Stack>
              <Typography mb={2} variant="h5">
                {title()}
              </Typography>
              <RenderInventoryView filteredInvoices={filteredInvoices} checkedCategories={checkedCategories} showUncategorized={showUncategorized} />
            </Stack>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default MakePickListDialog;


type PrintablePickListProps = {
    orgData: OrganizationData;
    filteredInvoices: Invoice[];
    checkedCategories: string[];
    title: string;
    showUncategorized: boolean;
    groupType: string;
  };
  

  // MARK: Printable
  const PrintablePickList = React.forwardRef<HTMLDivElement, PrintablePickListProps>(({orgData, filteredInvoices, checkedCategories, title, showUncategorized, groupType }, ref) => {

    console.log("PrintablePickList", orgData);

  const RenderInvoiceHeader = ({ invoice }: {invoice: any}) => {

    const deliveryDetails = () => {
      if (invoice.mobileStartDate === invoice.mobileEndDate) {
        const receiveBy =
          invoice.receiveMethod === "delivery" ? "DELIVERY" : "CUSTOMER PICKUP";
        const returnBy =
          invoice.returnMethod === "delivery" ? "CUSTOMER DROP OFF" : "ON SITE PICKUP";
        return `Same Day (${invoice.mobileStartDate}) Customer receives by ${receiveBy} and returns by ${returnBy}`;
      } else {
        // Handle multi-day rentals if needed
        const receiveBy =
          invoice.receiveMethod === "delivery" ? "DELIVERY" : "CUSTOMER PICKUP";
        const returnBy =
          invoice.returnMethod === "delivery" ?"CUSTOMER DROP OFF" : "ON SITE PICKUP";
        return `Customer Receives by ${receiveBy} on ${invoice.mobileStartDate} and returns by ${returnBy} on ${invoice.mobileEndDate}`;
      }
    };
    
    return (

      <Box sx={{ p: 1, backgroundColor: 'background.neutral' }}>
      <Typography variant="h6">
        {invoice.customer.customerDisplayName} - {invoice.invoiceNumber}
      </Typography>
      <Typography variant="body2" fontWeight={"bold"}>
       {deliveryDetails()}
      </Typography>
    </Box>
    )
  }
  

    return (
      <Box ref={ref} p={2}>
        {groupType === "invoice" ? (
          <Stack>
            <Typography mb={2} variant="h5">
              {title}
            </Typography>

            {filteredInvoices.map((invoice) => {
              const categorizedItems = invoice.selectedItems.items.filter(
                (item) => item.categoriesQueryHook && item.categoriesQueryHook.some((categoryId) => checkedCategories.includes(categoryId))
              );

              const uncategorizedItems = invoice.selectedItems.items.filter((item) => item.categoriesQueryHook && item.categoriesQueryHook.length === 0);

              if (categorizedItems.length === 0 && (uncategorizedItems.length === 0 || !showUncategorized)) {
                return null;
              }

              return (
                <Stack key={invoice.id} direction="column">
                  <RenderInvoiceHeader invoice={invoice} />

                  <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                    {categorizedItems.map((item) => {
                      return (
                        <Box key={item.id} sx={{ p: 1 }}>
                          <Stack direction={"row"}>
                            {orgData && orgData.showImagesOnPicklist && item.image && (
                              <img src={item.image.downloadURL} alt={item.name} style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                            )}

                            <Stack direction={"column"}>
                              <Typography fontSize={14} sx={{ width: "500px" }}>
                                {item.name}
                              </Typography>
                              {item.picklistInfo && (
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  {item.picklistInfo}
                                </Typography>
                              )}
                            </Stack>

                            <Typography fontSize={14} fontWeight={"bold"} sx={{ textAlign: "right" }}>
                              {item.selectedQty}
                            </Typography>
                          </Stack>
                          <Divider />
                        </Box>
                      );
                    })}

                    {showUncategorized && uncategorizedItems.length > 0 && (
                      <>
                        <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
                          {uncategorizedItems.map((item) => (
                            <Box key={item.id} sx={{ p: 1 }}>
                              <Stack direction={"row"} alignItems={"center"}>
                                {orgData.showImagesOnPicklist && item.image && (
                                  <img
                                    src={item.image.downloadURL}
                                    alt={item.name}
                                    style={{ width: "50px", height: "50px", objectFit: "contain", padding: "8px", marginRight: "8px" }}
                                  />
                                )}

                                <Stack direction={"column"}>
                                  <Typography fontSize={14} sx={{ width: "500px" }}>
                                    {item.name}
                                  </Typography>
                                  {item.picklistInfo && (
                                    <Typography variant="caption" sx={{ mb: 1 }}>
                                      {item.picklistInfo}
                                    </Typography>
                                  )}
                                </Stack>
                                <Typography fontSize={14} fontWeight={"bold"} sx={{ textAlign: "right" }}>
                                  {item.selectedQty}
                                </Typography>
                              </Stack>
                              <Divider />
                            </Box>
                          ))}
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        ) : (
          <Stack>
            <Typography mb={2} variant="h5">
              {title}
            </Typography>
            <RenderInventoryView filteredInvoices={filteredInvoices} checkedCategories={checkedCategories} showUncategorized={showUncategorized} />
          </Stack>
        )}
      </Box>
    );
  });
  
  type PrintPickListInvoiceBOMComponentProps = {
    orgData: OrganizationData;
    filteredInvoices: Invoice[];
    checkedCategories: string[];
    title: string;
    showUncategorized: boolean;
    groupType: string;
  };
  
  export const PrintPickListInvoiceBOMComponent = ({orgData, filteredInvoices, checkedCategories, title, showUncategorized, groupType }: PrintPickListInvoiceBOMComponentProps) => {
    const componentRef = useRef<HTMLDivElement>(null);
  
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "Pick List",
      pageStyle: `
        @page {
          size: auto;
          margin: 15mm; /* Adjust the margin as needed */
        }
        @media print {
          body {
            margin: 0;
          }
        }
      `,
    });

    // const handlePrint = useReactToPrint({
    //   content: () => componentRef.current,
    //   documentTitle: "Pick List",
    // });
  
    return (
      <>
        <IconButton onClick={handlePrint} aria-label="notifications">
          <Tooltip title="Print Pull Sheet">
            <Iconify icon="mingcute:print-fill" color={"white"}/>
          </Tooltip>
        </IconButton>

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <PrintablePickList
            orgData={orgData}
            filteredInvoices={filteredInvoices}
            checkedCategories={checkedCategories}
            title={title}
            showUncategorized={showUncategorized}
            ref={componentRef}
            groupType={groupType}
          />
        </div>
      </>
    );
  };
  
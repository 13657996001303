import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Divider, Chip, Grid, useTheme, alpha } from "@mui/material";
import firebase from "firebase";
import { Diff } from "../logic/MakeInvoiceDiff";
import { select } from "../../../app/theme/overrides/components/select";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { firelikeId } from "../../../app/utils/models/checkers/firelikeid";
import { useCurrentBreakpoint } from "../../../app/hooks/use-responsive-font";

interface InvoiceDiffProps {
  diffs: Diff[];
  orgData: OrganizationData; // Replace 'any' with the type of your orgData object
}

const fieldDisplayNames: { [key: string]: string } = {
  rentalDates: "Rental Dates",
  selectedItems: "Selected Items",
  customer: "Customer",
  invoiceNumber: "Invoice Number",
  createdBy: "Created By",
  createdOn: "Created On",
  updatedBy: "Updated By",
  updatedOn: "Updated On",
  notes: "Notes",
  internalNotes: "Internal Notes",
  taxAmt: "Tax Amount",
  taxRate: "Tax Rate",
  total: "Total",
  subtotal: "Subtotal",
  deliveryMethod: "Delivery Method",
  returnMethod: "Return Method",
  receiveVenueQueryHook: "Receive Venue",
  returnVenueQueryHook: "Return Venue",
  eventDate: "Event Date",
  salesRepName: "Sales Rep Name",
  rentalRange: "Number of Days",
  deliveryCustomerShippingAddress: "Shipping Address",
  deliveryCustomerShippingCity: "Shipping City",
  deliveryCustomerShippingState: "Shipping State",
  deliveryCustomerShippingZip: "Shipping Zip",
  deliveryCustomerShippingAddressStr: "Shipping Address",
  deliveryCustomerDisplayName: "Customer Name",
  contactPhoneNumber: "Contact Phone Number",
  selectedContact: "Selected Contact",
  specifiedReceiveTimeEnd: "Specified Receive Time End",
  specifiedReceiveTime: "Specified Receive Time",
  specifiedReturnTimeEnd: "Specified Return Time End",
  specifiedReturnTime: "Specified Return Time",
  rentalTurnaroundEnd: "Rental Turnaround End",
  rentalDateEnd: "Rental Date End",
  rentalDateStart: "Rental Date Start",
  mobileStartDate: "Mobile Start Date",
  mobileEndDate: "Mobile End Date",
  invoicePaymentDueDate: "Invoice Payment Due Date",
  bundleItemsQueryHook: "Bundle Items",
  categoriesQueryHook: "Categories",
  customerQueryHook: "Customer Query Hook",
  customerAddressChecked: "Customer Address Checked",
  hasSpecifiedReceiveTime: "Has Specified Receive Time",
  hasSpecifiedReturnTime: "Has Specified Return Time",
  isSalesInvoice: "Is Sales Invoice",
  invoiceSource: "Invoice Source",
  internalFiles: "Internal Files",
  onSiteCustomerDisplayName: "On-Site Customer Name",
  onSiteCustomerShippingAddress: "On-Site Shipping Address",
  onSiteCustomerShippingAddressStr: "On-Site Shipping Address (String)",
  onSiteCustomerShippingCity: "On-Site Shipping City",
  onSiteCustomerShippingState: "On-Site Shipping State",
  onSiteCustomerShippingZip: "On-Site Shipping Zip",
  orgId: "Organization ID",
  qrStatus: "QR Status",
  qrTotal: "QR Total",
  qrInvoiceHasIssues: "QR Invoice Has Issues",
  qrItems: "QR Items",
  receiveMethod: "Receive Method",
  salesRepId: "Sales Rep ID",
  selectedItemsQueryHook: "Selected Items Query Hook",
  status: "Status",
  tagsQueryHook: "Tags Query Hook",
  taxMetaData: "Tax Metadata",
  totalWeight: "Total Weight",
  totalDiscountNumber: "Total Discount Amount",
  totalDiscountPercent: "Total Discount Percent",
  totalDiscountType: "Total Discount Type",
  hasTotalDiscount: "Has Total Discount",
  type: "Order Type",
  deliveryAddrValidated: "Delivery Address Validated",
  deliveryLat: "Delivery Latitude",
  deliveryLng: "Delivery Longitude",
  onSiteAddrValidated: "On-Site Address Validated",
  onSiteLat: "On-Site Latitude",
  onSiteLng: "On-Site Longitude",
  signatureImgUrl: "Signature Image URL",
  deliveryFee: "Delivery Fee",
  deliveryFeeTaxable: "Delivery Fee Taxable",
  lastSyncDate: "Last Sync Date",
  lastSyncSuccess: "Last Sync Success",
  balanceFulfilled: "Balance Fulfilled",
  balanceRemaining: "Balance Remaining",
  paymentComplete: "Payment Complete",
  qboInvoiceNumber: "QuickBooks Invoice Number",
  qbId: "QuickBooks ID",
  estimateSignatures: "Estimate Signatures",
  paymentSignatures: "Payment Signatures",
  emailDeliveryFailed: "Email Delivery Failed",
  hasSendRecords: "Has Send Records",
  isLoaded: "Is Loaded",
  receivedByDelivery: "Received by Delivery",
  returnedByPickup: "Returned by Pickup",
  receivedByPickup: "Received by Pickup",
  returnedByDropOff: "Returned by Drop-Off",
  isSigned: "Is Signed",
  keyHelp: "Key Helper",
};

function getDisplayName(fieldName: string): string {
  return fieldDisplayNames[fieldName] || fieldName;
}

const specialRowDisplayName = (row: any) => {
  // special row types:  damageWaiver | percentFee | flatFee | subtotal | descriptionOnly
  switch (row.rowType) {
    case "damageWaiver":
      return "Damage Waiver";
    case "percentFee":
      return `Percent Fee`;
    case "flatFee":
      return `Flat Fee`;
    case "subtotal":
      return "Subtotal";
    case "descriptionOnly":
      return "Label";
    default:
      return "";
  }
};

const InvoiceDiff: React.FC<InvoiceDiffProps> = ({ diffs, orgData }) => {
  const { isMDAndUp, isMDAndDown, isLGAndUp } = useCurrentBreakpoint();
  const theme = useTheme();

  const fieldsToIgnore = ["salesRepId", "updatedOn", "updatedAt", "onSiteCustomerShippingAddress", "bundleItemsQueryHook", "categoriesQueryHook",
    "customerAddressChecked", "domain" 
  ];

  const renderDiffValue = (fieldName: string, value: any, isOldValue: boolean): any => {

    if (fieldsToIgnore.includes(fieldName)) {
      return null;
    }

    switch (fieldName) {
      case "rentalDates":
        if (Array.isArray(value)) {
          return value
            .map((ts) => {
              return ts.toDate().toLocaleDateString();
            })
            .join(" to ");
        }
        break;

      case "taxRate":
        return (
          <>
            <Typography variant="body2" color="textPrimary">
              {isOldValue ? "Old Rate:" : "New Rate:"}
            </Typography>
            {(value * 100).toFixed(2)}%
          </>
        );

      case "selectedItems":
        // Handle selectedItems field
        return (
          <>
            <Typography variant="body2" color="textPrimary">
              {isOldValue ? "Old Items:" : "New Items:"}
            </Typography>
            {value
              .sort((a: { key: number }, b: { key: number }) => (a.key < b.key ? -1 : 1))
              .map((item: any) => (
                <Typography
                  sx={{
                    ml: item.rowType !== "singleItem" ? 2 : 0,
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  {item.rowType === "singleItem"
                    ? `${item.name} - Qty: ${item.selectedQty} - Duration: ${item.selectedDuration} - Rate: ${FormatToLocalCurrency(
                        item.selectedRateMeta.rate / 100,
                        orgData
                      )}`
                    : `${specialRowDisplayName(item)} - ${item.selectedDescription ?? ""}`}

                </Typography>
              ))}
            <br />
          </>
        );

      case "taxAmt":
      case "total":
      case "deliveryFee":
      case "subtotal": // Handle numeric fields
        return (
          <>
            <Typography variant="body2" color="textPrimary">
              {isOldValue ? "Old Value:" : "New Value:"}
            </Typography>
            {FormatToLocalCurrency(value / 100, orgData)}
          </>
        );

      default:
        // Default case for generic fields
        const final = value !== undefined && value !== null ? value.toString() : "N/A";

        return (
          <>
            <Typography variant="body2" color="textPrimary">
              {isOldValue ? "Old Value:" : "New Value:"}
            </Typography>
            {final}
          </>
        );
    }
    return "--";
  };

  return (
    <Box>
      <Typography sx={{ ml: 2 }} variant="body2" fontWeight={"bold"} gutterBottom>
        Changes to Invoice
      </Typography>

      {diffs
        .filter((diff) => !fieldsToIgnore.includes(diff.fieldName))
        .map((diff, index) => (
          <Box
            key={firelikeId()}
            sx={{
              ml: 2,
              mt: 2,
            }}
          >
            <Chip label={getDisplayName(diff.fieldName)} />
            <Grid container>
              <Grid order={isMDAndUp ? 1 : 0} item xs={12} md={6} key={"new"}>
                <Box
                  sx={{
                    p: 2,
                    mt: 2,
                    mr: 2,
                    mb: 2,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.success.lighter, 0.7),
                    borderStyle: "dashed",
                  }}
                >
                  {renderDiffValue(diff.fieldName, diff.newValue, false)}
                </Box>
              </Grid>
              <Grid order={isMDAndUp ? 0 : 1} item xs={12} md={6} key={"old"}>
                <Box
                  sx={{
                    p: 2,
                    mt: 2,
                    mr: 2,
                    mb: 2,
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.warning.lighter, 0.7),
                    borderStyle: "dashed",
                  }}
                >
                  {renderDiffValue(diff.fieldName, diff.oldValue, true)}
                </Box>
              </Grid>
            </Grid>

            {index < diffs.filter((diff) => !fieldsToIgnore.includes(diff.fieldName)).length - 1 && <Divider />}
          </Box>
        ))}
    </Box>
  );
};

export default InvoiceDiff;
